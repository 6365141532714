import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NbDialogService, NbPopoverDirective } from '@nebular/theme';
import {
  Adjustment,
  AdjustmentType,
  EventType,
  SegmentImpact,
  AdjustmentKey,
  SegmentAdjustment,
  TradeTerm,
} from '../../../../../@core/interfaces/business/event';
import { Segment, SegmentType } from '../../../../../@core/interfaces/business/segment';
import { Plan } from '../../../../../@core/interfaces/business/plan';
import { SegmentService } from '../../../../../@core/entity/segment.service';
import { Store } from '@ngrx/store';
import { select_segments } from '@/store/segment/segment.selectors';
import { AdjustmentSign } from '../segment-impact/segment-adjustment.component';
import { SimpleDateRange } from '@/app/@core/interfaces/common/date-range';
import { select_tradeTermGroups } from '@/store/pages/event-management/event-management.selectors';
import { SET_TRADE_TERM_GROUPS } from '@/store/pages/event-management/event-management.actions';

export interface SharedAdjustmentType {
  sign: AdjustmentSign;
  value: number;
  type: AdjustmentType;
  checked: boolean;
}

export type PartialSharedAdjustmentType = Partial<SharedAdjustmentType>;

export enum TradeTerms {
  FLAT_DISCOUNT = 'flat_discount',
  VOLUME_REBATE_INCENTIVE = 'volume_rebate_incentive',
  BUNDLE_DISCOUNT = 'bundle_discount',
  DEMAND_IMPACT = 'demand_impact',
}
export const TRADETERM_LABELS: Array<any> = [
  { value: 'flat_discount', label: 'Flat Discount' },
  { value: 'volume_rebate_incentive', label: 'Volume Rebate Incentive' },
  { value: 'bundle_discount', label: 'Bundle Discount' },
  { value: 'demand_impact', label: 'Demand Impact' },
];

export enum ApplyTo {
  SALES_IN = 'sales_in',
  SALES_OUT_REIMBURSEMENT = 'sales_out_dr',
  SALES_OUT = 'sales_out',
}
export const APPLYTO_LABELS: Array<any> = [
  { value: 'sales_in', label: 'Sales In' },
  { value: 'sales_out_dr', label: 'Sales Out with Distributor Reimbursement' },
  { value: 'sales_out', label: 'Sales Out' },
];

const defaultTradeTerm: TradeTerm = {
  startDate: '',
  endDate: '',
  type: 'flat_discount',
  group1: '',
  group2: '',
  applyTo: 'sales_in',
  achievementRate: 0,
  conditions: [],
  impacts: [],
};

const defaultAdjustments: SegmentAdjustment[] = [{
  demand: {
    type: AdjustmentType.PERCENTAGE,
    value: 0,
  },
  nsv: {
    type: AdjustmentType.PERCENTAGE,
    value: 0,
  },
  gsv: {
    type: AdjustmentType.PERCENTAGE,
    value: 0,
  },
  cogs: {
    type: AdjustmentType.PERCENTAGE,
    value: 0,
  },
  cp: {
    type: AdjustmentType.DAYS,
    value: 0,
  },
  ss: {
    type: AdjustmentType.DAYS,
    value: 0,
  },
  startDate: '',
  endDate: '',
}];

@Component({
  selector: 'cel-trade-terms',
  templateUrl: './trade-terms.component.html',
  styleUrls: ['./trade-terms.component.scss'],
})
export class TradeTermsComponent implements OnInit {
  /** The impact segment being edited. */
  @Input() set value(s: SegmentImpact) {
    // clone segment impact object
    const nextImpact: SegmentImpact = JSON.parse(JSON.stringify(s || {}));

    if (!nextImpact.segment) {
      nextImpact.segment = {
        formatVersion: 'v1',
        type: SegmentType.Filter,
      };
    } 
    if (!nextImpact.tradeTerm) {
      nextImpact.tradeTerm = { ...defaultTradeTerm };
    }
    // give the segment a name
    nextImpact.segment.name = nextImpact.segment.name || Math.random().toString(36).substr(3, 6);
    nextImpact.adjustments = nextImpact.adjustments || [];

    this.impact = nextImpact;
    const tradeTerm = this.impact?.tradeTerm;
    this.selectedTradeTerm = tradeTerm?.type as TradeTerms || TradeTerms.FLAT_DISCOUNT;
    this.selectedTradeTermGroup1 = tradeTerm?.group1 as string || '';
    this.selectedTradeTermGroup2 = tradeTerm?.group2 as string || '';
    this.selectedApplyTo = tradeTerm?.applyTo as ApplyTo || ApplyTo.SALES_IN;

    setTimeout(() => {
      void this.updateCount();
    }, 500);
  }

  @Input() dateRange!: SimpleDateRange;

  impact?: SegmentImpact;

  get adjustments() {
    return this.impact?.adjustments;
  }

  get impactValue() {
    return this.impact || {} as SegmentImpact;
  }

  /** The type of the event. */
  @Input() eventType?: EventType;
  /** The plan where to match segmentCriterion. */
  @Input() plan?: Plan;
  @Input() eventDetail?: any;
  sharedDataBtoC: SharedAdjustmentType = {
    sign: AdjustmentSign.INCREASE,
    value: 0,
    type: AdjustmentType.PERCENTAGE,
    checked: false,
  };
  sharedDataCtoB: SharedAdjustmentType = {
    sign: AdjustmentSign.INCREASE,
    value: 0,
    type: AdjustmentType.PERCENTAGE,
    checked: false,
  };

  /** Emits when impact segment is edited inside this component. */
  @Output() valueChanged = new EventEmitter<SegmentImpact>();
  /** Emits when user wants to delete this segment impact. */
  @Output() delete = new EventEmitter<boolean>();

  selectedTradeTerm: TradeTerms = TradeTerms.FLAT_DISCOUNT;
  selectedApplyTo: ApplyTo = ApplyTo.SALES_IN;
  readonly tradeTermDatas = [
    { value: TradeTerms.FLAT_DISCOUNT, disabled: false },
    { value: TradeTerms.VOLUME_REBATE_INCENTIVE, disabled: false },
    { value: TradeTerms.BUNDLE_DISCOUNT, disabled: false },
    { value: TradeTerms.DEMAND_IMPACT, disabled: false },
  ];
  applyToDatas = [
    { value: ApplyTo.SALES_IN, disabled: false },
    { value: ApplyTo.SALES_OUT_REIMBURSEMENT, disabled: false },
    { value: ApplyTo.SALES_OUT, disabled: true },
  ];
  selectedTradeTermGroup1: string = '';
  selectedTradeTermGroup2: string = '';
  tradeTermGroups: string[] = [];
  tradeTermGroups$ = this.store.select(select_tradeTermGroups);

  /** Expose enum to template. */
  readonly EventType = EventType;

  constructor(
    private readonly segmentService: SegmentService,
    private readonly store: Store,
    private readonly dialogService: NbDialogService,
  ) { }

  ngOnInit(): void {
    this.tradeTermGroups$.subscribe(tradeTermGroups => {
      this.tradeTermGroups = tradeTermGroups || [];
    })
  }

  onSelectTradeTerm(value: TradeTerms): void {
    if (value === TradeTerms.DEMAND_IMPACT) {
      this.applyToDatas = this.applyToDatas.map(item => ({
        ...item,
        disabled: item.value !== ApplyTo.SALES_OUT
      }));
      this.selectedApplyTo = ApplyTo.SALES_OUT;
    } else {
      // Reset to original state or any other logic for different trade term
      this.applyToDatas = [
        { value: ApplyTo.SALES_IN, disabled: false },
        { value: ApplyTo.SALES_OUT_REIMBURSEMENT, disabled: false },
        { value: ApplyTo.SALES_OUT, disabled: true },
      ];
      this.selectedApplyTo = ApplyTo.SALES_IN;
    }

    this.updateTradeTermField('applyTo', this.selectedApplyTo);
    this.selectedTradeTerm = value;
    this.updateTradeTermField('type', this.selectedTradeTerm);

    if ([TradeTerms.DEMAND_IMPACT, TradeTerms.FLAT_DISCOUNT].includes(value)) {
      // Clear trade term conditions and impacts
      const clearTradeTerm = {
        conditions: [],
        impacts: [],
      };
      this.updateTradeTerm(clearTradeTerm);
    }

    if (![TradeTerms.DEMAND_IMPACT, TradeTerms.FLAT_DISCOUNT].includes(value)) {
      // Clear impact adjustments if trade term is not DEMAND_IMPACT or FLAT_DISCOUNT
      this.resetAdjustments([]);
    } else if (this.impact && (!this.impact.adjustments || this.impact.adjustments.length === 0)) {
      // Reset to default value if adjustments do not exist, for cases VOLUME_REBATE_INCENTIVE and BUNDLE_DISCOUNT
      this.resetAdjustments(defaultAdjustments);
    }
  }

  onSelectTradeTermGroup(groupNumber: number, value: string): void {
    if (groupNumber === 1) {
      this.selectedTradeTermGroup1 = value;
      this.updateTradeTermField('group1', this.selectedTradeTermGroup1);
    } else if (groupNumber === 2) {
      this.selectedTradeTermGroup2 = value;
      this.updateTradeTermField('group2', this.selectedTradeTermGroup2);
    }
  }

  onChangeApplyTo(value: ApplyTo) {
    this.selectedApplyTo = value;
    this.updateTradeTermField('applyTo', this.selectedApplyTo);
  }

  resetAdjustments(value: SegmentAdjustment[]) {
    if (!this.impact) return;
    this.impact.adjustments = value;
    this.valueChanged.emit(this.impact); 
  }

  updateTradeTermField(field: string, value: any) {
    if (!this.impact) return;
    const tradeTerm = this.impact.tradeTerm || { ...defaultTradeTerm };
    tradeTerm[field] = value;
    this.impact.tradeTerm = tradeTerm;
    this.valueChanged.emit(this.impact); 
  }

  isDemandImpact(): boolean {
    return [TradeTerms.DEMAND_IMPACT].includes(this.selectedTradeTerm); 
  }

  isSelectSegmentVisible(): boolean {
    const eventDetailDefined = this.eventDetail != undefined ? !this.eventDetail : false;
    const eventTypeDefined = !!this.eventType;
    const isNotInventoryOrCogs = ![EventType.INVENTORY_PARAMETERS, EventType.COGS].includes(this.eventType || '' as EventType);
    const isSelectedTradeTermIncluded = [TradeTerms.FLAT_DISCOUNT, TradeTerms.DEMAND_IMPACT].includes(this.selectedTradeTerm);
  
    return eventDetailDefined && eventTypeDefined && isNotInventoryOrCogs && isSelectedTradeTermIncluded;
  }

  handleDataChanged(child: 'B' | 'C', data: PartialSharedAdjustmentType): void {
    if (child === 'B') {
      this.sharedDataBtoC = { ...this.sharedDataBtoC, ...data };
    } else if (child === 'C') {
      this.sharedDataCtoB = { ...this.sharedDataCtoB, ...data };
    }
  }

  updateSegment(segment: Segment) {
    if (!this.impact) return;
    this.impact.segment = segment;
    this.valueChanged.emit(this.impact);
    void this.updateCount();
  }

  updateTradeTerm(data: Partial<TradeTerm>) {
    if (!this.impact) return;
    const tradeTerm = { ...(this.impact.tradeTerm || { ...defaultTradeTerm }), ...data };
    this.impact.tradeTerm = tradeTerm;
    this.valueChanged.emit(this.impact); 
  }

  resetAdjustment(segmentAdjustment: SegmentAdjustment, key: AdjustmentKey) {
    if (!this.impact) return;
    if ([AdjustmentKey.CP, AdjustmentKey.SS].includes(key)) {
      segmentAdjustment[key] = { type: AdjustmentType.DAYS, value: 0 };
    } else {
      segmentAdjustment[key] = { type: AdjustmentType.PERCENTAGE, value: 0 };
    }
  }

  updateAdjustment(segmentAdjustment: SegmentAdjustment, key: string, adjustment: Adjustment) {
    if (!this.impact) {
      return;
    }

    segmentAdjustment[key] = adjustment;
    // clear GSV
    if (this.eventType !== EventType.GENERAL_PRICING) {
      this.resetAdjustment(segmentAdjustment, AdjustmentKey.GSV);
    }
    // clear NSV
    if (this.eventType !== EventType.PROMOTION_CAMPAIGN) {
      this.resetAdjustment(segmentAdjustment, AdjustmentKey.NSV);
    }
    if (this.eventType !== EventType.INVENTORY_PARAMETERS) {
      this.resetAdjustment(segmentAdjustment, AdjustmentKey.CP);
      this.resetAdjustment(segmentAdjustment, AdjustmentKey.SS);
    }
    if (this.eventType !== EventType.COGS) {
      this.resetAdjustment(segmentAdjustment, AdjustmentKey.COGS);
    }
    this.valueChanged.emit(this.impact);
  }

  adapter = {
    search: (term: string, entity: string, customerType: string) =>
      this.segmentService.autocomplete({ term, entity, customerType, planId: this.plan?.id || '' }),
    count: (value: Segment) => this.segmentService.count(value, this.plan?.id || ''),
  };

  count?: { product: number; customer: number, dc: number };

  async updateCount() {
    if (!this.impact) return;
    this.count = await this.adapter.count(this.impact.segment).toPromise();
  }

  // savedSegmentsObs = this.state.segmentsObs;
  savedSegmentsObs = this.store.select(select_segments);

  useSavedSegment(s: Segment) {
    this.updateSegment(s);
  }

  addAdjustment() {
    if (!this.impact) return;
  
    const newAdjustment: SegmentAdjustment = {
      demand: { type: AdjustmentType.PERCENTAGE, value: 0 },
      startDate: '',
      endDate: '',
    };

    this.impact.adjustments.push(newAdjustment);
    this.valueChanged.emit(this.impact);
  }

  deleteAdjustment(index: number) {
    if (!this.impact || !this.impact.adjustments) return;
  
    if (index >= 0 && index < this.impact.adjustments.length) {
      this.impact.adjustments.splice(index, 1);
  
      this.valueChanged.emit(this.impact);
    }
  }

  updateImpact(impact: SegmentImpact) {
    this.impact = impact;
    this.valueChanged.emit(impact);
  }

  onDateRangeChanged(adjustmentIndex: number, newDateRange: SimpleDateRange): void {
    this.dateRange = { ...newDateRange };
  
    if (this.impact && this.impact.adjustments && adjustmentIndex >= 0 && adjustmentIndex < this.impact.adjustments.length) {
      const adjustment = this.impact.adjustments[adjustmentIndex];
      adjustment.startDate = newDateRange.start || adjustment.startDate;
      adjustment.endDate = newDateRange.end || adjustment.endDate;
      this.valueChanged.emit(this.impact);
    }
  }

  trackByFn(index: number): number {
    return index;
  }

  getTradeTermLabel(tradeTerm: string): string {
    return TRADETERM_LABELS.find((f) => f.value === tradeTerm)?.label;
  }

  getApplyToLabel(applyTo: string): string {
    return APPLYTO_LABELS.find((f) => f.value === applyTo)?.label;
  }

  index = 1;
  currentGroupNamePair: { oldName: string, newName: string } | undefined = undefined;
  @ViewChild(NbPopoverDirective) popover!: NbPopoverDirective;
  currentGroup: string = ''; 

  addItem(input: HTMLInputElement): void {
    let value = input.value?.trim();
    const highestGroupNumber = this.tradeTermGroups.reduce((maxNumber, groupName) => {
      const groupNumberMatch = groupName.match(/Group (\d+)/);
      if (groupNumberMatch) {
        const groupNumber = parseInt(groupNumberMatch[1]);
        return groupNumber > maxNumber ? groupNumber : maxNumber;
      }
      return maxNumber;
    }, 0);

    let generatedName = '';

    if (!value) {
      generatedName = `Group ${highestGroupNumber + 1}`;
    } else if (this.tradeTermGroups.indexOf(value) === -1) {
      generatedName = value;
    } else {
      generatedName = `Group ${highestGroupNumber + 1}`;
    }

    if (this.tradeTermGroups.indexOf(generatedName) === -1) {
      this.store.dispatch(SET_TRADE_TERM_GROUPS({ tradeTermGroups: [...this.tradeTermGroups, generatedName] }));
    }
  }

  getCustomerType(): 'customer' | 'distributor' {
    return this.selectedApplyTo === ApplyTo.SALES_IN ? 'distributor' : 'customer';
  }
  handleButtonClick(event: MouseEvent, group: string) {
    event.stopPropagation();

    const popover = this.popover;
    if (popover) {
      if (popover.isShown) {
        popover.hide();
      } else {
        popover.show();
      }
    }

    this.currentGroup = group;
  }

  openRenameGroup(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, {
      context: this.currentGroup,
    });
  }

  openConfirmDelete(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, {
      context: this.currentGroup,
    });
  }

  changeRenameGroup(value: string, group: string) {
    this.currentGroupNamePair = { oldName: group, newName: value };
  }

  editGroup() {
    if (this.currentGroupNamePair == undefined) {
      return;
    }
    const { oldName, newName } = this.currentGroupNamePair || {};
    this.tradeTermGroups = (this.tradeTermGroups || []).map(group => group === oldName ? newName : group);
    this.store.dispatch(SET_TRADE_TERM_GROUPS({ tradeTermGroups: this.tradeTermGroups }));
    if (oldName == this.selectedTradeTermGroup1) {
      this.onSelectTradeTermGroup(1, newName);
    }
    if (oldName == this.selectedTradeTermGroup2) {
      this.onSelectTradeTermGroup(2, newName);
    }

    this.currentGroupNamePair = undefined;
  }

  deleteGroup(group: string) {
    this.store.dispatch(SET_TRADE_TERM_GROUPS({ tradeTermGroups: (this.tradeTermGroups || []).filter(g => g !== group) }));
    if (group == this.selectedTradeTermGroup1) {
      this.onSelectTradeTermGroup(1, '');
    }
    if (group == this.selectedTradeTermGroup2) {
      this.onSelectTradeTermGroup(2, '');
    }
  }
}
