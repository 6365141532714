import { createAction, props } from '@ngrx/store';

import { EtlTask } from '@/app/@core/backend/simcel/etl.service';

export const FETCH_RUNNING_ETL_TASKS = createAction('[⚡ Layout] Fetch running etl task');

export const FETCH_RUNNING_ETL_TASKS_SUCCESS = createAction(
  '[🤖 Layout] Fetch running etl task success',
  props<{ data: EtlTask[] }>(),
);

export const FETCH_RUNNING_ETL_TASKS_FAILED = createAction(
  '[🤖 Layout] Fetch running etl task failed',
  props<{ error: any }>(),
);
