import { createReducer, on } from '@ngrx/store';

import type { Event, EventVersion } from '@/app/@core/interfaces/business/event';
import {
  LOAD_EVENTS,
  LOAD_EVENTS_FAILED,
  LOAD_EVENTS_INCREMENTAL_COMPLETE,
  LOAD_EVENTS_SUCCESS,
  SAVE_EVENT_VERSION,
  SAVE_EVENT_VERSION_FAILED,
  SAVE_EVENT_VERSION_SUCCESS,
  TOGGLE_COLLAPSE_EVENT_LIST
} from './event.actions';
import {
  BEGIN_EDIT_EVENT_VERSION
} from '../pages/event-management/event-management.actions';

export const EVENT_STATE_KEY = 'event';

/*************************************
 * State
 *************************************/

export interface IEventState {
  data: ReadonlyArray<Event>;
  loading: boolean;
  saving: boolean;
  editing: boolean;
  isEventListCollapsed: boolean;
  incEventsLoadVersion: number;
  errors: string[];
}

export const initialState: IEventState = {
  data: [],
  loading: false,
  saving: false,
  editing: false,
  isEventListCollapsed: false,
  incEventsLoadVersion: 0,
  errors: [],
};

/*************************************
 * Reducers
 *************************************/

export const eventReducer = createReducer(
  initialState,
  on(LOAD_EVENTS, (state: IEventState): IEventState => ({ ...state, loading: true })),
  on(LOAD_EVENTS_INCREMENTAL_COMPLETE, (state: IEventState): IEventState => ({ ...state, incEventsLoadVersion: state.incEventsLoadVersion + 1 })),
  on(LOAD_EVENTS_SUCCESS, (state, { data }): IEventState => ({ ...state, loading: false, data })),
  on(
    LOAD_EVENTS_FAILED,
    (state, { error }): IEventState => ({
      ...state,
      loading: false,
      data: [],
      errors: error,
    }),
  ),
  on(BEGIN_EDIT_EVENT_VERSION, (state): IEventState => ({
    ...state, 
    loading: false, 
    saving: false, 
    editing: true
  })),
  on(SAVE_EVENT_VERSION, (state): IEventState => ({ ...state, saving: true })),
  on(SAVE_EVENT_VERSION_SUCCESS, (state): IEventState => ({ ...state, saving: false })),
  on(
    SAVE_EVENT_VERSION_FAILED,
    (state, { error }): IEventState => ({ ...state, saving: false, errors: error }),
  ),
  on(TOGGLE_COLLAPSE_EVENT_LIST, (state, { isCollapsed }): IEventState => ({ ...state, isEventListCollapsed: isCollapsed })),
);
