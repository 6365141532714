<div class="scenarios example-list"
(cdkDropListDropped)="drop($event)"
cdkDropList cdkDropListOrientation="horizontal" 
>
  <cel-planning-scenario-item
    cdkDrag
    *ngFor="let scenario of scenarios; let i = index"
    [cdkDragData]="scenario"
    [plan]="getPlan(scenario.planRef)"
    [plans]="plans"
    [selectedPlan]="plan"
    [scenario]="scenario"
    [scenarios]="scenarios"
    [isPrimary]="primary?.id === scenario.id"
    [isHighlighted]="highlighted?.id === scenario.id"
    (setPrimary)="setPrimary.emit($event)"
    (setHighlighted)="setHighlighted.emit($event)"
    (edit)="edit.emit($event)"
    (hide)="hide.emit($event)"
    (delete)="delete.emit($event)"
    (clone)="clone.emit($event)"
    (download)="download.emit($event)"
    class="planning-scenario-item"
  ></cel-planning-scenario-item>
</div>
<div class="scenarios-btn">
  <button
    *ngIf="isMaxDisplayedCountReached()"
    nbButton
    fullWidth
    size="small"
    class="add-button"
    status="primary"
    disabled
  >
    <nb-icon icon="plus-circle-outline" class="plus-button" ></nb-icon>
    Add Dataset
  </button>
  <button
    *ngIf="!isMaxDisplayedCountReached()"
    nbButton
    fullWidth
    size="small"
    class="add-button"
    status="primary"
    [nbPopover]="dialog"
    nbPopoverPlacement="bottom"
    [class.popover-shown]="!!popover?.isShown"
  >
    <div *ngIf="!!popover?.isShown" class="row content">
      Scenario Title
      <div class="col"></div>
      <nb-icon icon="arrow-ios-downward-outline"></nb-icon>
    </div>
    <div *ngIf="!popover?.isShown">
      <nb-icon icon="plus-circle-outline" class="plus-button" ></nb-icon>
      Add Dataset
    </div>
  </button>
</div>

<ng-template #dialog let-data>
  <div class="dialog-content">
    <nb-list *ngIf="canManageScenario" class="scenario-list">
      <nb-list-item *ngIf = "!readonly" nbButton ghost class="scenario-select -new" (click)="createScenario()">
        <span>Create a New Scenario</span>
      </nb-list-item>
    </nb-list>
    <table [nbTreeGrid]="treeData">
      <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: ['name']"></tr>
      <ng-container nbTreeGridColumnDef="name">
        <td nbTreeGridCell *nbTreeGridCellDef="let row">
          <div class="button-container">
            <cel-planning-scenario-badge
              class="badge-absolute"
              *ngIf="isPrimaryScenario(row.data.dataset?.id)"
              [primary]="isPrimaryScenario(row.data.dataset?.id)"
            ></cel-planning-scenario-badge>
            <a 
              href="javascript:void(0)"
              ghost
              (click)="addScenario(row.data.dataset)"
              [style.paddingLeft]="'' + (row.level + 1) * 25 + 'px'"
              class="scenario-select -created"
              [nbPopover]="nbPopoverUnselectedScenario"
              [nbTooltipDisabled]="!row.data.dataset"
              nbPopoverTrigger="hint"
              nbPopoverPlacement="left"
            >
              {{ row.data.name }}
              <nb-icon nbSuffix icon="chevron-right-outline" *ngIf="!row.expanded && row.children?.length > 0" class="float-right"></nb-icon>
              <nb-icon nbSuffix icon="chevron-down-outline" *ngIf="row.expanded && row.children?.length > 0" class="float-right"></nb-icon>
            </a>
            <button
              *ngIf="row.data.dataset && canManageScenario && (isScenarioEditable(row.data.dataset) || isScenarioDeletable(row.data.dataset))"
              nbButton
              ghost
              size="small"
              shape="round"
              [nbPopover]="moreAction"
              [nbPopoverContext]="row.data.dataset"
              nbPopoverTrigger="click"
              nbPopoverPlacement="bottom"
              class="btnAdd"
              (click)="popover.toggle(); $event.stopPropagation()"
              #popover="nbPopover"
            >
              <nb-icon icon="more-vertical-outline"></nb-icon>
            </button>
          </div>
          <ng-template #nbPopoverUnselectedScenario>
            <div class="nbPopoverUnselectedScenario">
              <p><b>Dataset Name:</b> {{ row.data.dataset?.name }} </p>
              <p><b>From Plan:</b> {{ getPlan(row.data.dataset?.planRef)?.name || previousPlan }} </p>
            </div>
          </ng-template>
        </td>
      </ng-container>
    </table>
  </div>
</ng-template>

<ng-template #moreAction let-scenario>
  <nb-list>
    <nb-list-item 
      *ngIf="isScenarioEditable(scenario)"
      class="scenario-btn -list-item"
      nbButton ghost (click)="addAndEdit(scenario)"
    >
      <nb-icon class="scenario-btn -edit" icon="edit"></nb-icon>
      <label class="scenario-btn -edit -label">Edit</label>
    </nb-list-item>
    <nb-list-item
      *ngIf="isScenarioDeletable(scenario)"
      class="scenario-btn -list-item"
      nbButton ghost
      (click)="delete.emit(scenario)"
    >
      <nb-icon class="scenario-btn -remove" icon="trash-2-outline"></nb-icon>
      <label class="scenario-btn -remove -label">Delete</label>
    </nb-list-item>
  </nb-list>
</ng-template>
