import { Activity } from '@/app/@core/interfaces/business/activity';
import { Event, EventVersion } from '@/app/@core/interfaces/business/event';
import { createAction, props } from '@ngrx/store';

export const CANCEL_EVENT_VERSION_EDIT = createAction('[🤖 EventManage Page] Cancel Edit');

/** Delete an event version. */
export const DELETE_EVENT_VERSION = createAction(
  '[🤖 EventManage Page] Delete EventVersion',
  props<{ event: Event; version: EventVersion }>(),
);

/** Edit an event version. */
export const BEGIN_EDIT_EVENT_VERSION = createAction(
  '[⚡️ EventManage Page] Edit EventVersion',
  props<{ event: Event; version: EventVersion }>(),
);

export const VIEW_EVENT = createAction(
  '[⚡️ EventManage Page] View EventVersion',
  props<{ event: Event; version: EventVersion }>(),
);

/** Handler when create new event has been fired. */
export const CREATE_NEW_EVENT_VERSION_LOCALLY = createAction(
  '[⚡️ EventManage Page] Create New EventVersion locally',
);

/** Handler when an event is selected to be edited. */
export const CREATE_NEW_EVENT_VERSION_LOCALLY_SUCCESS = createAction(
  '[🤖 Event API] Create new EventVersion locally success',
  props<{ version: EventVersion }>(),
);

/** Handler when an event is selected to be edited. */
export const PRESET_EVENT_VERSION_DATE = createAction(
  '[🤖 Event API] preset event version date',
  props<{ startDate: Date | null }>(),
);

export const LOAD_ACTIVITIES = createAction(
  '[🤖 Event API] Load Activities',
);

export const LOAD_ACTIVITIES_SUCCESS = createAction(
  '[🤖 Event API] Load Activities Success',
  props<{ activities: Activity[] }>(),
);

export const LOAD_ACTIVITIES_FAILED = createAction(
  '[🤖 Event API] Load Activities Failed',
  props<{ error: any }>(),
);

export const SET_TRADE_TERM_GROUPS = createAction(
  '[🤖 Event API] Set Trade Term Groups',
  props<{ tradeTermGroups: string[] }>(),
);
