import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { equals } from 'rambda';
import {
  NbGetters,
  NbPopoverDirective,
  NbTreeGridDataSource,
  NbTreeGridDataSourceBuilder,
} from '@nebular/theme';
import { SegmentFilter } from '../../../@core/interfaces/business/segment';
import { Graph, Setting, Vertex } from './graph';
import { combineLatest, map, debounceTime, startWith, Subject, switchMap, BehaviorSubject } from 'rxjs';

const PAGE_SIZE = 30;

enum GraphType {
  Graph = 'graph',
  SecondaryGraph = 'secondaryGraph'
}

const NO_DISTRIBUTORS_FILTER = [{ parentSelected: ['No distributors selected'] }];
const NO_CUSTOMERS_FILTER = [{ parentSelected: ['No customers selected'] }];

@Component({
  selector: 'cel-select-tree',
  templateUrl: './select-tree.component.html',
  styleUrls: ['./select-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectTreeComponent {

  constructor(private ref: ChangeDetectorRef) {
    // ref.detach();
  }

  preview = '';
  graph = new Graph();
  secondaryGraph = new Graph();
  query$ = new Subject<string>();
  private _filter: SegmentFilter[] = [];
  private _secondaryFilter: SegmentFilter[] = [];
  top$ = new BehaviorSubject<number>(PAGE_SIZE);

  @Input() placeholder = '';
  @Input() searchPlaceholder = '';
  searchKey = '';

  @Input() disabledPopup: boolean = false;
  @Input() isProxy: boolean = false;
  @Input() limit = 0;
  selectedSize = 0;
  private _isMultiFacility: boolean = false;
  @Input()
  set isMultiFacility(value: boolean) {
    this._isMultiFacility = value;
    this.refresh();
  }
  get isMultiFacility(): boolean {
    return this._isMultiFacility;
  }
  
  parentExpanded = false;
  parentSelected = true;
  parentIndeterminate = false;
  secondaryParentExpanded = false;
  secondaryParentSelected = true;
  secondaryParentIndeterminate = false;

  @Input() set settings(settings: Setting[]) {
    if (!settings) {
      return;
    }
    // clone the settings
    settings = settings.map(s => Object.fromEntries(Object.entries(s)) as Setting);
    let prev = '';
    for (let i = 0; i < settings.length; i++) {
      const s = settings[i];
      const t = settings[i + 1];
      if (!s.outset && t) {
        s.outset = [t.type];
      }
      if (!s.key) {
        s.key = `${prev}:${s.type}`;
        prev = s.key;
      }
    }
    this.graph.settings = settings;
  }

  @Input() set secondarySettings(settings: Setting[]) {
    if (!settings) {
      return;
    }
    // clone the settings
    settings = settings.map(s => Object.fromEntries(Object.entries(s)) as Setting);
    let prev = '';
    for (let i = 0; i < settings.length; i++) {
      const s = settings[i];
      const t = settings[i + 1];
      if (!s.outset && t) {
        s.outset = [t.type];
      }
      if (!s.key) {
        s.key = `${prev}:${s.type}`;
        prev = s.key;
      }
    }
    this.secondaryGraph.settings = settings;
  }

  @Input() set data(data: any[]) {
    if (data.length < 1) {
      return;
    }
    this.graph.build(data);
    this.refresh();
    this.updateParent();
  }

  @Input() set secondaryData(data: any[]) {
    if (data.length < 1) {
      return;
    }
    this.secondaryGraph.build(data);
    this.refresh();
    this.updateSecondaryParent();
  }

  @Input() set filters(filters: SegmentFilter[]) {
    this._filter = filters || [];
    this.refresh();
  }

  @Input() set secondaryFilters(filters: SegmentFilter[]) {
    this._secondaryFilter = filters || []; 
    this.refresh();
  }

  @Input() showExpandIndicator = false;

  @Output() filtersChange = new EventEmitter();
  @Output() secondaryFiltersChange = new EventEmitter();

  @ViewChild(NbPopoverDirective) set popover(p: NbPopoverDirective) {
    const s = p.nbPopoverShowStateChange.subscribe((state) => {
      s.unsubscribe();
      if (!state.isShown) {
        this.searchKey = '';
        // emit change on popup closed
        this.emitChange();
      }
    });
  }


  onChanged(vertex: Vertex, selected: boolean) {
    if (this.isProxy) {
      // If it's Proxy Product/Customer ==> can only select 1 vertex at a time
      this.deselectAll();
    }
    vertex.updateSelect(selected);
    
    this.updateParent();
    this.updateSecondaryParent();
  }

  updateParent() {
    const flattenGraph = this.graph.flatten();
    const { selected, indeterminate } = this.setParentState(flattenGraph);
    this.parentSelected = selected;
    this.parentIndeterminate = indeterminate;
  }

  updateSecondaryParent() {
    const flattenSecondaryGraph = this.secondaryGraph.flatten();
    const { selected, indeterminate } = this.setParentState(flattenSecondaryGraph);
    this.secondaryParentSelected = selected;
    this.secondaryParentIndeterminate = indeterminate;
  }

  setParentState(flattenGraph: Vertex[]) {
    const allSelected = flattenGraph.every(g => g.selected);
    const noneSelected = flattenGraph.every(g => !g.selected);
    const someIndeterminate = flattenGraph.some(g => g.indeterminate);
    const parentState = {
      selected: !noneSelected,
      indeterminate: !(allSelected || noneSelected) || someIndeterminate,
    };

    return parentState;
  };

  emitChange() {
    const filters = this.limit > 0 ? this.graph.getAllSelectedCompact() : this.graph.compact();
    if (this.isMultiFacility && !this.parentSelected && this.secondaryParentSelected) {
      this.filtersChange.emit(NO_DISTRIBUTORS_FILTER);  
    } else {
      this.filtersChange.emit(filters);
    }
    
    const secondaryFilters = this.limit > 0 ? this.secondaryGraph.getAllSelectedCompact() : this.secondaryGraph.compact();
    if (this.isMultiFacility && !this.secondaryParentSelected && this.parentSelected) {
      this.secondaryFiltersChange.emit(NO_CUSTOMERS_FILTER);
    } else {
      this.secondaryFiltersChange.emit(secondaryFilters);
    }
  }

  refresh() {
    const filters = !this.isMultiFacility && equals(this._filter, NO_CUSTOMERS_FILTER) ? [] : this._filter;
    this.graph.applyFilters(filters);

    const secondaryFilters = this._secondaryFilter;
    this.secondaryGraph.applyFilters(secondaryFilters);

    this.parentSelected = !equals(this._filter, NO_DISTRIBUTORS_FILTER);
    this.secondaryParentSelected = this.isMultiFacility && !equals(this._secondaryFilter, NO_CUSTOMERS_FILTER);

    this.query$.next('');
    const strs: string[][] = [];
    if (this.parentSelected) {
      if (filters.length === 0 && secondaryFilters.length > 0) {
        strs.push(['Distributors']);
      }
      for (const filter of filters) {
        const filterValues = Object.values(filter);
        const lastElement = filterValues[filterValues?.length - 1];
        const s = lastElement?.length === 1
          ? [lastElement?.join(',')]
          : filterValues?.map((v) => v.join(','));
        strs.push(s);
      }
    }
    if (this.secondaryParentSelected) {
      if (secondaryFilters.length === 0 && filters.length > 0) {
        strs.push(['Customers']);
      }
      for (const filter of secondaryFilters) {
        const filterValues = Object.values(filter);
        const lastElement = filterValues[filterValues?.length - 1];
        const s = lastElement?.length === 1
          ? [lastElement?.join(',')]
          : filterValues?.map((v) => v.join(','));
        strs.push(s);
      }
    }
    this.selectedSize = Math.max(...strs.map(s => s.length));
    this.placeholder = strs.join(';');
  }

  selectAll(graphType?: GraphType) {
    if (!graphType || graphType === GraphType.Graph) {
      this.parentSelected = true;
      this.parentIndeterminate = false;
      this.graph.getEntries().forEach(e => e.updateSelect(true));
    }
    if (!graphType || graphType === GraphType.SecondaryGraph) {
      this.secondaryParentSelected = true;
      this.secondaryParentIndeterminate = false;
      this.secondaryGraph.getEntries().forEach(e => e.updateSelect(true));
    }
  }

  deselectAll(graphType?: GraphType) {
    if (!graphType || graphType === GraphType.Graph) {
      this.parentSelected = false;
      this.parentIndeterminate = false;
      this.graph.getEntries().forEach(e => e.updateSelect(false));
    }
    if (!graphType || graphType === GraphType.SecondaryGraph) {
      this.secondaryParentSelected = false;
      this.secondaryParentIndeterminate = false;
      this.secondaryGraph.getEntries().forEach(e => e.updateSelect(false));
    }
  }

  search(e: InputEvent) {
    const q = (e.target as any).value || '';
    if (q.length < 2) {
      this.graph.collapse();
      this.secondaryGraph.collapse();
    }
    this.query$.next(q);
    this.top$.next(PAGE_SIZE);
  }

  toggle(v: Vertex) {
    v.expanded = !v.expanded;
    setTimeout(() => this.ref.detectChanges(), 100);
    this.top$.next(this.top$.value);
  }

  loadMore() {
    this.top$.next(this.top$.value + 10);
  }

  trackBy(index: number, v: Vertex) {
    return v.id;
  }

  rows$ = this.query$.pipe(
    startWith(''),
    debounceTime(250),
    switchMap(q => this.top$.pipe(
      map(_ => this.graph.flatten(q))
    )),
  );

  viewRows$ = combineLatest(
    [this.rows$, this.top$]
  ).pipe(
    map(([rows, top]) => rows.slice(0, top))
  );

  canLoadMore$ = combineLatest(
    [this.rows$, this.top$]
  ).pipe(
    map(([rows, top]) => rows.length > top)
  );

  secondaryRows$ = this.query$.pipe(
    startWith(''),
    debounceTime(250),
    switchMap(q => this.top$.pipe(
      map(_ => this.secondaryGraph.flatten(q))
    )),
  );

  secondaryViewRows$ = combineLatest(
    [this.secondaryRows$, this.top$]
  ).pipe(
    map(([rows, top]) => rows.slice(0, top))
  );

  toggleParent() {
    this.parentExpanded = !this.parentExpanded;
  }

  onParentChanged(event: any) {
    this.parentSelected = event;
    if (!this.parentSelected) {
      this.deselectAll(GraphType.Graph);
    } else {
      this.selectAll(GraphType.Graph);
    }
  }

  toggleSecondaryParent() {
    this.secondaryParentExpanded = !this.secondaryParentExpanded;
  }

  onSecondaryParentChanged(event: any) {
    this.secondaryParentSelected = event;
    if (!this.secondaryParentSelected) {
      this.deselectAll(GraphType.SecondaryGraph);
    } else {
      this.selectAll(GraphType.SecondaryGraph);
    }
  }
}
