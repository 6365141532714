import { SegmentFilter } from "@/app/@core/interfaces/business/segment";

export const enum FilterType {
  Products,
  Customers,
  Locations,
}

export function sortOptionsByFields(arr: any, filterType: FilterType) {
  switch (filterType) {
    case FilterType.Products:
      return sortArrayByProducts(arr);

    case FilterType.Customers:
      return sortArrayByCustomers(arr);

    case FilterType.Locations:
      return sortArrayByLocations(arr);

    default:
      return arr;
  }
}

export function sortArrayByProducts(arr: any) {
  return [...arr].sort((a, b) => {
    if (a.category < b.category) return -1;
    if (a.category > b.category) return 1;

    if (a.subCategory < b.subCategory) return -1;
    if (a.subCategory > b.subCategory) return 1;

    if (a.brands < b.brands) return -1;
    if (a.brands > b.brands) return 1;

    if (a.StorageCondition < b.StorageCondition) return -1;
    if (a.StorageCondition > b.StorageCondition) return 1;

    if (a.productName < b.productName) return -1;
    if (a.productName > b.productName) return 1;

    return 0;
  });
}

export function sortArrayByCustomers(arr: any) {
  return [...arr].sort((a, b) => {
    if (a.channel < b.channel) return -1;
    if (a.channel > b.channel) return 1;

    if (a.subChannel < b.subChannel) return -1;
    if (a.subChannel > b.subChannel) return 1;

    if (a.shipTo < b.shipTo) return -1;
    if (a.shipTo > b.shipTo) return 1;

    if (a.keyAccount < b.keyAccount) return -1;
    if (a.keyAccount > b.keyAccount) return 1;

    return 0;
  });
}

export function sortArrayByLocations(arr: any) {
  return [...arr].sort((a, b) => {
    if (a.country < b.country) return -1;
    if (a.country > b.country) return 1;

    if (a.region < b.region) return -1;
    if (a.region > b.region) return 1;

    if (a.city < b.city) return -1;
    if (a.city > b.city) return 1;

    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;

    return 0;
  });
}

export function createBreadcrumb(segment: SegmentFilter[] | undefined, defaultLabel: string): string[] {
  if (segment && segment.length === 1) {
    const filterValues = Object.values(segment[0]);
    const s = filterValues.map(v => v.length > 1 ? '[multi-selection]' : v.join(','));
    return [defaultLabel, ...s];
  } else {
    return [defaultLabel, '[multi-selection]'];
  }
}

const numberFormatter = new Intl.NumberFormat(undefined, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 9,
});

export function formatNumberInput(input: number): string {
  return numberFormatter.format(input);
}

export function removeThousandSeparators(numberWithThousandSeparators: string) {
  const [thousandSeparator] = getNumericSeparators();
  return numberWithThousandSeparators.replace(new RegExp(`\\${thousandSeparator}`, 'g'), '');
}

export function isNumeric(str: string) {
  const num = Number(str);
  return !isNaN(num) && isFinite(num);
}

export function countDecimalSeparators(inputValue: string) {
  const [, decimalSeparator] = getNumericSeparators();
  const dotCount = (inputValue.match(new RegExp(`\\${decimalSeparator}`, 'g')) || []).length;
  return dotCount;
}

export function sanitizeForNumberInput(str: string) {
  if (isNumeric(str)) {
    return str;
  } else {
    const [, decimalSeparator] = getNumericSeparators();
    return str.replace(new RegExp(`[^\\d${decimalSeparator}]`, 'g'), '').replace(new RegExp(`([^d]*)(\\${decimalSeparator})$`), '$1');
  }
}

export function getLang(): string {
  if (navigator.languages != undefined) 
    return navigator.languages[0] || 'en-US'; 
  return navigator.language || 'en-US';
}

export function findFirstNonNumeric(inputString: string): [string, string] {
  const match = inputString.match(/[^0-9]/g);
  if (match && match.length > 1) {
    return [match[0], match[1]];
  } else {
    return [',', '.'];
  }
}

export function getNumericSeparators(): [string, string] {
  const number = 123456.789;
  const lang = getLang();
  const n = number.toLocaleString(lang);
  const [thousandSeparator, decimalSeparator] = findFirstNonNumeric(n);
  return [thousandSeparator, decimalSeparator];
}
