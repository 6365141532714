import { Injectable } from '@angular/core';
import { HttpService } from '../backend/common/api/http.service';

export interface IListCurrencyParams {
  from: string;
  to: string;
}
@Injectable()
export class CurrencyService {
  constructor(private readonly httpService: HttpService) { }

  getSupportedCurrency() {
    return this.httpService.get('explorer/currency/supported');
  }

  getListCurrency(params: IListCurrencyParams) {
    const { from, to } = params;
    return this.httpService.get(`explorer/currency/?from=${from}&to=${to}`);
  }
}
