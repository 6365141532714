import { Injectable } from '@angular/core';

import { HttpService } from '../backend/common/api/http.service';

@Injectable({ providedIn: 'root' })
export class SupplyPlanService {
  constructor(private readonly api: HttpService) {
  }

  listSupplyPlan() {
    return this.api.get('explorer/supply-plan');
  }
}
