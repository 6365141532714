import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';

import { DateAggregationOption } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.constants';
import { select_selectedWorkspace } from '@/store/workspace/workspace.selectors';
import { Workspace } from '@/app/@core/interfaces/common/workspace';
import { WorkspaceService } from '@/app/@core/utils';

@Component({
  selector: 'cel-date-aggregation-picker',
  template: `<div>
    <button
      *ngFor="let aggregation of dateAggregationLabels"
      [ngClass]="{ selected: aggregation === value }"
      (click)="onDateAggregationChange(aggregation)"
    >
      {{ aggregation }}
    </button>
  </div>`,
  styles: [
    `
      div {
        display: flex;
      }
      button {
        width: 40px;
        height: 11px;
        font-family: simcel-Bw-Mitga;
        font-style: normal;
        font-weight: normal;
        font-size: 9px;
        line-height: 11px;
        text-transform: uppercase;
        color: #686868;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid #686868;
        padding: 0px;
        background-color: white;
        @media (max-width:1025px){
          width:26px;
        }
        &.selected {
          border-bottom-color: #d24a43;
          color: #d24a43;
        }
      }
    `,
  ],
})
export class DateAggregationPicker {
  @Input() value? = DateAggregationOption.MONTH;
  @Input() showYear = true;
  @Output() changed = new EventEmitter<DateAggregationOption>();

  dateAggregationLabels = [
    DateAggregationOption.DAY,
    DateAggregationOption.WEEK,
    DateAggregationOption.MONTH,
    DateAggregationOption.FISCAL_YEAR,
  ];

  displayMonthYearOnly: boolean = false;

  constructor(
    private readonly store: Store,
    private readonly workspaceService: WorkspaceService
  ) {
  }

  ngOnInit(): void {
    this.store.select(select_selectedWorkspace).subscribe((workspace: Workspace) => {
      this.displayMonthYearOnly = this.workspaceService.checkIsDisplayMonthYearOnly(workspace);
      if (this.displayMonthYearOnly) {
        if (this.showYear == false) {
          this.dateAggregationLabels = [
            DateAggregationOption.MONTH
          ];
        } else {
          this.dateAggregationLabels = [
            DateAggregationOption.MONTH,
            DateAggregationOption.FISCAL_YEAR,
          ];
        }
        
        if (!this.value || (this.value != DateAggregationOption.MONTH && this.value != DateAggregationOption.FISCAL_YEAR)) {
          this.value = DateAggregationOption.MONTH;
        }
      } else {
        this.dateAggregationLabels = [
          DateAggregationOption.DAY,
          DateAggregationOption.WEEK,
          DateAggregationOption.MONTH,
          DateAggregationOption.FISCAL_YEAR,
        ];

        if (!this.value) {
          this.value = DateAggregationOption.WEEK;
        }
      }
    });
  }

  onDateAggregationChange(evt: DateAggregationOption) {
    if (this.value !== evt) {
      this.value = evt
      this.changed.emit(evt); 
    }
  }
}
