import { createAction, props } from '@ngrx/store';
import { Notification } from '@/app/@core/interfaces/business/notification';

export const LOAD_NOTIFICATIONS = createAction('[⚡ Notification API] Load Notifications');
export const LOAD_NOTIFICATIONS_SUCCESS = createAction(
  '[🤖 Notification API] Load Notifications success',
  props<{ data: Notification[] }>(),
);
export const LOAD_NOTIFICATIONS_FAILED = createAction(
  '[🤖 Notification API] Load Notifications failed',
  props<{ error: any }>(),
);

export const CHECK_ALL_NOTIFICATIONS_SUCCESS = createAction(
  '[🤖 Notification API] Check Notifications success'
);
export const ADD_NOTIFICATION_SUCCCESS = createAction(
  '[🤖 Notification API] Create Notification success',
  props<{ data: Notification }>(),
);
