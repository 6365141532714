import { EChartsOption } from 'echarts';
import { truncate } from '@/utils/strings';

export function truncDim(dimension) {
  const parts = dimension.split(' - ');
  for (let i = 0; i < parts.length; i++) {
    parts[i] = truncate(parts[i], 10, '...');
  }
  return `${parts[0]} - ${parts[1]}`;
}

export const DEFAULT_ECHARTS_OPTIONS: EChartsOption = {
  grid: {
    show: false,
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  xAxis: [
    {
      type: 'category',
      axisLabel: {
        color: '#000',
        interval: 0,
        rotate: 20,
        fontSize: 12,
        formatter: function (value) {
          return truncDim(value);
        },
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
    }
  ],
  yAxis: [
    {
      type: 'value',
      axisPointer: {
        show: false,
      },
      axisLabel: {
        color: '#000',
        formatter: '{value}',
        fontSize: 12,
      }
    },
    {
      type: 'value',
      axisPointer: {
        show: false,
      },
      axisLabel: {
        formatter: '{value}'
      }
    }
  ],
  series: [
    {
      type: 'bar',
      itemStyle: {
        color: 'rgba(243, 191, 108, 0.4)',
        borderColor: '#F3BF6C',
        borderRadius: 6,
      }
    },
    {
      type: 'line',
      yAxisIndex: 1,
      smooth: true,
      symbol: 'circle',
      lineStyle: {
        color: '#F3BF6C',
        width: 2,
      },
      itemStyle: {
        borderWidth: 1,
        borderColor: '#979797',
        color: '#F3BF6C'
      }
    }
  ]
};
