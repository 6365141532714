import { MasterFieldDisplayNameService } from '@/app/shared/master-field-display-name.service';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { lastValueFrom, of } from 'rxjs';
import { SegmentAdapter } from './segment-select-box.component';

interface Option {
  type: string;
  value: string;
  ref?: string;
}

@Component({
  selector: 'cel-entity-multi-select-box',
  templateUrl: './entity-multi-select-box.component.html',
  styleUrls: ['./entity-multi-select-box.component.scss'],
})
export class EntityMultiSelectBoxComponent implements OnInit {
  options: Option[] = [];

  @Input() selectedOptions: Option[] = [];
  @Input() adapter: SegmentAdapter = {
    search: (_term: string, _entity: string) => of([]),
  };
  @Input() entity = '';

  @Output() update = new EventEmitter<Option[]>();

  constructor(public displayNameService: MasterFieldDisplayNameService) {}

  ngOnInit(): void {
    this.search('');
  }

  async search(term: string) {
    try {
      this.options = await lastValueFrom(this.adapter.search(term, this.entity));
    } catch (e) {
      console.log(e);
    }
  }

  onSelectedChange(selectedOptions: Option[]) {
    this.update.emit(selectedOptions);
  }

  compareByValue(v1, v2): boolean {
    return v1.value === v2.value;
  }
}
