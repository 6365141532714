import { Segment, SegmentFilter } from './segment';
import { TaskStatus } from '../../backend/simcel/task.service';


export type SegmentAdjustment = PromotionCampaignSegmentAdjustments
| GeneralPricingSegmentAdjustments
| InventoryParameterSegmentAdjustments
| COGSSegmentAdjustment

export interface TradeTermProps {
  value: number;
  type: UnitType;
  product?: SegmentFilter[];
}

export enum ConditionUnitType {
  Unit = 'Unit',
  Value = 'Value',
}

export enum ImpactUnitType {
  Percentage = 'percentage',
}

export type UnitType = ConditionUnitType | ImpactUnitType;

export interface TradeTerm {
  startDate: string;
  endDate: string;
  type: string;
  group1: string;
  group2: string;
  applyTo: string;
  achievementRate: number;
  conditions: TradeTermProps[];
  impacts: TradeTermProps[];
}

/** Pairs a list of adjustments that will be applied to a list of segments. */
export interface SegmentImpact {
  segment: Segment;
  adjustments: SegmentAdjustment[];
  proxy: SegmentProxy;
  tradeTerm: TradeTerm;
}

export interface SegmentProxy {
  productId: string,
  customerRef: string,
}

export enum AdjustmentKey {
  DEMAND = 'demand',
  NSV = 'nsv',
  GSV = 'gsv',
  CP = 'cp',
  SS = 'ss',
  COGS = 'cogs'
}

export enum AdjustmentType {
  PERCENTAGE = 'percentage',
  ABSOLUTE = 'absolute',
  DAYS = 'days',
}

/** Unit of adjustment. */
export interface Adjustment {
  /** Value of the adjustment. */
  value: number;
  /** Percentage, absolute value or days. */
  type: AdjustmentType;
}

/** Defines a pricing or demand changes on an event. */
export interface BaseSegmentAdjustments {
  demand: Adjustment;
  startDate: string;
  endDate: string;
}

/** Adjustment for product price and demand. Impacts NSV. */
export interface PromotionCampaignSegmentAdjustments extends BaseSegmentAdjustments {
  nsv?: Adjustment;
}

/** Adjustment for product price and demand. Impacts GSV. */
export interface GeneralPricingSegmentAdjustments extends BaseSegmentAdjustments {
  gsv?: Adjustment;
}

export interface COGSSegmentAdjustment extends BaseSegmentAdjustments {
  cogs?: Adjustment;
}

/** Adjustment for Coverage Period. */
export interface InventoryParameterSegmentAdjustments extends BaseSegmentAdjustments {
  cp?: Adjustment;
  ss?: Adjustment;
}

/** Adjustment only for demand. */
export type GeneralDemandSegmentAdjustments = BaseSegmentAdjustments;

export enum EventType {
  PROMOTION_CAMPAIGN = 'promotion',
  GENERAL_PRICING = 'pricing adjustment',
  GENERAL_DEMAND = 'demand adjustment',
  NEW_DEMAND_STREAM = 'new_demand_stream',
  TRADE_TERMS = 'trade_terms',
  INVENTORY_PARAMETERS = 'inventory_parameters',
  PRODUCTION_CAPACIY = 'production_capacity',
  SHELF_LIFE_ACCEPTANCE = 'shelf_life_acceptance',
  MIN_TRUCK_FILL_RATE = 'min_truck_fill_rate',
  MOQ = 'moq',
  COGS = 'cogs',
  STORAGE_HANDLING_COST = 'storage_handling_cost',
  TRANSPORT_COST = 'transport_cost',
}

/** An event that could affect pricing and demand forecast. */
export interface Event {
  id: string;
  name: string;
  type: EventType;
  versions: EventVersion[];
}

/** A revision of an event. */
export interface EventVersion {
  id: string;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  segmentImpacts: SegmentImpact[];
  /** Deprecated. ID of the parent event. */
  eventId?: string;
  // A list of task ids of task that are related to this scenario
  tasks?: string[];
  // an obs storing a summarised status of all the related tasks
  tasksSummary?: {
    error?: string;
    status: TaskStatus;
  };
}

export function sortEventsByName(events: Array<Event>): Event[] {
  return (events || []).sort((a, b) => {
    return a.name.localeCompare(b.name)
  });
}
