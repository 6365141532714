import { EChartsOption, LineSeriesOption } from 'echarts';
import { sum } from 'lodash';
import { format, parseISO, addYears } from 'date-fns';

import { DateAggregationOption } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.constants';
import { PROP_TO_COLUMN_LOOKUP } from '@/app/@core/constants/entity-matcher.constants';
import { ISitDataResponse, ISitTreeNode, ISitAggregateResult } from '@/app/@core/entity/drp.service';
import { notNullOrUndefined } from '../../../utils/notNullOrUndefined';

// mainType?: 'axisPointer';
// type?: 'line' | 'shadow' | 'cross' | 'none';
// link?: AxisPointerLink[];
/** Override configs for timeseries chart. */
export const DEFAULT_ECHARTS_OPTIONS: EChartsOption = {
  title: {
    text: 'SIT chart',
    textStyle: { fontWeight: 500, fontFamily: 'Roboto', fontSize: 14, color: '#00355C' },
  },
  grid: { bottom: '40', containLabel: true, left: 200, right: 20 },
  legend: { icon: 'roundRect', itemHeight: 2, left: 'left', orient: 'vertical', top: 50 },
  tooltip: {
    trigger: 'axis'
  },
  xAxis: { type: 'category' },
  yAxis: {
  },
};

export function computeTotalLookupByNames(
  combinedDemandsChartData: ISitDataResponse,
  scenarios: { id: string, name: string }[],
) {
  return Object.fromEntries(
    scenarios.map((s) => {
      const data = takeRowDataById(s.id, combinedDemandsChartData.rows) || [];

      const result = [s.name, new Map([
        [' SIT', sum(data.map(i => i?.stocksInTrade || 0))],
        [' CLS', sum(data.map(i => i?.closingStocks || 0))],
        [' S.In', sum(data.map(i => i?.salesIn || 0))],
        [' S.Out', sum(data.map(i => i?.distributorSalesOut || 0))]
      ])];

      return result
    })
  );
}
export function calculateDataArea(rawData: { rows: Array<any>; columns: string[] }) {
  enum D {
    actual = 'actual',
    current = 'current',
    future = 'future',
  }
  // Area of Future is determinded by forecast base's data
  const data: Record<D, any[]> = {
    actual: rawData.rows.filter((r) => r[0] === 'actual').flatMap((r) => r[1]),
    current: rawData.rows.filter((r) => r[0] === 'current').flatMap((r) => r[1]),
    // Get the Forecast Base data, or if not found take the last row, after excluxing all special rows
    future: rawData.rows
      .filter((r) => r[0] !== 'actual')
      .filter((r) => r[0] !== 'current')
      .filter((r) => r[0] !== 'committed')
      .filter((r, i) => r[0] === 'forecast' || i === rawData.rows.length - 1)
      .flatMap((r) => r[1]),
  };
  const dataIndies: Record<D, number[]> = {
    actual: findIndexRangeOfAvailableData(data.actual),
    current: findIndexRangeOfAvailableData(data.current),
    future: findIndexRangeOfAvailableData(data.future),
  };
  const colValues: Record<D, string[]> = {
    actual: dataIndies.actual.map((i) => rawData.columns[i]),
    current: dataIndies.current.map((i) => rawData.columns[i]),
    future: dataIndies.future.map((i) => rawData.columns[i]),
  };
  const labelColors: Record<D, string> = {
    actual: '#979797',
    current: '#AD3781',
    future: '#222B45',
  };
  const bgColors: Record<D, string> = {
    actual: '#E3E3E340',
    current: '#7C596F40',
    future: 'transparent',
  };

  const result: Array<[string, LineSeriesOption['markArea']]> = Object.values(D).map((val) => [
    val,
    dataIndies[val].length
      ? {
        silent: true,
        data: [
          [
            {
              name: val.toUpperCase(),
              label: { color: labelColors[val] },
              itemStyle: { color: bgColors[val] },
              xAxis: colValues[val][0],
            },
            { xAxis: colValues[val][1] },
          ],
        ],
      }
      : undefined,
  ]);

  return Object.fromEntries(result);
}

export function formatFiscalYear(date: Date, fiscalYearStartMonth: number): string {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;  // getMonth() is zero-based

  let fiscalYearStart: number;
  let fiscalYearEnd: number;

  if (month < fiscalYearStartMonth) {
      // Fiscal year starts in the previous year
      fiscalYearStart = year - 1;
      fiscalYearEnd = year;
  } else {
      // Fiscal year starts in the current year
      fiscalYearStart = year;
      fiscalYearEnd = year + 1;
  }

  if (fiscalYearStartMonth === 1) {
      // If fiscal year starts in January, use only one year
      return `FY${fiscalYearStart.toString().slice(-2)}`;
  } else {
      // Otherwise, use both years
      return `FY${fiscalYearStart.toString().slice(-2)}/${fiscalYearEnd.toString().slice(-2)}`;
  }
}

export function formatByDateAggregationGen(dao: DateAggregationOption, fiscalYearStartMonth: number = 1) {
  return (s?: string) => {
    if (!s) return '';

    switch (dao) {
      case DateAggregationOption.DAY:
        return format(parseISO(s), 'dd MMM yy');
      case DateAggregationOption.WEEK:
        return format(new Date(s), "'W'ww MMM yyyy", { weekStartsOn: 0, firstWeekContainsDate: 6 });
      case DateAggregationOption.MONTH:
        return format(parseISO(s), 'MMM yy');
      case DateAggregationOption.FISCAL_YEAR:
        return formatFiscalYear(parseISO(s), fiscalYearStartMonth);
      default:
        return '';
    }
  };
}
// generateShorterKpiName(kpiName: string): string {
//   if (kpiName.concat('\n')) {
//     kpiName = kpiName.replace(/\n/g, ' ');
//   }
//   return kpiName.match(/.{1,20}/g)?.[0] + '...';
// }
export function generateShortedScenarioLegend(scenarioName: string): string {
  if (scenarioName.concat('\n')) {
    scenarioName = scenarioName.replace(/\n/g, ' ');
  }
  return scenarioName.match(/.{1,20}/g)?.[0] + '...';
}

export function takeRowDataById<T extends ISitAggregateResult | null>(
  dataId: string,
  rows: Array<[id: string, data: Array<T>]>
) {
  return rows
    .filter(([id]) => id === dataId)
    .map(([_, data]) => data)
    .pop();
}


/** Given a list of entity matcher properties, return their equivalent column names. */
export function propsToColumns(props: readonly string[]): string[] {
  return props.map((prop) => PROP_TO_COLUMN_LOOKUP[prop]);
}

function findIndexRangeOfAvailableData<T>(array: Array<T | null | undefined>) {
  let first: number | null = null;
  let last: number | null = null;

  for (let index = 0; index < array.length; index++) {
    const item = array[index];
    if (item === null || item === undefined) continue;
    if (first === null) first = index;
    last = index;
  }

  if (first !== null && last !== null) return [first, last];
  return [];
}

export function takeTreeNodeChildrenById(id: string, treeNodes: ISitTreeNode[]) {
  return treeNodes
    .filter(({ key }) => key === id)
    .map(({ children }) => children)
    .filter(notNullOrUndefined)
    .pop();
}

export function updateOrAddRow(rows, key, newRow) {
  // Find the index of the row with the specified key.
  const existingRowIndex = rows.findIndex(row => row[0] === key);

  if (existingRowIndex !== -1) {
    // Replace the existing row if it's found.
    rows[existingRowIndex][0] = newRow[0][0];
  } else {
    // Add the new row if no existing row was found.
    rows.push(newRow[0]);
  }

  return rows;
}

export function removeRow(rows, key) {
  const updatedRows = rows.filter(row => row[0] !== key);

  return updatedRows;
}

export function updateOrAddTreeElement(tree, key, newElement) {
  const elementExists = tree.some(el => el.key === key);

  if (elementExists) {
    // Map to replace the element with a new object.
    return tree.map(el => el.key === key ? newElement : el);
  } else {
    // Concat to add the new element.
    return tree.concat(newElement);
  }
}

export function removeTreeElement(tree, key) {
  return tree.filter(el => el.key !== key);
}

export function findDateIndexKYearsAhead(baseDates: string[], dateToFind: string, k: number): number {
  const dateToCompare = format(addYears(parseISO(dateToFind), k), 'yyyy-MM-dd');
  return baseDates.findIndex(date => date === dateToCompare);
}

export function transformDRPRows(
  drpActualXRows: [string, (ISitAggregateResult | null)[]][],
  drpActualXColumns: [string, (string | null)[]][],
  drpColumns: string[]
): [string, (ISitAggregateResult | null)[]][] {
  return drpActualXRows.map(([label, values]) => {
    // Find the correct column array using label and get the first date
    const actualXColumn = drpActualXColumns.find(([id]) => id === label);
    const firstDate = actualXColumn ? actualXColumn[1][0] : null;

    if (!firstDate) {
      console.error(`No column found for '${label}' or first date is null`);
      return [label, values]; // Return the original row if there's no date to find
    }

    // Extract offset from label
    const offset = parseInt(label.split('-')[1], 10);

    // Find the index
    const index = findDateIndexKYearsAhead(drpColumns, firstDate, offset);

    // Initialize a new array with null values
    const newValues = Array(drpColumns.length).fill(null);

    // Place the existing values at the right position
    for (let i = 0; i < drpColumns.length; i++) {
      if (i >= index && i - index < values.length) {
        newValues[i] = values[i - index];
      }
    }

    // Return the transformed row
    return [label, newValues];
  });
}

export function transformDRPTreeData(
  drpActualXTrees: ISitTreeNode[],
  drpActualXColumns: [string, (string | null)[]][],
  drpColumns: string[]
): ISitTreeNode[] {

  function transformTree(
    tree: ISitTreeNode,
    firstDate: string | null = null,
    index?: number
  ): ISitTreeNode {
    let currentFirstDate = firstDate;
    let currentIndex = index || 0;

    // If firstDate is not passed in, it means it is the parent, 
    // so find the correct column array using key and get the first date
    if (!currentFirstDate) {
      const actualXColumn = drpActualXColumns.find(([id]) => id === tree.key);
      currentFirstDate = actualXColumn ? actualXColumn[1][0] : null;

      if (!currentFirstDate) {
        console.error(`No column found for '${tree.key}' or first date is null`);
        return { ...tree }; // Return a deep copy of the original tree if there's no date to find
      }

      // Extract offset from key for the parent as it is not passed as a parameter
      const offset = parseInt(tree.key.split('-')[1], 10);

      // Calculate the index
      currentIndex = findDateIndexKYearsAhead(drpColumns, currentFirstDate, offset);
    }

    // Initialize a new array with null values
    const newData = new Array(drpColumns.length).fill(null);

    // Place the existing values at the right position
    for (let i = 0; i < drpColumns.length; i++) {
      if (i >= currentIndex && i - currentIndex < tree.data.length) {
        newData[i] = tree.data[i - currentIndex];
      }
    }

    // Create a copy of the tree with transformed data
    const newTree = { ...tree, data: newData };

    // If tree has children, recursively transform them with the parent's firstDate and index
    if (tree.children) {
      newTree.children = tree.children.map(child =>
        transformTree({ ...child }, currentFirstDate, currentIndex)
      );
    }

    // Return the transformed tree
    return newTree;
  }

  // Transform the trees
  return drpActualXTrees.map(tree => transformTree(tree));
}