import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IEtlTaskState, ETL_STATE_KEY } from './etl.state';
// import { select_actualPlan } from '../plan/plan.selectors';

const selectFeature = createFeatureSelector<IEtlTaskState>(ETL_STATE_KEY);

export const select_runningEtlTasks = createSelector(selectFeature, (s) => s.runningEtlTasks);

// export const select_hasRunningEtlTasks = createSelector(
//   select_actualPlan,
//   select_runningEtlTasks,
//   (actualPlan, runningEtlTasks) => {
//     if (runningEtlTasks.length > 0) {
//       return true;
//     }

//     return false;
//   }
// )
