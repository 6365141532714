import { createReducer, on } from '@ngrx/store';

import { DataZoomEvent } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.component';
import { DateAggregationOption } from '@/app/pages/explorer/planning-explorer/widgets/timeseries/timeseries.constants';
import { IGetDRPDataParams, IDRPLoadedParams, ISitDataResponse } from '@/app/@core/entity/drp.service';
import { TreeList } from '@/utils/treelist';

import {
  DEFAULT_SIT_CHART_RESPONSE,
  LOAD_SIT_DATA,
  LOAD_SIT_DATA_SUCCESS,
  LOAD_SIT_DATA_FAILED,
  LOAD_SIT_ACTUAL_X_DATA,
  LOAD_SIT_ACTUAL_X_DATA_SUCCESS,
  LOAD_SIT_ACTUAL_X_DATA_FAILED,
  UPDATE_DRP_VALUE,
  UPDATE_DRP_VALUE_SUCCESS,
  UPDATE_DRP_VALUE_FAILED,
  UPDATE_SIT_UOM,
  UPDATE_SIT_GROUPING,
  UPDATE_SIT_DATE_AGGREGATION,
  UPDATE_SIT_CONSTRAINED,
  VALIDATE_DRP,
  VALIDATE_DRP_SUCCESS,
  VALIDATE_DRP_FAILED,
  TRIGGER_SALES_IN_SIMULATION,
  TRIGGER_SALES_IN_SIMULATION_SUCCESS,
  TRIGGER_SALES_IN_SIMULATION_FAILED,
  CANCEL_SALES_IN_SIMULATION,
  UPDATE_SIT_LOADED_PARAMS
} from './sit.actions';
import { 
  updateOrAddRow,
  updateOrAddTreeElement,
  transformDRPRows,
  transformDRPTreeData 
} from './sit.utils';

export const PAGE__SIT_STATE_KEY = 'page_sit';

/*************************************
 * State
 *************************************/

export interface ISitPageState {
  datesSubfiltering?: DataZoomEvent;
  groupings: ReadonlyArray<string>;
  dateAggregation: DateAggregationOption;
  sit: ISitDataResponse;
  sitLoadedParams?: IDRPLoadedParams;
  sitLoadedScenarios?: string[];
  editedSITScenarios: string[];
  editingScenario?: string;
  selectedScenarioForSimulation: string;
  validatingScenario: string;
  simulatingScenario: string;
  sitLoading: boolean;
  sitValidating: boolean;
  sitTrigerringSimulation: boolean;
  drpActualX: ISitDataResponse;
  drpActualXLoadingCount: number;
  drpActualX_error?: any;
  fetchingSitDataError?: any;
  validationSitDataError?: any;
  triggerSimulationError?: any
  impacts: ISitDataResponse;
  uom?: string;
  useConstrained?: boolean;
  mapOfExpandedData: Record<string, TreeList.ITreeNode[]>;
}

export const initialState: ISitPageState = {
  dateAggregation: DateAggregationOption.MONTH,
  groupings: ['customerName', 'productName'],
  sit: DEFAULT_SIT_CHART_RESPONSE,
  sitLoadedParams: undefined,
  editedSITScenarios: [],
  selectedScenarioForSimulation: '',
  validatingScenario: '',
  simulatingScenario: '',
  sitLoading: false,
  sitValidating: false,
  sitTrigerringSimulation: false,
  drpActualX: DEFAULT_SIT_CHART_RESPONSE,
  drpActualXLoadingCount: 0,
  impacts: DEFAULT_SIT_CHART_RESPONSE,
  // Set default value for uom
  uom: '',
  useConstrained: false,
  mapOfExpandedData: {}
};

/*************************************
 * Reducers
 *************************************/

export const page_sitReducer = createReducer(
  initialState,
  on(
    UPDATE_SIT_DATE_AGGREGATION,
    (state, { dateAggregation }): ISitPageState => ({
      ...state,
      dateAggregation,
    }),
  ),
  on(
    UPDATE_SIT_UOM,
    (state, { uom }): ISitPageState => ({
      ...state,
      uom
    }),
  ),
  on(
    UPDATE_SIT_CONSTRAINED,
    (state, { useConstrained }): ISitPageState => ({
      ...state,
      useConstrained
    }),
  ),
  on(
    UPDATE_SIT_GROUPING,
    (state, { data }): ISitPageState => ({ ...state, groupings: data }),
  ),
  on(
    LOAD_SIT_DATA,
    (state): ISitPageState => ({
      ...state,
      sitLoading: true,
      drpActualXLoadingCount: state.drpActualXLoadingCount + 1,
      fetchingSitDataError: undefined
    })
  ),
  on(
    LOAD_SIT_DATA_SUCCESS,
    (state, { data }): ISitPageState => {
      const rowsData = data.rows

      let scenarioDoesHasNotSalesInGenerated: string[] = [];

      rowsData.forEach(([key, objectsArray]) => {
        const hasSalesInSimulationData = objectsArray[0]?.hasSalesInSimulationData

        if (hasSalesInSimulationData == false) scenarioDoesHasNotSalesInGenerated.push(key)
      });

      return {
        ...state,
        sit: data,
        sitLoading: false,
        editedSITScenarios: scenarioDoesHasNotSalesInGenerated && scenarioDoesHasNotSalesInGenerated.length > 0
          ? [...state.editedSITScenarios, ...scenarioDoesHasNotSalesInGenerated]
          : [...state.editedSITScenarios],
        fetchingSitDataError: undefined
      }
    }
  ),
  on(
    LOAD_SIT_DATA_FAILED,
    (state, { error }): ISitPageState => ({
      ...state,
      sit: DEFAULT_SIT_CHART_RESPONSE,
      sitLoading: false,
      fetchingSitDataError: error
    })
  ),
  on(
    LOAD_SIT_ACTUAL_X_DATA,
    (state): ISitPageState => ({
      ...state,
      drpActualXLoadingCount: state.drpActualXLoadingCount + 1,
      drpActualX_error: undefined
    })
  ),
  on(
    LOAD_SIT_ACTUAL_X_DATA_SUCCESS,
    (state, { data }): ISitPageState => {
      // The objective of this update is to modify or introduce new rows, columns, or trees, utilizing keys such as 'actual-1', 'actual-2', etc., as the basis for the alterations.
      const columns = updateOrAddRow([...state.drpActualX.columns], data.columns[0][0], data.columns);
      const rows = updateOrAddRow([...state.drpActualX.rows], data.rows[0][0], data.rows);
      const tree = updateOrAddTreeElement([...state.drpActualX.tree], data.tree[0].key, data.tree[0]);

      // The goal of this transformation is to accurately offset the row data, aligning it with the appropriate indices correlating to the columns in raw_demands.
      const transformedActualXRows = transformDRPRows(rows, columns, state.sit.columns);
      const transformedActualXTree = transformDRPTreeData(tree, columns, state.sit.columns);

      return ({
        ...state,
        drpActualX: {
          columns,
          rows: transformedActualXRows,
          tree: transformedActualXTree
        },
        drpActualXLoadingCount: Math.max(0, state.drpActualXLoadingCount - 1), // Decrement counter safely
        drpActualX_error: undefined
      })
    }
  ),
  on(
    LOAD_SIT_ACTUAL_X_DATA_FAILED,
    (state, { error }): ISitPageState => ({
      ...state,
      drpActualX: DEFAULT_SIT_CHART_RESPONSE,
      drpActualXLoadingCount: Math.max(0, state.drpActualXLoadingCount - 1), // Decrement counter safely
      drpActualX_error: error
    })
  ),
  on(
    UPDATE_DRP_VALUE,
    (state, { params }): ISitPageState => {
      const scenarioId = params.rowKey
      const editedSITScenarios = state.editedSITScenarios

      return {
        ...state,
        editingScenario: scenarioId,
        editedSITScenarios: scenarioId && !editedSITScenarios.includes(scenarioId) ? [...state.editedSITScenarios, scenarioId] : state.editedSITScenarios,
        fetchingSitDataError: undefined
      }
    }
  ),
  on(
    UPDATE_DRP_VALUE_SUCCESS,
    (state, { data }): ISitPageState => ({
      ...state,
      sit: data,
      editingScenario: undefined,
      fetchingSitDataError: undefined
    }),
  ),
  on(
    UPDATE_DRP_VALUE_FAILED,
    (state, { error }): ISitPageState => ({
      ...state,
      sit: DEFAULT_SIT_CHART_RESPONSE,
      editingScenario: undefined,
      fetchingSitDataError: error
    }),
  ),
  on(
    VALIDATE_DRP,
    (state, { scenarioId }): ISitPageState => ({
      ...state,
      // sitValidating: true,
      validatingScenario: scenarioId,
      selectedScenarioForSimulation: scenarioId,
      triggerSimulationError: undefined,
      validationSitDataError: undefined
    })
  ),
  on(
    VALIDATE_DRP_SUCCESS,
    (state, { scenarioId }): ISitPageState => ({
      ...state,
      // sitValidating: false,
      editedSITScenarios: state.editedSITScenarios.filter(s => s != scenarioId),
      validatingScenario: '',
      validationSitDataError: undefined
    }),
  ),
  on(
    VALIDATE_DRP_FAILED,
    (state, { error }): ISitPageState => ({
      ...state,
      // sitValidating: false,
      validatingScenario: '',
      validationSitDataError: error
    })
  ),
  on(
    TRIGGER_SALES_IN_SIMULATION,
    (state): ISitPageState => ({
      ...state,
      // sitTrigerringSimulation: true,
      simulatingScenario: state.selectedScenarioForSimulation,
      triggerSimulationError: undefined,
      validationSitDataError: undefined
    }),
  ),
  on(
    TRIGGER_SALES_IN_SIMULATION_SUCCESS,
    (state, { scenarioId }): ISitPageState => ({
      ...state,
      // sitTrigerringSimulation: false,
      editedSITScenarios: state.editedSITScenarios.filter(s => s != scenarioId),
      simulatingScenario: '',
      selectedScenarioForSimulation: '',
      triggerSimulationError: undefined
    })
  ),
  on(
    TRIGGER_SALES_IN_SIMULATION_FAILED,
    (state, { error }): ISitPageState => ({
      ...state,
      // sitTrigerringSimulation: false,
      simulatingScenario: '',
      selectedScenarioForSimulation: '',
      triggerSimulationError: error
    })
  ),
  on(
    CANCEL_SALES_IN_SIMULATION,
    (state): ISitPageState => ({
      ...state,
      validatingScenario: '',
      simulatingScenario: '',
      selectedScenarioForSimulation: '',
      validationSitDataError: undefined,
      triggerSimulationError: undefined
    })
  ),
  on(
    UPDATE_SIT_LOADED_PARAMS,
    (state, { params }): ISitPageState => ({
        ...state,
        sitLoadedParams: params
      }
    )
  ),
);
