<div class="popup-container">
  <div>
    <nb-form-field>
      <nb-icon nbPrefix icon="search" pack="eva"></nb-icon>
      <input class="search-filter-section" nbInput fullWidth autofocus fieldSize="small"
        placeholder="Search Saved Segment" [(ngModel)]="searchSegment" />
    </nb-form-field>
  </div>

  <div class="segment-select-div">
    <div class="segment-radio-div">
      <div class="option__item">
        <input type="radio" [value]="null" [checked]="this.choseSegment == null"
          (change)="changeOption($event.target.value)" /><label for="">None</label>
      </div>

      <div *ngFor="let segment of segments | async | filteringByName: searchSegment" class="option__item option__segment">
        <div class="option__align">
          <input type="radio" [value]="segment.id"
            [checked]="this.choseSegment == segment.id" (change)="changeOption($event.target.value)" />
          <label for="">{{segment.name}}</label>
        </div>
        <button nbButton ghost size="small" shape="round" [nbPopover]="eventVersionContextMenu"
          nbPopoverPlacement="bottom" style="margin-right: 10px;">
          <nb-icon icon="more-vertical-outline"></nb-icon>
          <ng-template #eventVersionContextMenu>
            <nb-list>

              <nb-list-item class="scenario-btn -list-item" nbButton ghost
                (click)="openEditSegment(editNameSegment,segment)">
                <nb-icon class="scenario-btn -edit" icon="edit" pack="simcel-event-icons"></nb-icon>
                <label class="scenario-btn -edit -label">Rename</label>
              </nb-list-item>

              <nb-list-item class="scenario-btn -list-item" nbButton ghost
                (click)="openConfirmDelete(confirmDeleteSegment,segment)">
                <nb-icon class="scenario-btn -edit" icon="copy-outline"></nb-icon>
                <label class="scenario-btn -edit -label">Delete</label>
              </nb-list-item>

            </nb-list>
          </ng-template>
        </button>
      </div>
    </div>
    <div class="align-items-end dialog-btn -div mt-3 ">
      <button type="button" class="dialog-btn -save" (click)="selectSumbit()" >
        Select
      </button>
    </div>
  </div>
  
</div>




<ng-template #confirmDeleteSegment let-segment let-ref="dialogRef">
  <nb-card>
    <nb-card-header>Confirm to delete segment {{ segment.name }}</nb-card-header>
    <nb-card-footer>
      <button nbButton status="primary" (click)="ref.close()">Cancel</button>
      <button nbButton class="float-right" status="danger" (click)="ref.close(); deleteSegment(segment)">
        Delete
      </button>
    </nb-card-footer>
  </nb-card>
</ng-template>




<ng-template #editNameSegment let-segment let-ref="dialogRef">
  <nb-card class="dialog-container">
    <nb-card-header class="dialog-header row">
      <label class="dialog-label">Rename Segment</label>
      <button class="dialog-btn -close">
        <nb-icon class="dialog-btn -close-icon" icon="close-outline" pack="eva" fullWidth></nb-icon>
      </button>
    </nb-card-header>
    <nb-card-body class="dialog-body">
      <div class="dialog-text">Please add the name for a New Segment</div>
      <label class="dialog-input-label">Segment Name</label>
      <div>
        <input type="text" autofocus class="dialog-input" placeholder="Input Text" [value]="this.segmentSelect?.name"
          (change)="changeRenameSegment($event.target.value,segment)" />
      </div>
    </nb-card-body>
    <nb-card-footer class="dialog-footer">
      <div class="row align-items-end dialog-btn -div">
        <button type="button" class="dialog-btn -cancel" (click)="ref.close()">Cancel</button>
        <button type="button" class="dialog-btn -save" (click)="ref.close(); editSegment()">
          Save
        </button>
      </div>
    </nb-card-footer>
  </nb-card>

</ng-template>