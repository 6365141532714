export const truncate = (text: string, limit: number, suffix?: string) => {
  let result = text;
  if (result.length > limit) {
    result = result.substring(0, limit);
  }
  if (suffix) {
    result += suffix;
  }
  return result;
}
