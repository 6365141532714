import { Notification } from '@/app/@core/interfaces/business/notification';
import { CHECK_ALL_NOTIFICATIONS_SUCCESS } from '@/store/notification/notification.actions';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'cel-notification-box',
  styleUrls: ['./notifcation-box.component.scss'],
  templateUrl: './notifcation-box.component.html',
})
export class NotificationBoxComponent {
  @Input() items: readonly Notification[] = [];

  constructor(private readonly store: Store) {}

  get text (): string {
    return String(this.items?.filter((i) => !i.checked).length || '');
  }

  handleToggle() {
    setTimeout(() => {
      this.store.dispatch(CHECK_ALL_NOTIFICATIONS_SUCCESS());
    }, 1000);
  }
}
