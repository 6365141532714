import { Injectable } from '@angular/core';
import { HttpService } from '../common/api/http.service';

export interface EtlTask {
  id: string;
  workspace_id: string;
  status: string;
  logs?: string[];
}

/** Simplified service for triggering and monitoring end-to-end runs. */
@Injectable()
export class EtlService {
  private readonly apiController: string = 'etl';

  constructor(private readonly http: HttpService) { }

  fetchRunningEtlTasks() {
    return this.http.get(`${this.apiController}/running-etl-tasks`);
  }
}
