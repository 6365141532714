import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { HttpService } from '../backend/common/api/http.service';
import { PlanSetting } from '../interfaces/business/plan-setting';
import { Observable, of, retry } from 'rxjs';
import { SimpleDateRange } from '../interfaces/common/date-range';

@Injectable({ providedIn: 'root' })
export class PlanSettingService {
  private readonly apiController: string = 'explorer/plan-setting';
  constructor(private readonly api: HttpService) { }

  getSetting(planRef: string): Observable<PlanSetting> {
    return this.api.get(`${this.apiController}/get-setting`, { params: { planRef: planRef } }).pipe(retry(5));
  }

  addDisplayedScenario(planRef: string, scenarioId: string): Observable<PlanSetting> {
    return this.api.post(`${this.apiController}/add-scenario`, { planRef: planRef, scenarioId: scenarioId });
  }

  hideDisplayedScenario(planRef: string, scenarioId: string): Observable<PlanSetting> {
    return this.api.post(`${this.apiController}/hide-scenario`, { planRef: planRef, scenarioId: scenarioId });
  }

  deleteDisplayedScenario(planRef: string, scenarioId: string): Observable<PlanSetting> {
    return this.api.post(`${this.apiController}/delete-scenario`, { planRef: planRef, scenarioId: scenarioId });
  }

  changeDisplayedScenario(planRef: string, scenarios: string[]): Observable<PlanSetting> {
    return this.api.post(`${this.apiController}/change-scenario`, { planRef: planRef, scenarios: scenarios });
  }

  changeDisplayedDateRange(planRef: string, dateRange: SimpleDateRange): Observable<PlanSetting> {
    return this.api.post(`${this.apiController}/change-date-range`, { planRef: planRef, dateRange: { start: dateRange.start, end: dateRange.end } });
  }

  changeUsingY2Y(planRef: string, usingY2Y: boolean): Observable<PlanSetting> {
    return this.api.post(`${this.apiController}/change-using-y2y`, { planRef: planRef, usingY2Y });
  }

  changeCompareMode(planRef: string, compareMode: string): Observable<PlanSetting> {
    return this.api.post(`${this.apiController}/change-compare-mode`, { planRef: planRef, compareMode });
  }

  changeSelectedKpis(planRef: string, selectedKpis: any[]): Observable<PlanSetting> {
    return this.api.post(`${this.apiController}/change-selected-kpis`, { planRef: planRef, selectedKpis });
  }
}
