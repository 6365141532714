import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  NbDialogRef,
  NbToastrService,
  NbGlobalLogicalPosition,
  NbDialogService,
} from '@nebular/theme';
import { FormControl } from '@angular/forms';
import { filter, map, pairwise, Subject, takeUntil, tap, withLatestFrom, combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { PureAbility } from '@casl/ability';
import { addYears } from 'date-fns';

import { ForecastSettingsComponent } from '../explorer/planning-explorer/widgets/forecast-settings/forecast-settings.component';
import { Plan } from '../../@core/interfaces/business/plan';
import { PlanImport } from '../../@core/interfaces/business/plan-import';
import { SimpleDateRange } from '../../@core/interfaces/common/date-range';
import { convertToSimpleDateRangeFromRangepickerVal } from '../../../utils/calendar';
import { DeletePlanWarningDialog } from '@/app/@components/common/delete-plan-warning-dialog/delete-plan-warning-dialog.component';
import { select_availablePlanImports } from '@/store/plan-import/plan-import.selectors';
import { select_latestBudgetPlan, select_latestFuturePlan } from '@/store/plan/plan.selectors';
import { LOAD_PLAN_IMPORTS } from '@/store/plan-import/plan-import.actions';
import { BEGIN_CREATE_NEW_PLAN, BEGIN_UPDATE_PLAN, BEGIN_DELETE_PLAN } from '@/store/pages/layout/layout.actions';
import {
  select_creatingPlan,
  select_creatingPlanError,
  select_updatingPlan,
  select_updatingPlanError
} from '@/store/pages/layout/layout.baseSelectors';
import { select_selectedWorkspace, select_isNewBaseDemandAvailable } from '@/store/workspace/workspace.selectors';
import { Workspace } from '@/app/@core/interfaces/common/workspace';
import { WorkspaceService } from '@/app/@core/utils';
import { select_actualScenario, select_latestPrimaryScenario, select_primaryScenario, select_scenarios } from '@/store/scenario/scenario.selectors';
import { Scenario, ScenarioFlag } from '@/app/@core/interfaces/business/scenario';
import { OPEN_FORECAST_SETTINGS_DIALOG } from '@/store/pages/demand-planning/demand-planning.actions';
import { FORECASTING_METHOD_LABELS, ForecastingMethod } from '@/app/@core/utils/workspace.service';
import { select_latestActual } from '@/store/actual/actual.selectors';

// const now = new Date();
// const startOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);

@Component({
  selector: 'cel-plan-management',
  templateUrl: './plan-management.component.html',
  styleUrls: ['./plan-management.component.scss'],
})
export class PlanManagementComponent implements OnInit, OnDestroy {
  form_rangeSelected = new FormControl([]);

  selectedPlanImport?: PlanImport;
  selectedUpdatePlanImport?: PlanImport;
  selectedLatestPlan?: Plan;
  planTitle = '';
  planDescription = '';
  planDateRange?: SimpleDateRange;
  preDate: Date = new Date();
  now: Date[] = [new Date(), new Date()];
  updateMode = false;
  selectExistingPlan = 10;
  forecastingMethod = ForecastingMethod.DT;
  useForecasting = true;
  budgetPlan = false;
  createdPlan?: Plan;
  primaryScenario?: Scenario;
  latestPrimaryScenario?: Scenario;
  actualScenario?: Scenario;
  workspace?: Workspace;
  isNewBaseDemandAvailable = false;
  disableFeature = false;
  actualEndDate?: Date;
  currentForecastingMethod: ForecastingMethod = ForecastingMethod.DT;

  latestFuturePlan = this.store.select(select_latestFuturePlan);
  latestBudgetPlan = this.store.select(select_latestBudgetPlan);
  planImports = this.store.select(select_availablePlanImports);
  creatingPlan = this.store.select(select_creatingPlan);
  creatingPlan_error = this.store.select(select_creatingPlanError);

  updatingPlan = this.store.select(select_updatingPlan);
  updatingPlan_error = this.store.select(select_updatingPlanError);

  latestActual = this.store.select(select_latestActual)

  workspace$ = this.store.select(select_selectedWorkspace);

  allScenarios$ = this.store.select(select_scenarios);
  allScenarios: Scenario[] = [];

  presettedDateRanges = {
    'One year plan': [this.preDate, addYears(this.preDate, 1)],
    'Two year plan': [this.preDate, addYears(this.preDate, 2)],
    'Three year plan': [this.preDate, addYears(this.preDate, 3)],
    'Five year plan': [this.preDate, addYears(this.preDate, 5)],
  };
  displayMonthYearOnly: boolean = false;
  dateRangeSelectionText = "Date";

  

  /** Unsubscribe helper. */
  private readonly unsubscribe = new Subject<void>();

  constructor(
    private readonly dialogService: NbDialogService,
    private readonly store: Store,
    private readonly dialogRef: NbDialogRef<Plan>,
    private readonly toastrService: NbToastrService,
    private readonly workspaceService: WorkspaceService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(LOAD_PLAN_IMPORTS());
    this.bindFormControlToProp();

    combineLatest([
      this.store.select(select_selectedWorkspace),
      this.store.select(select_isNewBaseDemandAvailable),
    ]).subscribe(([workspace, isNewBaseDemandAvailable]) => {
      this.displayMonthYearOnly = this.workspaceService.checkIsDisplayMonthYearOnly(workspace);
      if (this.displayMonthYearOnly) {
        this.dateRangeSelectionText = "Month";
      } else {
        this.dateRangeSelectionText = "Date";
      }

      if (workspace?.settings?.triggerSimulation === false) {
        this.disableFeature = true;
      }
      this.isNewBaseDemandAvailable = isNewBaseDemandAvailable;
      this.workspace = workspace;
    });

    this.creatingPlan
      .pipe(
        pairwise(),
        withLatestFrom(this.creatingPlan_error),
        tap(([[previousVal, currentVal], error]) => {
          // Close the dialog if its status changed from 'true' to 'false' and having no error
          if (previousVal === true && currentVal === false && !error) this.close();
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();

    this.updatingPlan
      .pipe(
        pairwise(),
        withLatestFrom(this.updatingPlan_error),
        tap(([[previousVal, currentVal], error]) => {
          // Close the dialog if its status changed from 'true' to 'false' and having no error
          if (previousVal === true && currentVal === false && !error) this.close();
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe();

    if (!this.budgetPlan) {
      this.latestFuturePlan.subscribe(plan => {
        this.selectedLatestPlan = plan
      });
    } else {
      this.latestBudgetPlan.subscribe(plan => {
        this.selectedLatestPlan = plan
      });
    }

    this.latestActual.subscribe(actual => {
      this.actualEndDate = actual.actualEndDate
    })

    this.store.select(select_primaryScenario).subscribe((primaryScenario) => {
      this.primaryScenario = primaryScenario;
    })

    this.store.select(select_latestPrimaryScenario).subscribe((latestPrimaryScenario) => {
      this.latestPrimaryScenario = latestPrimaryScenario
    })

    this.store.select(select_actualScenario).subscribe((actualScenario) => {
      this.actualScenario = actualScenario;
    })

    this.allScenarios$.subscribe((allScenarios) => {
      this.allScenarios = allScenarios;
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private bindFormControlToProp() {
    this.form_rangeSelected.valueChanges.subscribe(result => {
      this.onChangePresettedDateRanges(result[0])
    });
    this.form_rangeSelected.valueChanges
      .pipe(
        map(range => {
          if (this.dateRangeSelectionText === "Month") {
            this.workspaceService.resetRange(range);
          }
          return range;
        }),
        map(convertToSimpleDateRangeFromRangepickerVal),
        filter((data) => !!data.start && !!data.end),
        tap((data) => (this.planDateRange = data)),
        takeUntil(this.unsubscribe),
      )
      .subscribe();
  }

  onChangePresettedDateRanges(e: Date) {
    this.presettedDateRanges = {
      'One year plan': [e, addYears(e, 1)],
      'Two year plan': [e, addYears(e, 2)],
      'Three year plan': [e, addYears(e, 3)],
      'Five year plan': [e, addYears(e, 5)],
    }
  }

  changeMode(checked: boolean) {
    this.updateMode = checked;

    if (this.selectedLatestPlan && this.updateMode) {
      this.forecastingMethod = this.selectedLatestPlan.forecastMethod
      this.currentForecastingMethod = this.selectedLatestPlan.forecastMethod
    } else {
      this.forecastingMethod = ForecastingMethod.DT
      this.currentForecastingMethod = ForecastingMethod.DT
    }
  }

  changeDate(e) {
    this.onChangePresettedDateRanges(e[0])
  }

  changeCreateMode(checked: boolean) {
    this.useForecasting = checked;

    if (checked) {
      this.selectedPlanImport = undefined;
    }
  }

  changePlanType(checked: boolean) {
    this.budgetPlan = checked;

    if (!this.budgetPlan) {
      this.latestFuturePlan.subscribe(plan => {
        this.selectedLatestPlan = plan
      });
    } else {
      this.latestBudgetPlan.subscribe(plan => {
        this.selectedLatestPlan = plan
      });
    }

    if (checked) {
      this.selectedPlanImport = undefined;
    }
  }

  changeUpdateMode(checked: boolean) {
    this.useForecasting = checked;

    if (checked) {
      this.selectedUpdatePlanImport = undefined;
    }
  }

  create() {
    this.dialogRef.close()

    let isForecastCollectionAvailable = true;
    if (this.workspace && !this.workspace.forecastCollection[this.forecastingMethod]) {
      isForecastCollectionAvailable = false;
    }
    
    this.store.dispatch(
      BEGIN_CREATE_NEW_PLAN({
        params: {
          planImport: this.selectedPlanImport,
          title: this.planTitle,
          description: this.planDescription,
          useForecasting: this.useForecasting,
          futureStartDate: this.planDateRange?.start || '',
          futureEndDate: this.planDateRange?.end || '',
          forecastingMethod: this.forecastingMethod,
          isBudgetPlan: this.budgetPlan,
          workspaceId: this.workspace?.id || '',
          isNewBaseDemandAvailable: this.isNewBaseDemandAvailable,
          isForecastCollectionAvailable
        },
      }),
    );
  }

  update() {
    this.dialogRef.close()
    const forecastBaseScenario = this.allScenarios.find(s => s.flags?.includes(ScenarioFlag.FORECAST_BASE));

    this.store.dispatch(
      BEGIN_UPDATE_PLAN({
        params: {
          planId: this.selectedLatestPlan?.id || '',
          useForecasting: this.useForecasting,
          futureStartDate: this.selectedLatestPlan?.futurePlanStartDate || '',
          futureEndDate: this.selectedLatestPlan?.futurePlanEndDate || '',
          forecastingMethod: this.forecastingMethod,
          primaryScenarioId: this.latestPrimaryScenario?.id || (forecastBaseScenario?.id || ''),
          workspaceId: this.workspace?.id || '',
          isNewBaseDemandAvailable: this.isNewBaseDemandAvailable,
        }
      })
    );
  }

  showMessage(title: string, content: string, durartion: number, status: string) {
    this.toastrService.show(content, title, {
      position: NbGlobalLogicalPosition.BOTTOM_START,
      duration: durartion,
      destroyByClick: true,
      status: status,
    });
  }

  showWarningDeleteDialog() {
    this.dialogService.open(DeletePlanWarningDialog, {
      closeOnBackdropClick: false,
      context: {
        latestPlanName: this.selectedLatestPlan?.name || '',
        onSubmit: (ref: NbDialogRef<DeletePlanWarningDialog>) => {
          this.store.dispatch(BEGIN_DELETE_PLAN({
            params: { plan: this.selectedLatestPlan }
          }));
          this.dialogRef.close();
          ref.close();
        }
      },
    });
  }

  close() {
    this.dialogRef.close();
  }

  generateCreateBtnClass() {
    return this.planTitle != '' && this.selectedPlanImport != null
      ? 'plan-mgt-btn -save'
      : 'plan-mgt-btn -cancel';
  }

  isCreateButnDisabled(): boolean {
    if (!this.useForecasting) {
      if (!this.selectedPlanImport) {
        return true;
      }
    } else {
      if (!this.planDateRange) {
        return true;
      }
    }

    if (this.planTitle === '') {
      return true;
    }

    return false;
  }

  isUpdateButnDisabled(): boolean {
    if (!this.useForecasting) {
      return true
    }
    if (!this.selectedLatestPlan) {
      return true;
    }

    if (!this.actualEndDate) {
      return true
    }

    // Can not update a plan which have Future Start Date before the Actual End Date
    // if (new Date(this.selectedLatestPlan?.futurePlanStartDate) < new Date(this.actualEndDate)) {
    //   return true
    // }

    return false;
  }

  isUpdatingPlanBeforeActual(): boolean {
    if (!this.selectedLatestPlan) {
      return true;
    }

    if (!this.actualEndDate) {
      return true
    }

    // Can not update a plan which have Future Start Date before the Actual End Date
    // if (new Date(this.selectedLatestPlan?.futurePlanStartDate) < new Date(this.actualEndDate)) {
    //   return true
    // }

    return false;
  }

  openForecastSettingsDialog() {
    this.store.dispatch(OPEN_FORECAST_SETTINGS_DIALOG());
    this.dialogService.open(ForecastSettingsComponent, {
      hasBackdrop: true,
      closeOnEsc: true,
      closeOnBackdropClick: false
    });
  }

  changeForecastingMethod(value: ForecastingMethod) {
    this.forecastingMethod = value
  }

  getForecastMethodLabel(forecastingMethod: string): string {
    return FORECASTING_METHOD_LABELS.find((f) => f.method === forecastingMethod)?.label
  }
}
