import { EChartsOption } from 'echarts';

import { BreakdownKpis } from '@/app/@core/interfaces/business/breakdown';
import { transformInputToKpiFormat } from '@/app/pipes/kpi-formatting.pipe';
import { getTransformedKpiValue } from '../../planning-explorer.utils';
import { Workspace } from '@/app/@core/interfaces/common/workspace';

export const PNL_EVOLUTION_KPIS: BreakdownKpis[] = [
  'FIEDemandValue',
  'GrossFIESales',
  'FIETradeExpenses',
  'NetFIESales',
  'cogs',
  'transferCost',
  'distributionCost',
  'cbm',
];

export const PNL_EVOLUTION_FINANCE_KPIS: BreakdownKpis[] = [
  'FIEDemandValue',
  'GrossFIESales',
  'FIETradeExpenses',
  'NetFIESales',
  'cogs',
  'GrossProfit',
  'Transport',
  'StorageAndHandling',
  'Promotion',
  'MedicalAffairs',
  'Administration',
  'EBIT',
];

export const PNL_EVOLUTION_KPIS_NAME: string[] = [
  'Primary\nDemand\nValue',
  'Primary\nGross\nSales\nValue',
  'Primary\nTrade\nExpenses',
  'Primary\nNet\nSales\nValue',
  'COGS',
  'Stock\nTransfer',
  'Distribution\nCost',
  'Profit\nBefore Marketing',
];

export const PNL_EVOLUTION_FINANCE_KPIS_NAME: string[] = [
  'Primary\nDemand\nValue',
  'Primary\nGross\nSales\nValue',
  'Primary\nTrade\nExpenses',
  'Primary\nNet\nSales\nValue',
  'COGS',
  'Gross\nProfit',
  'Transport',
  'Storage\nand\nHandling',
  'Promotion',
  'Medical\nAffairs',
  'Administration',
  'EBIT',
];

export const PNL_EVOLUTION_KPIS_MAP: Array<{ name: string, label: string }> = [
  { name: 'FIEDemandValue', label: 'Primary Demand Value' },
  { name: 'GrossFIESales', label: 'Primary Gross Sales Value' },
  { name: 'FIETradeExpenses', label: 'Primary Trade Expenses' },
  { name: 'NetFIESales', label: 'Primary Net Sales Value' },
  { name: 'COGS', label: 'COGS' },
  { name: 'TransferCost', label: 'Stock Transfer' },
  { name: 'DistributionCost', label: 'Distribution Cost' },
  { name: 'ProfitBeforeMarketing', label: 'Profit Before Marketing' },
];

export const PNL_EVOLUTION_FINANCE_KPIS_MAP: Array<{ name: string, label: string }> = [
  { name: 'FIEDemandValue', label: 'Primary Demand Value' },
  { name: 'GrossFIESales', label: 'Primary Gross Sales Value' },
  { name: 'FIETradeExpenses', label: 'Primary Trade Expenses' },
  { name: 'NetFIESales', label: 'Primary Net Sales Value' },
  { name: 'COGS', label: 'COGS' },
  { name: 'GrossProfit', label: 'Gross Profit' },
  { name: 'Transport', label: 'Transport' },
  { name: 'StorageAndHandling', label: 'Storage and Handling' },
  { name: 'Promotion', label: 'Promotion' },
  { name: 'MedicalAffairs', label: 'Medical Affairs' },
  { name: 'Administration', label: 'Administration' },
  { name: 'EBIT', label: 'EBIT' },
];

export function filterPnlHiddenKpis(kpis: BreakdownKpis[], hiddenKpis: string[]) {
  // filter PNL_EVOLUTION_KPIS with hiddenKpis
  return kpis.filter(kpi => !hiddenKpis.includes(kpi));
}

export function filterPnlHiddenKpisMap(kpiMap: Array<{ name: string, label: string }>, workspace: Workspace) {
  // filter PNL_EVOLUTION_KPIS_MAP with hiddenKpis
  const hiddenKpis = workspace?.settings?.hiddenKpis || [];
  return kpiMap.filter(kpi => !hiddenKpis.includes(kpi.name));
}

// Build breakdown waterfall chart options. For reference:
// https://echarts.apache.org/en/option.html#title
export const BREAKDOWN_OPTIONS_BASE: ((workspace?: Workspace, decimalPlaces?: number, numericScale?: string) => EChartsOption) = (workspace?: Workspace, decimalPlaces?: number, numericScale?: string) => {
  const displayFinanceKpis = workspace?.settings?.displayFinanceKpis || false;
  const pnlEvolutionKpisMap = displayFinanceKpis ? PNL_EVOLUTION_FINANCE_KPIS_MAP : PNL_EVOLUTION_KPIS_MAP; 
  const pnlEvolutionKpisName = displayFinanceKpis ? PNL_EVOLUTION_FINANCE_KPIS_NAME : PNL_EVOLUTION_KPIS_NAME; 
  return {
    title: {
      text: 'Profit & Loss',
      show: true,
      textStyle: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontFamily: 'simcel-Bw-Mitga',
        fontSize: 16,
        lineHeight: 19,
        color: '#686868'
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' },
      backgroundColor: '#d1d1d1',
    },
    textStyle: { color: '#979797' },
    legend: { show: false },
    grid: {
      show: true,
      height: 'auto',
      left: '3%',
      right: '4%',
      containLabel: true,
      backgroundColor: '#ffffff',
      borderColor: '#f2f2f2',
    },
    xAxis: [
      {
        type: 'category',
        data: workspace ? filterPnlHiddenKpisMap(pnlEvolutionKpisMap, workspace).map(kpi => getTransformedKpiValue(kpi, workspace)) : pnlEvolutionKpisName,
        axisLabel: {
          interval: 0,
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontFamily: 'simcel-Bw-Mitga',
          fontSize: 9,
          lineHeight: 14,
          color: '#000',
        },
        axisLine: { show: false },
        axisTick: { show: false },
      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontFamily: 'Roboto',
          fontSize: 12,
          lineHeight: 14,
          color: '#000',
          formatter: (v: string | number) => transformInputToKpiFormat(v, '-', [], decimalPlaces || 0, numericScale || ''),
        },
        axisLine: { show: false },
        axisTick: {
          show: true,
          lineStyle: { color: '#C4C4C4', type: 'dotted', width: 5 },
        },
      },
    ],
  }
};
