import { createFeatureSelector, createSelector } from '@ngrx/store';
import { select_latestActual } from '@/store/actual/actual.selectors';

import { WorkspaceState, WORKSPACE_STATE_KEY } from './workspace.state';
import { select_selectedPlan } from '../pages/layout/layout.selectors';

const selectFeature = createFeatureSelector<WorkspaceState>(WORKSPACE_STATE_KEY);
export const select_workspacesLoading = createSelector(selectFeature, (state) => state.loading);
export const select_selectedWorkspaceId = createSelector(selectFeature, (state) => state.selected);
export const select_selectedWorkspace = createSelector(
  selectFeature,
  (state) => state.data.find((d) => d.id === state.selected) || state.data[0],
);
export const select_isNewBaseDemandAvailable = createSelector(
  selectFeature,
  (state) => state.isNewBaseDemandAvailable
);
export const select_params_loadForecastSettingsChartData = createSelector(
  select_selectedPlan,
  select_latestActual,
  (
    selectedPlan,
    latestActual
  ): any | undefined => {
    if (!selectedPlan) return;

    const { actualEndDate } = latestActual;
    const date = new Date(actualEndDate);
    date.setMonth(date.getMonth() - 12);
    date.setDate(date.getDate() + 1);
    const newDateString = date.toISOString().slice(0, 10);

    return {
      planId: selectedPlan.id,
      dateRange: {
        start: newDateString,
        end: actualEndDate
      },
      workspaceId: selectedPlan.workspace.id,
      forecastCollection: selectedPlan.forecastCollection,
    };
  },
);

