import { Injectable } from '@angular/core';
import { HttpService } from '../backend/common/api/http.service';
import { Segment } from '../interfaces/business/segment';
import { Scenario } from '../interfaces/business/scenario';

export interface IGetBusinessDriverParams {
  segment?: Segment;
  mainDim?: string;
  mainKPI?: string;
  secondaryDim?: string;
  secondaryKPI?: string;
  simcelInputDb?: string;
  simcelOutputDb?: string;
  startDate?: string;
  endDate?: string;
  highlightedScenario?: Scenario;
  top?: number;
  by?: number;
  duration?: number;
  planId?: string;
  currency?: string;
  highlightedScenarioId?: string;
}

export interface BusinessDriverData {
  [key: string]: number | string;
}

@Injectable()
export class BusinessDriverService {
  constructor(private readonly httpService: HttpService) { }

  getBusinessDriver(params: IGetBusinessDriverParams) {
    return this.httpService.post('business-driver', params);
  }
}

