import { forkJoin, Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  BreakdownService,
  CURRENT_PLAN_ENDPOINT,
  COMMITTED_PLANS_ENDPOINT,
} from '../../../@core/entity/breakdown.service';
import {
  ATOMIC_KPIS_TO_BREAKDOWN_MAP,
  Breakdown,
  emptyBreakdown,
  KpiMetadata,
  mergeBreakdowns,
} from '../../../@core/interfaces/business/breakdown';
import { Plan } from '../../../@core/interfaces/business/plan';
import { Segment } from '../../../@core/interfaces/business/segment';
import { Scenario, ScenarioFlag } from '../../../@core/interfaces/business/scenario';
import { fromBreakdowns } from '../../../@core/interfaces/business/timeseries';
import { SimpleDateRange, intersection } from '../../../@core/interfaces/common/date-range';
import { PlanningExplorerScenarioResults } from './planning-explorer.models';
import { Actual } from '@/app/@core/interfaces/business/actual';
import { formatYYYYMMDD } from '@/utils/calendar';
import { MasterFieldDisplayNamePipe } from '@/app/shared/master-field-display-name.pipe';
import { Workspace } from '@/app/@core/interfaces/common/workspace';

export const MAX_SCENARIO_DISPLAY_COUNT = 7;
export const MAX_NUMBER_OF_IMPACTS = 15; 

/**
 * Modify numeric data from a breakdown for mocking the results. Refer to the linked asana
 * task for more information: https://app.asana.com/0/1171584194938875/1190037091328809
 *
 * TODO(nathanielcapule): This is a temporary change. Remove on production.
 */
export function distortBreakdownData(breakdown: Breakdown, multiplier: number): Breakdown {
  return {
    ...breakdown,
    cogs: breakdown.cogs * multiplier,
    demandValue: breakdown.demandValue * multiplier,
    tradeExpenses: breakdown.tradeExpenses * multiplier,
    distributionCost: breakdown.distributionCost * multiplier,
    grossSalesValue: breakdown.grossSalesValue * multiplier,
    handlingCost: breakdown.handlingCost * multiplier,
    netSalesValue: breakdown.netSalesValue * multiplier,
    storageCost: breakdown.storageCost * multiplier,
    transferCost: breakdown.transferCost * multiplier,
  };
}

/** Load and populate PlanningExplorerScenario from a given scenario reference. */
export function loadPlanningExplorerFromScenario(
  plan: Plan,
  soonestActual: Actual,
  scenario: Scenario,
  breakdownService: BreakdownService,
  options?: {
    segment: Segment;
    dateRange: SimpleDateRange;
  },
): Observable<PlanningExplorerScenarioResults> {
  let customEndpoint: string | null = null;

  // Special handler for loading dataset of committed plans scenario.
  if ((scenario.flags || []).includes(ScenarioFlag.COMMITTED)) {
    customEndpoint = COMMITTED_PLANS_ENDPOINT;
    // Limits the datapoints retrieved from committed plans with actualStartDate and
    // futurePlanStartDate.
    if (options)
      options.dateRange = intersection(options.dateRange, {
        start: formatYYYYMMDD(soonestActual.actualStartDate),
        end: plan.futurePlanEndDate,
      });
  }
  // Special handler for loading dataset of actual scenario.
  if ((scenario.flags || []).includes(ScenarioFlag.ACTUAL)) {
    // Early exit if we want to load data for actual scenario, since 'Actual' is already
    // retrieved from the parent planning explorer state.
    return observableOf({
      scenario,
      breakdown: emptyBreakdown(),
      timeseries: fromBreakdowns(scenario.id, []),
    });
  }
  // Special handler for loading dataset of current scenario.
  if ((scenario.flags || []).includes(ScenarioFlag.CURRENT)) {
    customEndpoint = CURRENT_PLAN_ENDPOINT;
    if (options)
      options.dateRange = intersection(options.dateRange, {
        start: plan.currentPlanStartDate,
        end: plan.currentPlanEndDate,
      });
  }

  const breakdownObs = breakdownService
    .getWithPostQuery(
      { database: scenario.outputDatabase || '' },
      {
        ...options,
        segmentDatabase: scenario.masterInputDatabase,
        companyId: plan.company,
        planId: plan.id,
      },
      {},
      customEndpoint || '',
    )
    .pipe(
      map((arr) => arr.map((v) => ({ ...v, id: scenario.id }))),
      map((arr) => mergeBreakdowns(...arr) || emptyBreakdown()),
    );

  const timeseriesObs = breakdownService
    .getWithPostQuery(
      { database: scenario.outputDatabase || '' },
      {
        ...options,
        segmentDatabase: scenario.masterInputDatabase,
        companyId: plan.company,
        interval: 'day',
        planId: plan.id,
      },
      {},
      customEndpoint || '',
    )
    .pipe(map((arr) => fromBreakdowns(scenario.id, arr)));

  return forkJoin([observableOf(scenario), breakdownObs, timeseriesObs]).pipe(
    map(([scenario, breakdown, timeseries]) => ({
      scenario,
      breakdown,
      timeseries,
    })),
  );
}

export function getShortName(name: string) {
  return ATOMIC_KPIS_TO_BREAKDOWN_MAP[name] ?? name;
}

export function getTransformedKpiValue(kpi: { name: string, label: string }, workspace: Workspace | undefined): string {
  const kpiFieldDisplayNames = workspace?.settings?.kpiFieldDisplayNames;
  const shortName = getShortName(kpi.name);

  if (!kpiFieldDisplayNames || !kpiFieldDisplayNames[shortName] || !workspace) {
    return kpi.label;
  }

  return MasterFieldDisplayNamePipe.prototype.transform.call(
    null,
    shortName,
    kpiFieldDisplayNames
  );
}

export function getScenarioName(scenarioId: string, scenarios: Scenario[]): string {
  const scenario = scenarios.find((scenario) => scenario.id === scenarioId)

  if (scenario) {
    return scenario.name
  }

  // Can not found scenario cause the scenarioId belongs to special scenario
  switch (scenarioId) {
    case 'current':
      return 'Last Plan'
    case 'actual':
      return 'Actual'
    case 'committed':
      return 'Committed Plans'
    case 'baseDemand':
      return 'Base Demand'
    default:
      const actualreduceMonth = scenarioId.split('actual-')

      return 'Actual - ' + actualreduceMonth[1]
  }
}

export function getTransformedKpiTableValue(kpi: KpiMetadata, workspace: Workspace | undefined): string {
  const kpiFieldDisplayNames = workspace?.settings?.kpiFieldDisplayNames;

  if (!kpiFieldDisplayNames || !kpiFieldDisplayNames[kpi.shortName] || !workspace) {
    return kpi.name;
  }

  return MasterFieldDisplayNamePipe.prototype.transform.call(
    null,
    kpi.shortName,
    kpiFieldDisplayNames
  );
}
