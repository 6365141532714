import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Plan } from '../../interfaces/business/plan';
import { HttpService } from '../common/api/http.service';

export interface IDeletePlanParams {
  plan: Plan | undefined;
}

/** Simplified service for triggering and monitoring end-to-end runs. */
@Injectable()
export class PlanService {
  private readonly apiController: string = 'explorer/plans';

  constructor(private readonly api: HttpService) { }

  delete({ plan }: IDeletePlanParams): Observable<Plan> {
    const planId = plan?.id;
    return this.api.delete(`${this.apiController}/${planId}`);
  }
}
