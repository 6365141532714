<div class="planning-comment-div">
  <!-- when open list comments, it will be scroll to scrollHeight (last message) -->
  <div class="comments-container" #scrollMe [scrollTop]="scrollMe.scrollHeight">
    <ng-container *ngFor="let item of (combinedArray)">
      <ng-container *ngIf="isComment(item); else activityItem">
        <ng-container
          *ngTemplateOutlet="commentItem; context: { $implicit: item }"
        ></ng-container>
      </ng-container>
      <ng-template #activityItem>
        <ng-container
          *ngTemplateOutlet="activityTemplate; context: { $implicit: item }"
        ></ng-container>
      </ng-template>
    </ng-container>

    <ng-template #commentItem let-comment="$implicit">
      <div class="comment">
        <div class="name">
          {{ comment.user.firstName }} {{ comment.user.lastName }} - {{ comment.user.role }}
        </div>
        <div class="date">{{ comment.createdAt | date: 'short' }}</div>
        <div class="text">{{ comment.text }}</div>
      </div>
    </ng-template>

    <ng-template #activityTemplate let-activity="$implicit">
      <div class="comment">
        <div class="name">Activity Log </div>
        <div class="date">{{ activity.createdAt | date: 'short' }}</div>
        <div class="text">
          <label class="version-name" [nbPopover]="nbPopoverEventVersion" nbPopoverTrigger="hover" nbPopoverPlacement="top"
              nbPopoverClass="popover-content"
          >
            {{
              !activity.customMessage
                ? user?.firstName + ' ' + user?.lastName + ' has ' + activity.operation + 'd a ' + activity.entityType
                : activity.customMessage
            }}
          </label>
          <ng-template #nbPopoverEventVersion >
            <div class="nbPopoverEventVersion">
              <p><b>User:</b> {{ user?.firstName + ' ' + user?.lastName }} </p>
              <p><b>Operation:</b> {{ activity.operation }} </p>
              <p><b>Entity Type:</b> {{ activity.entityType }} </p>
              <p><b>Entity ID:</b> {{ activity.entityId }} </p>
            </div>
          </ng-template>
        </div>
      </div>
    </ng-template>
  </div>

  <div class="input-container">
    <textarea
      nbInput
      class="input-comment"
      fullWidth
      type="text"
      placeholder="Input text"
      [(ngModel)]="text"
      (keydown.enter)="onEnter($event)"
    ></textarea>
  </div>
</div>
