<nb-card fullWidth>
  <nb-card-body class="d-flex">
    <div class="col-3 filter-col">
      <div class="filter-label">
        <span
          nz-icon
          [nzType]="isProductEyeOn ? 'eye' : 'eye-invisible'"
          nzTheme="fill"
          class="icon-style"
          (click)="onProductEyeClick()"
        ></span>
        Products
      </div>
      <div class="filter-box">
        <cel-select-tree
          data-testid="e2e-inp-filter-products"
          placeholder="All"
          searchPlaceholder="Search Product"
          [filters]="(selectedSegment | async)?.product | ensureArray"
          [settings]="(segmentSettings | async)?.product | ensureArray"
          [data]="segmentProductsOptions"
          [showExpandIndicator]="true"
          (filtersChange)="onProductFiltersChanged($event)"
        ></cel-select-tree>
      </div>
    </div>
    <div class="col-3 filter-col" *ngIf="!(useDistributorFilter$ | async)">
      <div class="filter-label">
        <span
          nz-icon
          [nzType]="isCustomerEyeOn ? 'eye' : 'eye-invisible'"
          nzTheme="fill"
          class="icon-style"
          (click)="onCustomerEyeClick()"
        ></span>
        Customers
      </div>
      <div class="filter-box">
        <cel-select-tree
          data-testid="e2e-inp-filter-customers"
          placeholder="All"
          searchPlaceholder="Search Customer"
          [filters]="(selectedSegment | async)?.customer | ensureArray"
          [settings]="(segmentSettings | async)?.customer | ensureArray"
          [data]="segmentCustomersOptions"
          [showExpandIndicator]="true"
          (filtersChange)="onCustomerFiltersChanged($event)"
        ></cel-select-tree>
      </div>
    </div>
    <div class="col-3 filter-col" *ngIf="useDistributorFilter$ | async">
      <div class="filter-label">
        <span
          nz-icon
          [nzType]="isCustomerEyeOn ? 'eye' : 'eye-invisible'"
          nzTheme="fill"
          class="icon-style"
          (click)="onCustomerEyeClick()"
        ></span>
        Customers
      </div>
      <div class="filter-box">
        <cel-select-tree
          data-testid="e2e-inp-filter-customers"
          placeholder="All"
          searchPlaceholder="Search Customer"
          [filters]="(selectedSegment | async)?.distributor | ensureArray"
          [secondaryFilters]="(selectedSegment | async)?.customer | ensureArray"
          [settings]="(segmentSettings | async)?.distributor | ensureArray"
          [secondarySettings]="(segmentSettings | async)?.customer | ensureArray"
          [data]="segmentDistributorsOptions | async | ensureArray"
          [secondaryData]="segmentCustomersOptions"
          [showExpandIndicator]="true"
          (filtersChange)="onDistributorFiltersChanged($event)"
          (secondaryFiltersChange)="onCustomerFiltersChanged($event)"
          [isMultiFacility]="true"
        ></cel-select-tree>
      </div>
    </div>
    <div class="col-3 filter-col">
      <div class="filter-label">
        <span
          nz-icon
          [nzType]="isLocationEyeOn ? 'eye' : 'eye-invisible'"
          nzTheme="fill"
          class="icon-style"
          (click)="onLocationEyeClick()"
        ></span>
        Locations
      </div>
      <div class="filter-box">
        <cel-select-tree
          data-testid="e2e-inp-filter-locations"
          placeholder="All"
          searchPlaceholder="Search Location"
          [filters]="(selectedSegment | async)?.location | ensureArray"
          [settings]="(segmentSettings | async)?.location | ensureArray"
          [data]="segmentLocationsOptions"
          [showExpandIndicator]="true"
          (filtersChange)="onLocationFiltersChanged($event)"
        ></cel-select-tree>
      </div>
    </div>
    <div class="save-as-segment" >
        <button
        size="small"
        nbButton
        status="primary"
        (click)="addSavedSegment()"
      >
      <nb-icon nbPrefix icon="save-outline"></nb-icon>
    </button>
    </div>
    
    <div class="col-2 selected-segment">
      <div class="filter-label">Selected Segment</div>
      <nb-form-field class="popover-container">
        <input
          type="text"
          class="filter-box"
          nbInput
          fullWidth
          [nbPopover]="component"
          nbPopoverPlacement="bottom"
          fieldSize="small"
          placeholder="Select a Segment"
          (click)="openSegmentManagementPopOver()"
          [value]="(selectedSegment | async)?.name || ''"
        />
        <nb-icon
          nbSuffix
          icon="chevron-down-outline"
          (click)="openSegmentManagementPopOver()"
        ></nb-icon>
      </nb-form-field>
    </div>
    <div class="col-2 date-range-box">
      <div class="filter-label">{{ dateRangeSelectionText }} Range</div>
      <nz-range-picker
        class="form-control range-picker"
        [nzMode]="dateRangeSelectionText.toLowerCase()"
        [formControl]="formControl_selectedRange"
        [nzPlaceHolder]="'Select ' + dateRangeSelectionText + ' Range'"
        [nzAllowClear]="false"
        [nzDisabledDate]="selectableDateRangeFn | async | defaultTo: noDateAllowed"
        [nzRanges]="presettedDateRanges | async | defaultTo: {}"
      ></nz-range-picker>
    </div>

    <button
      class="btn-setting"
      status="primary"
      (click)="openWidgetManagementWindow()"
    >
      <nb-icon icon="settings-2-outline"></nb-icon>
    </button>

    <div class="col-1">
      <div class="filter-label">Currencies</div>
      <nb-select size="tiny" [(ngModel)]="selectedCurrency" (ngModelChange)="onSelectCurrency($event)">
          <nb-option *ngFor="let currency of supportedCurrencies" [value]='currency'>{{ currency }}</nb-option>
      </nb-select> 
    </div>
  </nb-card-body>
  <div class="breadcrumb-wrapper">
    <div *ngIf="isProductEyeOn">
      <nz-breadcrumb nzSeparator=">">
        <nz-breadcrumb-item *ngFor="let crumb of productBreadcrumb" class="breadcrumb-text">
          {{ crumb }}
        </nz-breadcrumb-item>
      </nz-breadcrumb>
    </div>

    <div *ngIf="isCustomerEyeOn && !(useDistributorFilter$ | async)">
      <nz-breadcrumb nzSeparator=">">
        <nz-breadcrumb-item *ngFor="let crumb of customerBreadcrumb" class="breadcrumb-text">
          {{ crumb }}
        </nz-breadcrumb-item>
      </nz-breadcrumb>
    </div>

    <div *ngIf="isDistributorEyeOn && (useDistributorFilter$ | async)">
      <nz-breadcrumb nzSeparator=">">
        <nz-breadcrumb-item *ngFor="let crumb of distributorBreadcrumb" class="breadcrumb-text">
          {{ crumb }}
        </nz-breadcrumb-item>
      </nz-breadcrumb>
    </div>

    <div *ngIf="isLocationEyeOn">
      <nz-breadcrumb nzSeparator=">">
        <nz-breadcrumb-item *ngFor="let crumb of locationBreadcrumb" class="breadcrumb-text">
          {{ crumb }}
        </nz-breadcrumb-item>
      </nz-breadcrumb>
    </div>
  </div>
</nb-card>
