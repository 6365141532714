import { createFeatureSelector, createSelector } from '@ngrx/store';
import { select_selectedWorkspace } from '../workspace/workspace.selectors';

import { ISegmentState, SEGMENT_STATE_KEY } from './segment.state';

const selectFeature = createFeatureSelector<ISegmentState>(SEGMENT_STATE_KEY);

export const select_segments = createSelector(selectFeature, (state) => state.data);
export const select_segmentOptions = createSelector(selectFeature, (state) => state.options);
export const select_loadingSegments = createSelector(selectFeature, (state) => state.loading);

export const select_segmentProductsOptions = createSelector(
  select_segmentOptions,
  (options) => options?.products || [],
);
export const select_segmentCustomersOptions = createSelector(
  select_segmentOptions,
  (options) => options?.customers || [],
);

export const select_segmentDistributorsOptions = createSelector(
  select_segmentOptions,
  (options) => options?.customers || [],
);

export const select_segmentLocationsOptions = createSelector(
  select_segmentOptions,
  (options) => options?.locations || [],
);

export const select_segment_settings = createSelector(
  select_selectedWorkspace,
  selectFeature,
  (workspace, state) => {
    const settings = state.settings;
    if (workspace?.settings?.segment) {
      return { ...settings, ...workspace.settings.segment };
    }
    return settings;
  });

export const select_channel_segment_settings = createSelector(
  selectFeature,
  (state) => {
    const settings = state.settings;
    return {
      ...settings, ...{
        product: [],
        customer: [{
          type: 'channel',
        }],
        location: [],
      }
    };
  });
