import { Actual } from '@/app/@core/interfaces/business/actual';
import { Plan } from '@/app/@core/interfaces/business/plan';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { select_latestActual, select_soonestActual } from '../actual/actual.selectors';
import { select_selectedPlan } from '../pages/layout/layout.selectors';

import { IEventState, EVENT_STATE_KEY } from './event.state';
import { sortEventsByName } from '@/app/@core/interfaces/business/event';

const selectFeature = createFeatureSelector<IEventState>(EVENT_STATE_KEY);
export const select_events = createSelector(selectFeature, (state) => state.data);
export const select_incEventsLoadVersion = createSelector(selectFeature, (state) => state.incEventsLoadVersion);

// map event version id to event name
export const select_eventNamesLookup = createSelector(selectFeature, (state) =>
  Object.fromEntries(
    state.data.flatMap((event) =>
      event.versions.map((eventVersion) => [
        eventVersion.id,
        `${event.name} - ${eventVersion.name}`,
      ]),
    ),
  ),
);

export const select_sortedEvents = createSelector(
  select_events,
  (events) => sortEventsByName([...events])
)

export const select_savingEventVersion = createSelector(selectFeature, (state) => state.saving);

export const select_selectedActualsAndPlan = createSelector(
  select_soonestActual,
  select_latestActual,
  select_selectedPlan,
  (firstActual, lastActual, plan): { soonestActual: Actual, latestActual: Actual, selectedPlan: Plan } | undefined => {
    if (!firstActual || !lastActual || !plan) {
      return undefined
    }

    return {
      soonestActual: firstActual,
      latestActual: lastActual,
      selectedPlan: plan,
    }
  }
)

export const select_isEventListCollapsed = createSelector(selectFeature, (state) => state.isEventListCollapsed);

