import { NbCalendarRange } from '@nebular/theme';
import { compareAsc, format, parse, subMonths, max, min, setDate, formatISO, parseISO, subYears, addYears } from 'date-fns';

import { SimpleDateRange } from '@/app/@core/interfaces/common/date-range';
import { PlanFlag } from '@/app/@core/interfaces/business/plan';
import { IDemandTreeNode } from '@/store/pages/demand-planning/demand-planning.actions';
import { MONTH_ABBREVIATIONS } from '@/store/pages/demand-planning/demand-planning.utils';

export function convertToCalendarRange(dateRange?: SimpleDateRange): NbCalendarRange<Date> | null {
  if (!dateRange) return null;
  return {
    start: new Date(dateRange.start),
    end: new Date(dateRange.end),
  };
}

export function convertToSimpleDateRange(calendarRange: NbCalendarRange<Date>): SimpleDateRange {
  return {
    start: calendarRange.start.toLocaleString('sv', { timeZoneName: 'short' }).slice(0, 10),
    end: calendarRange.end?.toLocaleString('sv', { timeZoneName: 'short' })?.slice(0, 10) || '',
  };
}

export const parseYYYYMMDD = (s: string) => parse(s, 'yyyy-MM-dd', new Date());
export const formatYYYYMMDD = (d: Date) => format(d, 'yyyy-MM-dd');

export function convertToRangePickerVal(dateRange?: SimpleDateRange): Date[] {
  if (!dateRange) return [];

  const { start, end } = dateRange

  return [parseYYYYMMDD(start), parseYYYYMMDD(end)];
}

export function convertToSimpleDateRangeFromRangepickerVal(range: Date[]): SimpleDateRange {
  return {
    start: range[0] ? formatYYYYMMDD(range[0]) : '',
    // Below is the previous code. Variable range is an array of 2 elements with start time and end time
    // In there, start is range[0] so end range[1]
    // I fixed it after seeing someone made a mistake : end : range[0]
    // end: range[0] ? formatYYYYMMDD(range[1]) : '', 
    end: range[1] ? formatYYYYMMDD(range[1]) : '',
  };
}

export function sortByDateProp(a: { date?: Date }, b: { date?: Date }) {
  if (!a.date && !b.date) return 0;
  if (!a.date) return -1;
  if (!b.date) return 1;
  return compareAsc(a.date, b.date);
}

export function calculateDateRangeBasedOnEndDate(selectedPlan: any): SimpleDateRange | undefined {
  if (!selectedPlan) return;

  let startDate: string;
  let endDate: string;

  if (selectedPlan.flags?.includes(PlanFlag.ACTUAL)) {
    const defaultEndDate = parseYYYYMMDD(selectedPlan.defaultPlanDisplayEndDate);
    const calculatedStartDate = setDate(subMonths(defaultEndDate, 11), 1);
    const defaultStartDate = parseYYYYMMDD(selectedPlan.defaultPlanDisplayStartDate);
    
    startDate = formatYYYYMMDD(max([calculatedStartDate, defaultStartDate]));
    endDate = formatYYYYMMDD(defaultEndDate);
  } else {
    const planStartDate = new Date(parseYYYYMMDD(selectedPlan.defaultPlanDisplayStartDate));
    const planEndDate = new Date(planStartDate);
    planEndDate.setFullYear(planStartDate.getFullYear() + 1);
    planEndDate.setDate(planEndDate.getDate() - 1);

    const actualEndDate = new Date(parseYYYYMMDD(selectedPlan.defaultPlanDisplayEndDate));
    const finalEndDate = min([planEndDate, actualEndDate]);

    startDate = formatYYYYMMDD(planStartDate);
    endDate = formatYYYYMMDD(finalEndDate);
  }

  return {
    start: startDate,
    end: endDate,
  }
}

export function createMonthYearArray(dateRange: SimpleDateRange): string[] {
  const startDate = new Date(dateRange.start);
  const endDate = new Date(dateRange.end);
  const monthYearArray: string[] = [];

  let currentDate = startDate;
  while (currentDate <= endDate) {
    const month = MONTH_ABBREVIATIONS[currentDate.getMonth()];
    const year = currentDate.getFullYear().toString().slice(-2).padStart(2, '0');
    const formattedDate = `${month} ${year}`;
    monthYearArray.push(formattedDate);
    
    currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
  }

  return monthYearArray;
}

export function getYears(dateRange: SimpleDateRange): string[] {
  const startDate = new Date(dateRange.start);
  const endDate = new Date(dateRange.end);
  const years: string[] = [];

  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  for (let year = startYear; year <= endYear; year++) {
    years.push(String(year));
  }

  return years;
}

export function getStartAndEndDates(inputDate: string) {
  const [monthStr, yearStr] = inputDate.split(' ');
  // Ensure the yearStr has two digits
  const paddedYearStr = yearStr.padStart(2, '0');
  const year = parseInt(`20${paddedYearStr}`, 10);

  const startDate = new Date(Date.UTC(year, MONTH_ABBREVIATIONS.indexOf(monthStr), 1));

  const lastDay = new Date(Date.UTC(year, MONTH_ABBREVIATIONS.indexOf(monthStr) + 1, 0));

  const startDateStr = startDate.toISOString().split('T')[0];
  const lastDayStr = lastDay.toISOString().split('T')[0];

  return { startDate: startDateStr, endDate: lastDayStr };
}

export function subtractYearsFromDateRange(dateRange: SimpleDateRange, n: number) {
  // Parse the start and end dates using parseISO
  const startDate = parseISO(dateRange.start);
  const endDate = parseISO(dateRange.end);

  // Subtract n years from each date using subYears
  const newStartDate = subYears(startDate, n);
  const newEndDate = subYears(endDate, n);

  // Format the dates back to strings using formatISO
  const newStartDateString = formatISO(newStartDate, { representation: 'date' });
  const newEndDateString = formatISO(newEndDate, { representation: 'date' });

  // Return the new date range object
  return {
    start: newStartDateString,
    end: newEndDateString
  };
}

export function findDateIndexKYearsAhead(baseDates: string[], dateToFind: string, k: number): number {
  const dateToCompare = format(addYears(parseISO(dateToFind), k), 'yyyy-MM-dd');
  return baseDates.findIndex(date => date === dateToCompare);
}

export function transformRows(
  demandsActualXRows: [string, (number | null)[]][],
  demandsActualXColumns: [string, (string | null)[]][],
  demandsColumns: string[]
): [string, (number | null)[]][] {
  return demandsActualXRows.map(([label, values]) => {
    // Find the correct column array using label and get the first date
    const actualXColumn = demandsActualXColumns.find(([id]) => id === label);
    const firstDate = actualXColumn ? actualXColumn[1][0] : null;

    if (!firstDate) {
      console.error(`No column found for '${label}' or first date is null`);
      return [label, values]; // Return the original row if there's no date to find
    }

    // Extract offset from label
    const offset = parseInt(label.split('-')[1], 10);

    // Find the index
    const index = findDateIndexKYearsAhead(demandsColumns, firstDate, offset);

    // Initialize a new array with null values
    const newValues = Array(demandsColumns.length).fill(null);

    // Place the existing values at the right position
    for (let i = 0; i < demandsColumns.length; i++) {
      if (i >= index && i - index < values.length) {
        newValues[i] = values[i - index];
      }
    }

    // Return the transformed row
    return [label, newValues];
  });
}

export function transformTreeData(
  demandsActualXTrees: IDemandTreeNode[],
  demandsActualXColumns: [string, (string | null)[]][],
  demandsColumns: string[]
): IDemandTreeNode[] {

  function transformTree(
    tree: IDemandTreeNode,
    firstDate: string | null = null,
    index?: number
  ): IDemandTreeNode {
    let currentFirstDate = firstDate;
    let currentIndex = index || 0;

    // If firstDate is not passed in, it means it is the parent, 
    // so find the correct column array using key and get the first date
    if (!currentFirstDate) {
      const actualXColumn = demandsActualXColumns.find(([id]) => id === tree.key);
      currentFirstDate = actualXColumn ? actualXColumn[1][0] : null;

      if (!currentFirstDate) {
        console.error(`No column found for '${tree.key}' or first date is null`);
        return { ...tree }; // Return a deep copy of the original tree if there's no date to find
      }

      // Extract offset from key for the parent as it is not passed as a parameter
      const offset = parseInt(tree.key.split('-')[1], 10);

      // Calculate the index
      currentIndex = findDateIndexKYearsAhead(demandsColumns, currentFirstDate, offset);
    }

    // Initialize a new array with null values
    const newData = new Array(demandsColumns.length).fill(null);

    // Place the existing values at the right position
    for (let i = 0; i < demandsColumns.length; i++) {
      if (i >= currentIndex && i - currentIndex < tree.data.length) {
        newData[i] = tree.data[i - currentIndex];
      }
    }

    // Create a copy of the tree with transformed data
    const newTree = { ...tree, data: newData };

    // If tree has children, recursively transform them with the parent's firstDate and index
    if (tree.children) {
      newTree.children = tree.children.map(child => 
        transformTree({ ...child }, currentFirstDate, currentIndex)
      );
    }

    // Return the transformed tree
    return newTree;
  }

  // Transform the trees
  return demandsActualXTrees.map(tree => transformTree(tree));
}
