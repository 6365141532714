import { IEditWorkspaceSettingParams } from '@/app/@core/entity/workspace-setting.service';
import { createAction, props } from '@ngrx/store';

// NOTE: not used yet
export const SELECT_WORKSPACE = createAction(
  '[🤖 Workspace API] Select Workspace',
  props<{ id: string }>(),
);
export const BEGIN_UPDATE_WORKSPACE_SETTINGS = createAction(
  '[🌱 Workspace API] Updating Workspace Settings...',
  props<{ params: IEditWorkspaceSettingParams }>(),
);
export const BEGIN_UPDATE_WORKSPACE_SETTINGS_FOR_SANOFI = createAction(
  '[🌱 Workspace API] Updating Workspace Settings For Sanofi...',
  props<{ params: IEditWorkspaceSettingParams }>(),
);
export const UPDATE_WORKSPACE_SETTINGS_SUCCESS = createAction(
  '[⚡️ Workspace API] Update Workspace Settings successfully',
  props<{ data: any }>(),
);
export const UPDATE_WORKSPACE_SETTINGS_FAILED = createAction(
  '[⚡️ Workspace API] Update Workspace Settings failed',
  props<{ error: any }>(),
);

export const GET_BASE_DEMAND_AVAILABILITY = createAction(
  '[⚡️ Workspace API] Get base demand availability',
  props<{ id: string }>(),
);
export const GET_BASE_DEMAND_AVAILABILITY_SUCCESS = createAction(
  '[⚡️ Workspace API] Get base demand availability successfully',
  props<{ data: any }>(),
);
export const GET_BASE_DEMAND_AVAILABILITY_FAILED = createAction(
  '[⚡️ Workspace API] Get base demand availability failed',
  props<{ error: any }>(),
);
