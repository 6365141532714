import { createReducer, on } from '@ngrx/store';
import { EtlTask } from '@/app/@core/backend/simcel/etl.service';
import { 
  FETCH_RUNNING_ETL_TASKS,
  FETCH_RUNNING_ETL_TASKS_SUCCESS,
  FETCH_RUNNING_ETL_TASKS_FAILED
} from './etl.actions';

export const ETL_STATE_KEY = 'etl';

/*************************************
 * State
 *************************************/

export interface IEtlTaskState {
  runningEtlTasks: EtlTask[];
  loading: boolean;
  errors: any;
}

export const initialState: IEtlTaskState = {
  runningEtlTasks: [],
  loading: false,
  errors: [],
};

/*************************************
 * Reducers
 *************************************/
export const etlReducer = createReducer(
  initialState,
  on(FETCH_RUNNING_ETL_TASKS, (state) => ({
      ...state,
      loading: true
  })),
  on(FETCH_RUNNING_ETL_TASKS_SUCCESS, (state, { data }) => ({
    ...state,
    runningEtlTasks: data,
    loading: false
  })),
  on(FETCH_RUNNING_ETL_TASKS_FAILED, (state, { error }) => ({
    ...state,
    runningEtlTasks: [],
    loading: false,
    errors: error
  })),
);
