import { sortActualsByEndDate, sortActualsByStartDate } from '@/app/@core/interfaces/business/actual';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { IActualState, ACTUAL_STATE_KEY } from './actual.state';
import { SimpleDateRange } from '@/app/@core/interfaces/common/date-range';
import { formatYYYYMMDD } from '@/utils/calendar';

const selectFeature = createFeatureSelector<IActualState>(ACTUAL_STATE_KEY);

export const select_actuals = createSelector(selectFeature, (state) => state.data);

export const select_sortedActualByStartDate = createSelector(select_actuals, (actuals) =>
  sortActualsByStartDate([...actuals]),
);

export const select_sortedActualByEndDate = createSelector(select_actuals, (actuals) =>
  sortActualsByEndDate([...actuals]),
);

export const select_soonestActual = createSelector(
  select_sortedActualByStartDate,
  (sortedActuals) => sortedActuals[0],
);

export const select_latestActual = createSelector(
  select_sortedActualByEndDate,
  (sortedActuals) => sortedActuals[0],
);


