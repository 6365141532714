import { EventType } from '@/app/@core/interfaces/business/event';
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, of } from 'rxjs';
import { propOr } from 'rambda';
import { Segment, SegmentFilter } from '../../../@core/interfaces/business/segment';
import { Subscription } from 'rxjs/internal/Subscription';
import { Store } from '@ngrx/store';
import { select_selectedWorkspace } from '@/store/workspace/workspace.selectors';
import { Workspace } from '@/app/@core/interfaces/common/workspace';
import { MasterFieldDisplayNameService } from '@/app/shared/master-field-display-name.service';
import { TradeTerms } from '@/app/pages/explorer/events-management/widgets/trade-terms/trade-terms.component';

interface Option {
  type: string;
  value: string;
  ref?: string;
}

// function GroupBy<T>(data: IterableIterator<T>, field: string) {
//   const map = new Map<string, Set<T>>();
//   for (const v of data) {
//     const k = v[field];
//     if (!map.has(k)) {
//       map.set(k, new Set());
//     }
//     map.get(k).add(v);
//   }
//   return map;
// }

export interface SegmentAdapter {
  search(term: string, entity: string, customerType?: string): Observable<Option[]>;
  count?(segment: Segment): Observable<any>;
}

@Component({
  selector: 'cel-segment-select-box',
  templateUrl: './segment-select-box.component.html',
  styleUrls: ['./segment-select-box.component.scss'],
})
export class SegmentSelectBoxComponent implements OnChanges {
  readonly EventType = EventType;
  selectedOptions: Option[] = [];
  workspace?: Workspace;
  private subWorkspace?: Subscription;

  constructor(private readonly domSanitizer: DomSanitizer, private readonly store: Store, private readonly displayNameService : MasterFieldDisplayNameService) {
    this.subWorkspace = this.store
      .select(select_selectedWorkspace)
      .subscribe((workspace: Workspace) => {
        this.workspace = workspace;
      });
  }

  count = {
    product: 0,
    customer: 0,
    dc: 0,
  };

  segment: Segment = {};

  @Input() eventType?: EventType;

  @Input() adapter: SegmentAdapter = {
    search: () => of([]),
    count: () => of({}),
  };

  @Input() eventDetail?: any;
  @Input() isTradeTerm?: boolean;
  @Input() selectedTradeTerm?: TradeTerms;
  @Input() customerType: 'customer' | 'distributor' = 'customer';

  isSelectProductVisible(): boolean {
    if (!this.isTradeTerm && !this.selectedTradeTerm) {
      return true;
    }

    const isSelectedTradeTermIncluded = [TradeTerms.FLAT_DISCOUNT, TradeTerms.DEMAND_IMPACT].includes(this.selectedTradeTerm as TradeTerms);
    return isSelectedTradeTermIncluded;
  }

  @Input() set value(s: Segment | undefined) {
    if (!s) return;
    // clone the object
    this.segment = s;
    this.segment.customer = s.customer || [{}];
    this.segment.product = s.product || [{}];
    this.segment.location = s.location || [{}];
    this.segment.distributor = s.distributor || [{}];
    this.segment.dc = s.dc || [{}];

    if (s.dc && s.dc.length > 0) {
      const dcNames = s.dc[0].name

      this.selectedOptions = dcNames?.map(name => ({
        type: 'name',
        value: name
      }))
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('customerType' in changes) {
      this.segment.customerType = this.customerType;
      this.emitChange();
    }
  }

  @Output() valueChanged = new EventEmitter<Segment>();

  addFilter(filters?: SegmentFilter[]) {
    if (!filters) return;
    const firstFilterEmpty = filters.length > 0 && Object.keys(filters[0]).length < 1;
    if (!firstFilterEmpty) {
      filters.unshift({});
    }
  }

  updateFilter(filters: SegmentFilter[] | undefined, option: Option, index: number) {
    if (!filters) return;
    const filter = filters[index];
    const { type, value } = option;
    if (!Array.isArray(filter[type])) {
      filter[type] = [value];
    } else if (!filter[type].includes(value)) {
      filter[type].push(value);
    }
    filters[index] = filter;
    this.emitChange();
  }

  deleteFilter(filters: SegmentFilter[] | undefined, index: number) {
    if (!filters) return;
    filters.splice(index, 1);
    if (filters.length < 1) {
      filters.push({});
    }
    this.emitChange();
  }

  updateFilterMultiOptions(filters: SegmentFilter[] | undefined, options: Option[]) {
    if (!filters) return;
    const filter = {};
    for (const option of options) {
      if (filter[option.type]) {
        filter[option.type].push(option.value);
      } else {
        filter[option.type] = [option.value];
      }
      if (filter["ref"]) {
        filter["ref"].push(option.ref);
      } else {
        filter["ref"] = [option.ref];
      }
    }
    filters[0] = filter;
    this.emitChange();
  }

  preview(filter: SegmentFilter, isDC?: boolean) {
    let self = this;
    let refs: string[] = [];
    if (filter.hasOwnProperty("ref")) {
      refs = filter.ref;
    }
    const s = Object.entries(filter)
      .filter(([k, v]) => !isDC || k !== "ref")
      .map(([k, v]) => {
        let key = this.fieldToText[k] || k;
        key = self.displayNameService.getCustomisedMasterDataFieldNames(k);
        const value = v.map((v, i) => (isDC ? `${v} - ${refs[i]}` : v)).join(', ');
        return `<strong>${key}</strong>: ${value}`;
      })
      .join(' / ');
    return this.domSanitizer.bypassSecurityTrustHtml(s);
  }

  emitChange() {
    this.valueChanged.emit(this.segment);
  }

  private readonly fieldToText = {
    name: 'Name',
    channel: 'Channel',
    subChannel: 'Subchannel',
    keyAccount: 'Key Account',
    country: 'Country',
    region: 'Region',
    city: 'City',
    category: 'Category',
    subCategory: 'Subcategory',
    brands: 'Brand',
    productRange: 'Product Range',
  };

  ngOnDestroy(): void {
    this.subWorkspace?.unsubscribe();
  }
}
