import { createAction, props } from '@ngrx/store';

import { ForecastBaseTask, DemandsShaperTask } from '@/app/@core/backend/simcel/demand-generation.service';

export const FETCH_PENDING_FORECAST_BASE_TASKS = createAction('[⚡ Layout] Fetch pending forecast base task');

export const FETCH_PENDING_FORECAST_BASE_TASKS_SUCCESS = createAction(
  '[🤖 Layout] Fetch pending forecast base task success',
  props<{ data: ForecastBaseTask[] }>(),
);

export const FETCH_PENDING_FORECAST_BASE_TASKS_FAILED = createAction(
  '[🤖 Layout] Fetch pending forecast base task failed',
  props<{ error: any }>(),
);

export const FETCH_PENDING_DEMANDS_SHAPER_TASKS = createAction(
  '[⚡ Layout] Fetch pending demands shaper task',
  props<{ planId: string }>(),
);

export const FETCH_PENDING_DEMANDS_SHAPER_TASKS_SUCCESS = createAction(
  '[🤖 Layout] Fetch pending demands shaper task success',
  props<{ data: DemandsShaperTask[] }>(),
);

export const FETCH_PENDING_DEMANDS_SHAPER_TASKS_FAILED = createAction(
  '[🤖 Layout] Fetch pending demands shaper task failed',
  props<{ error: any }>(),
);

export const FORECAST_BASE_TASKS_RAN_SUCCESSFULLY = createAction(
  '[🤖 Layout] Forecast base task ran successfully'
);

export const DEMANDS_SHAPER_TASKS_RAN_SUCCESSFULLY = createAction(
  '[🤖 Layout] Demands shaper task ran successfully'
);

export const START_CHECKING_PENDING_FORECAST_BASE_TASKS = createAction('[⚡ Layout] Checking pending forecast base task');
export const START_CHECKING_PENDING_DEMANDS_SHAPER_TASKS = createAction(
  '[⚡ Layout] Checking pending demands shaper task',
  props<{ planId: string }>(),
);
