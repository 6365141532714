import { Component, Input } from '@angular/core';

@Component({
  selector: 'cel-forecaster-running-overlay',
  template: `
    <div *ngIf="isShow" class="overlay">
      <div class="backdrop"></div>
      <div class="content-wrapper">
        <div class="content">
          <div class="media">
            <img src="../../../../assets/images/simcel-logo-animation.gif" />
          </div>
          <div>
            <p>The forecaster is running, which might take some time.</p>
            <p>Feel free to explore other plan while we complete this task.</p>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .overlay {
        position: fixed;
        top: 45px;
        left: 70px;
        bottom: 0;
        right: 0;
        z-index: 99;
      }

      .backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        background-color: #ccc;
      }

      .content-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
      }

      .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        padding: 20px;
        border-radius: 3px;
      }

      .media {
        float: left;
        width: 50px;
        height: 100px;
        margin-right: 15px;
        align-items: center;
      }

      .media img {
        width: 50px;
        height: 50px;
      }
    `
  ]
})
export class ForecasterRunningOverlayComponent {
  @Input() isShow: boolean = false;
}
