<nb-form-field class="popover-container">
  <input
    type="text"
    class="filter-box"
    [placeholder]="placeholder"
    [value]="preview"
    readonly
    nbInput
    fullWidth
    [nbPopover]="popup"
    [nbTooltipDisabled]="disabledPopup"
    [ngClass]="{ 'cursor-disabled': disabledPopup }"
    nbPopoverPlacement="bottom"
    fieldSize="small"
    (keydown)="$event.preventDefault()"
  />
  <nb-icon nbSuffix icon="chevron-down-outline"></nb-icon>
</nb-form-field>

<ng-template #popup>
  <div class="popup" (click)="$event.stopPropagation()">
    <div class="p-1">
      <div class="select-all" style="font-size: 11px;float: right;">
        <a *ngIf="!isProxy" href="javascript:void(0)" (click)="selectAll()">Select All</a>
        &nbsp;&nbsp;
        <a href="javascript:void(0)" (click)="deselectAll()">Deselect All</a>
      </div>
      
      <nb-form-field>
        <nb-icon nbPrefix icon="search" pack="eva"></nb-icon>
        <input class="search-filter-section" nbInput [value]="query$ | async" (input)="search($event)" fullWidth
          autofocus fieldSize="small" [placeholder]="searchPlaceholder" />
      </nb-form-field>
    </div>
    <div *ngIf="isMultiFacility" class="row" (click)="toggleParent()" [class.expanded]="parentExpanded">
      <nb-checkbox [indeterminate]="parentIndeterminate" [checked]="parentSelected" (checkedChange)="onParentChanged($event)">
      </nb-checkbox>
      <span>Distributors</span>
      <nb-icon nbSuffix icon="chevron-right-outline" *ngIf="!parentExpanded"></nb-icon>
      <nb-icon nbSuffix icon="chevron-down-outline" *ngIf="parentExpanded"></nb-icon>
    </div>
    <div *ngIf="!isMultiFacility || parentExpanded">
      <div *ngFor="let v of viewRows$ | async; trackBy: trackBy" [class]="'level-'+(v.level + (isMultiFacility ? 1 : 0))" (click)="toggle(v)"
        class="row">
        <nb-checkbox [disabled]="isProxy && v.expandable" [indeterminate]="v.indeterminate" [checked]="v.selected" (checkedChange)="onChanged(v, $event)">
        </nb-checkbox>
        <span> {{ v.title }} </span>
        <nb-icon nbSuffix icon="chevron-right-outline"
          *ngIf="showExpandIndicator && v.expandable && !v.expanded"></nb-icon>
        <nb-icon nbSuffix icon="chevron-down-outline"
          *ngIf="showExpandIndicator && v.expandable && v.expanded "></nb-icon>
      </div>
      <div (click)="loadMore()" class="load-more" *ngIf="canLoadMore$ | async">load more</div>
    </div>

    <div *ngIf="isMultiFacility" class="row" (click)="toggleSecondaryParent()" [class.expanded]="parentExpanded">
      <nb-checkbox [indeterminate]="secondaryParentIndeterminate" [checked]="secondaryParentSelected" (checkedChange)="onSecondaryParentChanged($event)">
      </nb-checkbox>
      <span>Customers</span>
      <nb-icon nbSuffix icon="chevron-right-outline" *ngIf="!secondaryParentExpanded"></nb-icon>
      <nb-icon nbSuffix icon="chevron-down-outline" *ngIf="secondaryParentExpanded"></nb-icon>
    </div>
    <div *ngIf="isMultiFacility && secondaryParentExpanded">
      <div *ngFor="let v of secondaryRows$ | async; trackBy: trackBy" [class]="'level-'+(v.level + (isMultiFacility ? 1 : 0))" (click)="toggle(v)"
        class="row">
        <nb-checkbox [disabled]="isProxy && v.expandable" [indeterminate]="v.indeterminate" [checked]="v.selected" (checkedChange)="onChanged(v, $event)">
        </nb-checkbox>
        <span> {{ v.title }} </span>
        <nb-icon nbSuffix icon="chevron-right-outline"
          *ngIf="showExpandIndicator && v.expandable && !v.expanded"></nb-icon>
        <nb-icon nbSuffix icon="chevron-down-outline"
          *ngIf="showExpandIndicator && v.expandable && v.expanded "></nb-icon>
      </div>
      <div (click)="loadMore()" class="load-more" *ngIf="canLoadMore$ | async">load more</div>
    </div>
  </div>
</ng-template>
