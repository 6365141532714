import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Workspace } from '../@core/interfaces/common/workspace';
import { select_selectedWorkspace } from '@/store/workspace/workspace.selectors';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class MasterFieldDisplayNameService {
  workspace?: Workspace;
  private subWorkspace?: Subscription;

  constructor(private readonly store: Store) {
    this.subWorkspace = this.store
      .select(select_selectedWorkspace)
      .subscribe((workspace: Workspace) => {
        this.workspace = workspace;
      });
  }

  // TODO: to be truly safe against all edge case, we should return an observable or a promise here, which should resolve only when the workspace sub resolves too.
  // But that will make usage a bit cumbersome, and since the dependencies is on the workspace, this should also be a general requirement for the workspace data to be loaded
  // before showing any UI components
  getCustomisedMasterDataFieldNames(originalFieldName) {
    let customisedFieldNames = this.workspace?.settings?.masterDataFieldDisplayNames;
    if (customisedFieldNames) {
      return customisedFieldNames[originalFieldName] ? customisedFieldNames[originalFieldName] : originalFieldName;
    }
    return originalFieldName;
  }

  ngOnDestroy(): void {
    this.subWorkspace?.unsubscribe();
  }
}
