import { createReducer, on } from '@ngrx/store';
import { PlanSetting } from '../../app/@core/interfaces/business/plan-setting';
import {
  ADD_DISPLAYED_SCENARIO_FAILED,
  ADD_DISPLAYED_SCENARIO_SUCCESS,
  CHANGED_DISPLAYED_DATERANGE_SUCCESS,
  CHANGED_DISPLAYED_SCENARIO_SUCCESS,
  CHANGED_USING_Y2Y_SUCCESS,
  CHANGED_DISPLAYED_DATERANGE_FAILED,
  CHANGED_DISPLAYED_SCENARIO_FAILED,
  CHANGED_USING_Y2Y_FAILED,
  DELETE_DISPLAYED_SCENARIO_FAILED,
  DELETE_DISPLAYED_SCENARIO_SUCCESS,
  HIDE_DISPLAYED_SCENARIO_FAILED,
  HIDE_DISPLAYED_SCENARIO_SUCCESS,
  LOAD_PLAN_SETTING,
  LOAD_PLAN_SETTING_FAILED,
  LOAD_PLAN_SETTING_SUCCESS,
  CHANGED_COMPARE_MODE_SUCCESS,
  CHANGED_COMPARE_MODE_FAILED,
  CHANGED_SELECTED_KPIS_SUCCESS,
  CHANGED_SELECTED_KPIS_FAILED,
} from './planSetting.actions';
import { SimpleDateRange } from '@/app/@core/interfaces/common/date-range';
import { BREAKDOWN_KPIS_GROUPING_INFO_LIST } from '@/app/@core/interfaces/business/breakdown';

export const PLAN_SETTING_STATE_KEY = 'plan-setting';

/*************************************
 * State
 *************************************/

export interface IPlanSettingState {
  planSetting?: PlanSetting;
  loading: boolean;
  saving: boolean;
  errors: string[];
  updatedScenario?: any;
  displayedDateRange?: SimpleDateRange;
  usingY2Y: boolean;
  compareMode?: string;
  selectedKpis?: any[];
}

export const initialState: IPlanSettingState = {
  loading: false,
  saving: false,
  errors: [],
  updatedScenario: [],
  displayedDateRange: { start: '', end: '' },
  usingY2Y: false,
  compareMode: 'contribution',
  selectedKpis: BREAKDOWN_KPIS_GROUPING_INFO_LIST
};

/*************************************
 * Reducers
 *************************************/

export const planSettingReducer = createReducer(
  initialState,
  on(LOAD_PLAN_SETTING, (state: IPlanSettingState): IPlanSettingState => ({ ...state, loading: true })),
  on(
    LOAD_PLAN_SETTING_SUCCESS,
    (state, { planSetting }): IPlanSettingState => ({ ...state, loading: false, planSetting }),
  ),

  on(
    LOAD_PLAN_SETTING_FAILED,
    (state, { error }): IPlanSettingState => ({
      ...state,
      loading: false,
      planSetting: {
        id: '',
        planRef: '',
        userId: '',
        displayedScenarios: [],
        displayedStartDate: '',
        displayedEndDate: '',
        usingY2Y: false,
        compareMode: 'contribution',
        selectedKpis: [],
      },
      errors: error,
    }),
  ),
  on(
    ADD_DISPLAYED_SCENARIO_SUCCESS,
    HIDE_DISPLAYED_SCENARIO_SUCCESS,
    DELETE_DISPLAYED_SCENARIO_SUCCESS,
    CHANGED_DISPLAYED_SCENARIO_SUCCESS,
    (state, { updatedScenario }): IPlanSettingState => ({ ...state, saving: false, updatedScenario }),
  ),
  on(
    CHANGED_DISPLAYED_DATERANGE_SUCCESS,
    (state, { displayedDateRange }): IPlanSettingState => ({ ...state, saving: false, displayedDateRange }),
  ),
  on(
    CHANGED_USING_Y2Y_SUCCESS,
    (state, { usingY2Y }): IPlanSettingState => ({ ...state, saving: false, usingY2Y: usingY2Y as boolean }),
  ),
  on(
    CHANGED_COMPARE_MODE_SUCCESS,
    (state, { compareMode }): IPlanSettingState => ({ ...state, saving: false, compareMode }),
  ),
  on(
    CHANGED_SELECTED_KPIS_SUCCESS,
    (state, { planSetting }): IPlanSettingState => ({ ...state, saving: false, planSetting }),
  ),
  on(
    ADD_DISPLAYED_SCENARIO_FAILED,
    HIDE_DISPLAYED_SCENARIO_FAILED,
    DELETE_DISPLAYED_SCENARIO_FAILED,
    CHANGED_DISPLAYED_SCENARIO_FAILED,
    CHANGED_DISPLAYED_DATERANGE_FAILED,
    CHANGED_USING_Y2Y_FAILED,
    CHANGED_COMPARE_MODE_FAILED,
    CHANGED_SELECTED_KPIS_FAILED,
    (state): IPlanSettingState => ({ ...state, saving: false }),
  ),
  on(
    CHANGED_DISPLAYED_SCENARIO_SUCCESS,
    (state, { planSetting }): IPlanSettingState => ({ ...state, loading: false, planSetting }),
  ),
);
