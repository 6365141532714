import { Injectable } from '@angular/core';
import { Ability, AbilityBuilder, AbilityClass, ExtractSubjectType } from '@casl/ability';

export type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete';
export type Subjects = 'all' | 'Plan' | 'Scenario' | 'SIT' | 'Event';

export type AppAbility = Ability<[Actions, Subjects]>;

@Injectable({
  providedIn: 'root'
})
export class AbilityService {
  private ability: AppAbility;

  constructor() {
    this.ability = new Ability<[Actions, Subjects]>();
    this.defineAbilitiesFor('user');
  }

  public defineAbilitiesFor(role: string) {
    const { can, rules } = new AbilityBuilder(Ability as AbilityClass<AppAbility>);

    if (role === 'admin') {
      can('manage', 'all'); // read-write access to everything
    } else {
      can('read', 'all'); // read-only access to articles
    }

    this.ability.update(rules);
  }

  public getAbility() {
    return this.ability;
  }
}