import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/internal/Subscription';
import { Workspace } from '../@core/interfaces/common/workspace';
import { select_selectedWorkspace } from '@/store/workspace/workspace.selectors';

@Pipe({
  name: 'masterFieldDisplayName',
})
export class MasterFieldDisplayNamePipe implements PipeTransform {
  transform(input: string, customisedFieldNames, namesNotToCustomise: string[] = []): string {
    if (customisedFieldNames) {
      if (namesNotToCustomise.includes(input)) {
        return input;
      }
      return customisedFieldNames[input] ? customisedFieldNames[input] : input;
    }
    return input;
  }
}
