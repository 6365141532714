import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule } from '@nebular/auth';

import { SimulationService } from './simulation.service';
import { PlanImportService } from './plan-import.service';
import { PlanService } from './plan.service';
import { PricingGenerationService } from './pricing-generation.service';
import { TaskService } from './task.service';
import { DemandGenerationService } from './demand-generation.service';
import { EtlService } from './etl.service';
import { SupplyPlanService } from '../../entity/supplyPlan.service';

const SERVICES = [
  PricingGenerationService,
  PlanImportService,
  PlanService,
  SimulationService,
  TaskService,
  DemandGenerationService,
  EtlService,
  SupplyPlanService,
];

@NgModule({
  imports: [CommonModule, NbAuthModule],
})
export class SimcelBackendModule {
  static forRoot(): ModuleWithProviders<SimcelBackendModule> {
    return { ngModule: SimcelBackendModule, providers: SERVICES };
  }
}
