import { createAction, props } from '@ngrx/store';
import type { Event, EventVersion } from '@/app/@core/interfaces/business/event';

export const LOAD_EVENTS_INITIATED = createAction(
  '[🤖 Event API] Load Events Initiated'
);
export const LOAD_EVENTS = createAction(
  '[⚡ Event API] Load Events',
  props<{ startDate: string; endDate: string }>(), // TODO: add workspace
);
export const LOAD_EVENTS_SUCCESS = createAction(
  '[🤖 Event API] Load Event success',
  props<{ data: Event[] }>(),
);
export const LOAD_EVENTS_INCREMENTAL_COMPLETE = createAction(
  '[🤖 Event API] Load Events Incremental Complete',
);
export const LOAD_EVENTS_FAILED = createAction(
  '[🤖 Event API] Load Event failed',
  props<{ error: any }>(),
);

/** Create / save an event version record (depends if _id exists). */
export const SAVE_EVENT_VERSION = createAction(
  '[🤖 EventManage Page] Save EventVersion',
  props<{ event: Event; version: EventVersion }>(),
);

export const SAVE_EVENT_VERSION_SUCCESS = createAction(
  '[🤖 Event API] Save EventVersion success',
  props<{ data: EventVersion }>(),
);
export const SAVE_EVENT_VERSION_FAILED = createAction(
  '[🤖 Event API] Save EventVersion failed',
  props<{ error: any }>(),
);

export const DELETE_EVENT_VERSION_SUCCESS = createAction(
  '[🤖 Event API] Delete EventVersion success',
);
export const DELETE_EVENT_VERSION_FAILED = createAction(
  '[🤖 Event API] Delete EventVersion failed',
  props<{ error: any }>(),
);

export const TOGGLE_COLLAPSE_EVENT_LIST = createAction(
  '[🤖 Event API] Toggle collapse event list',
  props<{ isCollapsed: boolean }>(),
);
