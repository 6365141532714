import { Actual } from '@/app/@core/interfaces/business/actual';
import { createAction, props } from '@ngrx/store';

export const LOAD_ACTUALS = createAction(
  '[⚡ Actual API] Load Actuals',
  props<{ workspaceID: string }>()
);
export const LOAD_ACTUALS_SUCCESS = createAction(
  '[🤖 Actual API] Load Actuals success',
  props<{ data: Actual[] }>(),
);
export const LOAD_ACTUALS_FAILED = createAction(
  '[🤖 Actual API] Load Actuals failed',
  props<{ error: any }>(),
);
