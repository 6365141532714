<div class="footer-container">
  <div class="created-by">
    <span>© {{ currentYear }} by <a href="https://www.simcel.io/" target="_blank">CEL</a></span>
  </div>

  <div class="socials">
    <a
      href="https://www.facebook.com/CELConsultingVietnam/"
      target="_blank"
      class="ion ion-social-facebook"
    ></a>
    <a href="https://twitter.com/CEL_SCM" target="_blank" class="ion ion-social-twitter"></a>
  </div>
</div>
