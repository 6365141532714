export interface ProductCriterion {
  brand?: string;
  productFamily?: string;
  groups?: string[];
  productId?: string;

  // TODO: Consider deletion / refactoring. These are old props.
  category?: string;
  brands?: string;
  productRange?: string;
  subCategory?: string;
  status?: string;
  // productIds: string[];
}

export interface CustomerCriterion {
  country?: string;
  city?: string;
  channel?: string;
  groups?: string[];
  ref?: string;
}

export interface LocationCriterion {
  country?: string[];
  region?: string[];
}

export class SegmentCriterion {
  products?: ProductCriterion[];
  customers?: CustomerCriterion[];
  locations?: LocationCriterion[];
  distributors?: CustomerCriterion[];
}

export interface MatchingEntities {
  productIds: string[];
  customerRefs: string[];
}

export interface SegmentFilter {
  [key: string]: string[];
}

export const enum SegmentType {
  Filter = 'filter',
  Event = 'event',
}

/** Defines a segment that specifies the impact of an event. */
export class Segment {
  id?: string;
  formatVersion?: string;
  name?: string;
  type?: SegmentType;
  customer?: SegmentFilter[];
  product?: SegmentFilter[];
  location?: SegmentFilter[];
  distributor?: SegmentFilter[];
  dc?: SegmentFilter[];
  customerType?: 'customer' | 'distributor';

  // event segment
  segmentCriteria?: SegmentCriterion[];
  segmentCriteriaTable?: { [key: string]: SegmentCriterion[] };
  matchingEntities?: MatchingEntities[];

  public toString = (): string => {
    const segment = {
      formatVersion: this.formatVersion,
      name: this.name,
      type: this.type,
      location: this.location,
      product: this.product,
      customer: this.customer,
      distributor: this.distributor,
      dc: this.dc
    }

    return JSON.stringify(segment)
  }
}

export const DEFAULT_SEGMENT: Segment = {
  formatVersion: '1',
  name: '',
  type: SegmentType.Filter,
  location: [],
  product: [],
  customer: [],
  distributor: [],
  dc: [],
};
