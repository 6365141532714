import { Workspace } from '@/app/@core/interfaces/common/workspace';
import { MasterFieldDisplayNamePipe } from '@/app/shared/master-field-display-name.pipe';
import { MasterFieldDisplayNameService } from '@/app/shared/master-field-display-name.service';
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnChanges, SimpleChanges  } from '@angular/core';
import { debounceTime, lastValueFrom, mergeMap, of, shareReplay, startWith, Subject, switchMap, Observable } from 'rxjs';
import { SegmentAdapter } from './segment-select-box.component';

interface Option {
  type: string;
  value: string;
  ref?: string;
}

@Component({
  selector: 'cel-entity-select-box',
  templateUrl: './entity-select-box.component.html',
  styleUrls: ['./entity-select-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntitySelectBoxComponent implements OnChanges {
  constructor(public displayNameService: MasterFieldDisplayNameService) {
    this.options$ = this.term$.pipe(
      startWith(''),
      debounceTime(250),
      mergeMap((term) => this.adapter.search(term, this.entity, this.customerType)),
      shareReplay(1),
    );
  }

  options: Option[] = [];
  @Input() workspace?: Workspace;

  @Input() adapter: SegmentAdapter = {
    search: (_term: string, _entity: string, _customerType: string) => of([]),
  };

  @Input() boxHeight: string = '';

  @Input() entity = '';
  term$ = new Subject<string>();
  @Output() update = new EventEmitter<Option>();
  @Output() add = new EventEmitter<void>();
  @Input() customerType: string = 'customer';

  options$: Observable<Option[]>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customerType) {
      // Refetch autocomplete data when customerType changes
      this.updateOptions();
    }
  }

  private updateOptions(): void {
    this.options$ = this.term$.pipe(
      startWith(''),
      debounceTime(250),
      mergeMap((term) => this.adapter.search(term, this.entity, this.customerType)),
      shareReplay(1),
    );
  }

  onSelect(option: Option) {
    this.update.next(option);
  }

  addNewSearch() {
    this.add.next();
  }
}
