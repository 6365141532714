import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { lastValueFrom, Observable } from 'rxjs';
import { HttpService } from '../backend/common/api/http.service';
import { Actual } from '../interfaces/business/actual';

import { refId } from '../interfaces/common/mongo';

@Injectable({ providedIn: 'root' })
export class ActualService {
    private readonly apiController: string = 'explorer/actual';
    constructor(private readonly api: HttpService) { }

    getActuals(workspaceID: string): Observable<Actual[]> {
        return this.api.get(`${this.apiController}/get-actuals`, { params: { workspaceID: workspaceID } });
    }
}
