import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'cel-warning-dialog',
  templateUrl: './delete-plan-warning-dialog.component.html',
  styles: [
    `
      nb-card {
        width: 600px;
        max-width: calc(100vw - 80px);
      }
      nb-card-footer {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
      }
    `,
  ],
})
export class DeletePlanWarningDialog {
  @Input() latestPlanName: string | undefined = '';
  @Input() onSubmit: (ref: NbDialogRef<DeletePlanWarningDialog>) => void = () => {};

  constructor(protected ref: NbDialogRef<DeletePlanWarningDialog>) { }

  dismiss() {
    this.ref.close();
  }

  submit() {
    this.onSubmit(this.ref);
  }
}
