import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Event } from '../interfaces/business/event';

@Injectable({ providedIn: 'root' })
export class EventService extends EntityCollectionServiceBase<Event> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super('explorer/events', serviceElementsFactory);
  }

  getEventsWithPageCount(query: any): Observable<{ events: Event[], pageCount: number }> {
    return super.getWithQuery(query).pipe(
      map((response: any) => {
        return {
          events: response[0]?.events as Event[] || [],
          pageCount: response[0]?.pageCount as number || 0,
        };
      })
    );
  }

  add(event: Event) {
    return super.add(this.cleanEvent(event));
  }

  update(event: Event) {
    return super.update(this.cleanEvent(event));
  }

  // strip objects that only exists on the frontend
  private cleanEvent(event: Event) {
    const eventObject: Event = { ...event, versions: [] };
    for (const version of event.versions) {
      // skip empty version id
      // the event service remove all versions if one of them has empty id
      if (version.id) {
        eventObject.versions.push(version);
      }
    }
    return eventObject;
  }
}
