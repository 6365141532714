import { Event, EventVersion } from '@/app/@core/interfaces/business/event';
import {
  DELETE_EVENT_VERSION_SUCCESS, SAVE_EVENT_VERSION_SUCCESS
} from '@/store/event/event.actions';
import { createReducer, on } from '@ngrx/store';
import {
  CANCEL_EVENT_VERSION_EDIT,
  CREATE_NEW_EVENT_VERSION_LOCALLY,
  CREATE_NEW_EVENT_VERSION_LOCALLY_SUCCESS,
  BEGIN_EDIT_EVENT_VERSION,
  VIEW_EVENT,
  PRESET_EVENT_VERSION_DATE,
  LOAD_ACTIVITIES,
  LOAD_ACTIVITIES_SUCCESS,
  LOAD_ACTIVITIES_FAILED,
  SET_TRADE_TERM_GROUPS
} from './event-management.actions';
import { Activity } from '@/app/@core/interfaces/business/activity';

export const PAGE__EVENTS_MANAGEMENT_STATE_KEY = 'page_eventsManagement';

/*************************************
 * State
 *************************************/

export interface IEventsManagementPageState {
  selectedEvent: Event | null;
  selectedEventVersion: EventVersion | null;
  presetStartDate: Date | null;
  activities: Activity[];
  isActivityLoading: boolean; 
  tradeTermGroups: string[];
}

export const initialState: IEventsManagementPageState = {
  selectedEvent: null,
  selectedEventVersion: null,
  presetStartDate: null,
  activities: [],
  isActivityLoading: false,
  tradeTermGroups: [],
};

/*************************************
 * Reducers
 *************************************/

export const page_eventsManagementReducer = createReducer(
  initialState,
  on(
    CREATE_NEW_EVENT_VERSION_LOCALLY_SUCCESS,
    (state, { version }): IEventsManagementPageState => ({
      ...state,
      selectedEventVersion: version,
    }),
  ),
  on(
    VIEW_EVENT,
    BEGIN_EDIT_EVENT_VERSION,
    (state, { event, version }): IEventsManagementPageState => ({
      ...state,
      selectedEvent: event,
      selectedEventVersion: version,
    }),
  ),
  on(
    CREATE_NEW_EVENT_VERSION_LOCALLY,
    CANCEL_EVENT_VERSION_EDIT,
    SAVE_EVENT_VERSION_SUCCESS,
    (state): IEventsManagementPageState => ({
      ...state,
      selectedEvent: null,
      selectedEventVersion: null,
    }),
  ),
  on(
    DELETE_EVENT_VERSION_SUCCESS,
    (state): IEventsManagementPageState => ({ ...state, selectedEventVersion: null }),
  ),
  on(
    PRESET_EVENT_VERSION_DATE,
    (state, { startDate }): IEventsManagementPageState => ({ ...state, presetStartDate: startDate }),
  ),
  on(
    LOAD_ACTIVITIES,
    (state): IEventsManagementPageState => ({
      ...state,
      isActivityLoading: true,
    }),
  ),
  on(
    LOAD_ACTIVITIES_SUCCESS,
    (state, { activities }): IEventsManagementPageState => ({
      ...state,
      activities,
      isActivityLoading: false,
    }),
  ),
  on(
    LOAD_ACTIVITIES_FAILED,
    (state): IEventsManagementPageState => ({
      ...state,
      isActivityLoading: false,
    }),
  ),
  on(
    SET_TRADE_TERM_GROUPS,
    (state, { tradeTermGroups }): IEventsManagementPageState => ({
      ...state,
      tradeTermGroups,
    })
  ),
);
