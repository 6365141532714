import tinycolor from 'tinycolor2';
import { BREAKDOWN_KPIS_INFO_LIST, Breakdown, BREAKDOWN_FINANCE_KPIS_INFO_LIST, kpiOf } from '@/app/@core/interfaces/business/breakdown';
import { transformInputToKpiFormat } from '@/app/pipes/kpi-formatting.pipe';
import { PNL_EVOLUTION_FINANCE_KPIS, PNL_EVOLUTION_KPIS, filterPnlHiddenKpis } from './breakdown.constants';
import { Scenario, ScenarioFlag } from '@/app/@core/interfaces/business/scenario';

const BLANK_SERIES_NAME = '_blank';

/** Create a pair of series data for a waterfall chart pluggable for echarts data. */
export function createWaterfallSeries(
  scenario?: Scenario,
  scenarioBreakdown?: Breakdown,
  actualBreakdown?: Breakdown,
  color?: string,
  isHighlighted = false,
  scenarioIndex = 0,
  numOfScenarios = 1,
  hiddenKpis: string[] = [],
  displayFinanceKpis: boolean = false,
) {
  const lightColor = tinycolor(color);
  lightColor.setAlpha(0.5);

  const data = createWaterfallSeriesData(scenario, scenarioBreakdown, actualBreakdown, hiddenKpis, displayFinanceKpis);
  let lastEndValue = 0;

  const pnlEvolutionKpis = displayFinanceKpis ? PNL_EVOLUTION_FINANCE_KPIS : PNL_EVOLUTION_KPIS;
  const breakdownKpis = displayFinanceKpis ? [...BREAKDOWN_KPIS_INFO_LIST, ...BREAKDOWN_FINANCE_KPIS_INFO_LIST] : BREAKDOWN_KPIS_INFO_LIST;

  const result = [
    // Specifies the blank area below each data point in the waterfall chart. Note
    // that all offsets here can be derived from the mockup at:
    // https://www.figma.com/file/JqjqLZ0XOgDtK2xwWc0d2U/Simcel?node-id=903%3A1436
    // {
    //   name: BLANK_SERIES_NAME,
    //   type: 'bar',
    //   stack: scenario?.id,
    //   itemStyle: { borderColor: 'rgba(0,0,0,0)', color: 'rgba(0,0,0,0)' },
    //   emphasis: { itemStyle: { borderColor: 'rgba(0,0,0,0)', color: 'rgba(0,0,0,0)' } },
    //   data: createWaterfallPaddingData(scenario, scenarioBreakdown, actualBreakdown, hiddenKpis, displayFinanceKpis),
    // },
    // Specifies the filled areas on top of the blank areas in the waterfall chart.
    {
      name: scenario?.name,
      type: 'custom',
      stack: scenario?.id,
      color: scenario?.color || '#0C80EB',
      data: data,
      renderItem: (params, api) => {
        const dataIndex = api.value(0);
        const kpiName = filterPnlHiddenKpis(pnlEvolutionKpis, hiddenKpis)[dataIndex];
        const kpiInfo = breakdownKpis.find(info => info.shortName === kpiName);

        const rectMinHeight = 0;
        const style = api.style();
        style.fill = lightColor.toString();
        const borderWidth = 2;
        const borderRadius = 4;

        const totalWidth = 100;
        const gapWidth = 8;
        const availableWidth = totalWidth - (numOfScenarios - 1) * gapWidth;
        const barWidth = availableWidth / numOfScenarios;

        let rectHeight = 0;
        let startCoord = [0, 0];
        let endCoord = [0, 0];
        let barStartValue = 0;
        let barEndValue = 0;

        // Totals (type = profit) - bar goes from 0 to the last Lost's ending price
        // Costs (type = lost) - bar goes down from the last Profit/Lost's ending price
        if (kpiInfo && kpiInfo.type === 'profit') {
          const prevKpiName = filterPnlHiddenKpis(pnlEvolutionKpis, hiddenKpis)[dataIndex - 1];
          const prevKpiInfo = breakdownKpis.find(info => info.shortName === prevKpiName);
          if (prevKpiInfo && prevKpiInfo.type === 'lost') {
            barStartValue = 0;
            barEndValue = lastEndValue; // absolute value of barEndValue equals to api.value(1);
          } else {
            barStartValue = 0;
            barEndValue = api.value(1);
          }
        } else {
          const prevKpiName = filterPnlHiddenKpis(pnlEvolutionKpis, hiddenKpis)[dataIndex - 1];
          const prevKpiInfo = breakdownKpis.find(info => info.shortName === prevKpiName);
          if (prevKpiInfo && prevKpiInfo.type === 'profit') {
            barStartValue = data[dataIndex - 1]!;
            barEndValue = data[dataIndex - 1]! - api.value(1);
          } else {
            barStartValue = lastEndValue;
            barEndValue = lastEndValue - api.value(1);
          }
        }

        lastEndValue = barEndValue;
        startCoord = api.coord([dataIndex, barStartValue]);
        endCoord = api.coord([dataIndex, barEndValue]);
        rectHeight = startCoord[1] - endCoord[1];
        rectHeight = rectHeight === 0 ? rectMinHeight : rectHeight;

        const roundRectItem = {
          type: 'path',
          shape: {
            pathData: createRoundRectPath(
              endCoord[0] - totalWidth / 2 + scenarioIndex * (barWidth + gapWidth),
              endCoord[1],
              barWidth,
              rectHeight,
              borderRadius
            ),
          },
          style: {
            fill: lightColor.toString(),
            stroke: isHighlighted ? color : null,
            lineWidth: borderWidth,
          },
        };
        return roundRectItem;
      },
    },
  ];
  return result;
}

// The path format in this function is related to SVG path data.
// The function createRoundRectPath generates a string containing SVG path commands to create a rounded rectangle.
// This path data can be used as the d attribute for an SVG path element to draw the desired shape.
// Reference: https://echarts.apache.org/en/option.html#series-custom.renderItem
function createRoundRectPath(x, y, width, height, borderRadius) {
  if (Math.abs(height) <= 1) {
    return `M${x},${y}` +
      `h${width}`;
  }

  borderRadius = Math.min(borderRadius, Math.abs(height) / 2);

  if (height < 0) {
    return `M${x + borderRadius},${y}` +
      `h${width - 2 * borderRadius}` +
      `a${borderRadius},${borderRadius} 0 0,0 ${borderRadius},-${borderRadius}` +
      `v${height + 2 * borderRadius}` +
      `a${borderRadius},${borderRadius} 0 0,0 -${borderRadius},-${borderRadius}` +
      `h${-width + 2 * borderRadius}` +
      `a${borderRadius},${borderRadius} 0 0,0 -${borderRadius},${borderRadius}` +
      `v${-height - 2 * borderRadius}` +
      `a${borderRadius},${borderRadius} 0 0,0 ${borderRadius},${borderRadius}Z`;
  } else {
    return `M${x + borderRadius},${y}` +
      `h${width - 2 * borderRadius}` +
      `a${borderRadius},${borderRadius} 0 0,1 ${borderRadius},${borderRadius}` +
      `v${height - 2 * borderRadius}` +
      `a${borderRadius},${borderRadius} 0 0,1 -${borderRadius},${borderRadius}` +
      `h${-width + 2 * borderRadius}` +
      `a${borderRadius},${borderRadius} 0 0,1 -${borderRadius},-${borderRadius}` +
      `v${-height + 2 * borderRadius}` +
      `a${borderRadius},${borderRadius} 0 0,1 ${borderRadius},-${borderRadius}Z`;
  }
}

/** Tooltip formatter for a series pair. Excludes series with name = '_blank'. */
export function seriesPairFormatterFactory(
  highlightedScenarioName: string | undefined,
  highlightedWaterfallSeriesData: (number | null)[],
  kpiTypes: string[],
  acceptedScale?: string[],
  decimalPlaces?: number,
  numericScale?: string,
) {
  return (
    params: Array<{ seriesName: string; value: number; dataIndex: number; marker: string }>,
  ): string => {
    if (params.length === 0) {
      return 'No data';
    }

    const content = params
      .filter((param) => param.seriesName !== BLANK_SERIES_NAME)
      .map((param) => {
        const value = transformInputToKpiFormat(param.value, '-', acceptedScale, decimalPlaces || 0, numericScale || '');
        let diffPercentageInString = '';
        let fontWeight = 'bold';
        let color = '';

        /** Add diff value to the tooltips of non-highlighted scenarios.*/
        if (param.seriesName !== highlightedScenarioName) {
          const highlightedValue = highlightedWaterfallSeriesData[param.dataIndex];
          if (highlightedValue) {
            const diffPercentage = ((param.value - highlightedValue) / highlightedValue) * 100;
            color = generateDiffColor(diffPercentage, kpiTypes[param.dataIndex]);

            fontWeight = 'normal';
            const sign = diffPercentage > 0 ? '+' : '';
            diffPercentageInString = `(${sign}${diffPercentage.toFixed(1)}%)`;
          }
        }

        return `${param.marker} <span style="color:#484848; font-weight:${fontWeight}">${value}</span> 
        <span style="padding-left:20px; font-weight:bold; color:${color}">${diffPercentageInString}</span>`;
      })
      .join('<br/>');
    return content;
  };
}

export function createWaterfallPaddingData(
  scenario?: Scenario,
  scenarioBreakdown?: Breakdown,
  actualBreakdown?: Breakdown,
  hiddenKpis: string[] = [],
  displayFinanceKpis: boolean = false,
): (number | null)[] {
  // If the scenario is an executed plans scenario, do not add actual to the scenario breakdown.
  const isActualPlans = scenario?.flags?.includes(ScenarioFlag.ACTUAL);
  const breakdown = isActualPlans ? actualBreakdown : scenarioBreakdown;
  if (!breakdown) return [];

  const pnlEvolutionKpis = displayFinanceKpis ? PNL_EVOLUTION_FINANCE_KPIS : PNL_EVOLUTION_KPIS;
  return filterPnlHiddenKpis(pnlEvolutionKpis, hiddenKpis).map((name) => {
    switch (name) {
      case 'tradeExpenses':
        const discount = kpiOf(breakdown, 'tradeExpenses') || 0;
        if (discount >= 0) {
          return kpiOf(breakdown, 'netSalesValue');
        } else {
          return kpiOf(breakdown, 'grossSalesValue');
        }
      case 'cogs':
        return (kpiOf(breakdown, 'netSalesValue') || 0) - (kpiOf(breakdown, 'cogs') || 0);
      case 'transferCost':
        return (
          (kpiOf(breakdown, 'netSalesValue') || 0) -
          (kpiOf(breakdown, 'cogs') || 0) -
          (kpiOf(breakdown, 'transferCost') || 0)
        );
      case 'distributionCost':
        return kpiOf(breakdown, 'cbm');
      default:
        return 0;
    }
  });
}

export function createWaterfallSeriesData(
  scenario?: Scenario,
  scenarioBreakdown?: Breakdown,
  actualBreakdown?: Breakdown,
  hiddenKpis: string[] = [],
  displayFinanceKpis: boolean = false,
): (number | null)[] {
  const pnlEvolutionKpis = displayFinanceKpis ? PNL_EVOLUTION_FINANCE_KPIS : PNL_EVOLUTION_KPIS;
  // For actual scenario, we use the actual breakdown data
  if (scenario?.flags?.includes(ScenarioFlag.ACTUAL)) {
    if (!actualBreakdown) return [];

    return filterPnlHiddenKpis(pnlEvolutionKpis, hiddenKpis).map((name) => kpiOf(actualBreakdown, name));
  }
  if (!scenarioBreakdown) return [];

  // const merged = mergeBreakdowns(actualBreakdown, scenarioBreakdown);
  // Otherwise, we use scenario breakdown data
  return filterPnlHiddenKpis(pnlEvolutionKpis, hiddenKpis).map((name) => {
    const value = kpiOf(scenarioBreakdown, name) || 0;
    if (value < 0) {
      return -value;
    }
    return value;
  });
}

export function generateDiffColor(value: string | number, kpiType: string): string {
  if (typeof value === 'string') value = +value;
  const greenColor = '#028647';
  const redColor = '#FE4B48';

  if (kpiType === 'profit') {
    if (value > 0) {
      return greenColor;
    }

    return redColor;
  }

  if (value > 0) {
    return redColor;
  }

  return greenColor;
}
