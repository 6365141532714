import { createFeatureSelector, createSelector } from '@ngrx/store';
import { addMonths, addQuarters, isBefore, min, max, sub } from 'date-fns';

import { select_events } from '@/store/event/event.selectors';
import { select_selectedPlan } from '../layout/layout.selectors';
import {
  IEventsManagementPageState,
  PAGE__EVENTS_MANAGEMENT_STATE_KEY,
} from './event-management.state';
import { parseYYYYMMDD } from '@/utils/calendar';
import { select_soonestActual } from '@/store/actual/actual.selectors';

const selectFeature = createFeatureSelector<IEventsManagementPageState>(
  PAGE__EVENTS_MANAGEMENT_STATE_KEY,
);

export const select_selectedEventVersion = createSelector(
  selectFeature,
  (state) => state.selectedEventVersion,
);

// export const select_selectedEvent = createSelector(
//   select_selectedEventVersion,
//   select_events,
//   (selectedEventVersion, events) =>
//     events.find((e) => e.versions.some((ev) => ev.id === selectedEventVersion?.id)),
// );

export const select_selectedEvent = createSelector(
  selectFeature,
  (state) => state.selectedEvent,
);

export const select_activities = createSelector(
  selectFeature,
  (state) => state.activities,
);

export const select_isActivityLoading = createSelector(
  selectFeature,
  (state) => state.isActivityLoading,
);

export const select_presetStartDate = createSelector(
  selectFeature,
  (state) => state.presetStartDate,
);

export const select_tradeTermGroups = createSelector(
  selectFeature,
  (state) => state.tradeTermGroups,
);

export const select_presettedDateRanges = createSelector(
  select_selectedPlan,
  select_presetStartDate,
  (selectedPlan, selectedPresetStartDate): Record<string, Array<Date>> => {
    if (!selectedPlan) return {};
    const plan_start = parseYYYYMMDD(selectedPlan.defaultPlanDisplayStartDate);
    const plan_end = parseYYYYMMDD(selectedPlan.defaultPlanDisplayEndDate);

    let oneMonthStart = plan_start
    let oneMonthEnd = sub(addMonths(oneMonthStart, 1), { days: 1 });

    let threeMonthsStart = plan_start
    let threeMonthsEnd = sub(addMonths(threeMonthsStart, 3), { days: 1 });

    let sixMonthsStart = plan_start
    let sixMonthsEnd = sub(addMonths(sixMonthsStart, 6), { days: 1 });

    if (selectedPresetStartDate) {
      oneMonthStart = max([selectedPresetStartDate, oneMonthStart]);
      oneMonthEnd = sub(addMonths(oneMonthStart, 1), { days: 1 });

      threeMonthsStart = max([selectedPresetStartDate, threeMonthsStart]);
      threeMonthsEnd = sub(addMonths(threeMonthsStart, 3), { days: 1 });

      sixMonthsStart = max([selectedPresetStartDate, sixMonthsStart]);
      sixMonthsEnd = sub(addMonths(sixMonthsStart, 6), { days: 1 });
    }

    return {
      'Entire Plan': [plan_start, plan_end],
      'One Month': [oneMonthStart, min([oneMonthEnd, plan_end])],
      'Three Months': [threeMonthsStart, min([threeMonthsEnd, plan_end])],
      'Six Months': [sixMonthsStart, min([sixMonthsEnd, plan_end])],
    };
  },
);

export const select_disabledDateRangeFn = createSelector(
  select_soonestActual,
  (soonestActual): ((current: Date) => boolean) => {
    if (!soonestActual) return () => false;
    const actualStart = new Date(soonestActual.actualStartDate);
    const startOfMonth = new Date(actualStart.getFullYear(), actualStart.getMonth(), 1);

    return (d: Date) => isBefore(d, startOfMonth);
  },
);
