<nb-card class="impact-segement-card">
  <nb-card-body class="container-fluid">
    <div class="mb-4">
      <div class="trade-term-info-div">
        <div class="trade-term-flex-container col-md-4">
          <label class="trade-term-input-label for-make-title">Trade Term:</label>
          <nb-select
            class="trade-term-select-box"
            placeholder="Select Trade Term"
            [selected]="selectedTradeTerm"
            (selectedChange)="onSelectTradeTerm($event)"
            [disabled]="this.eventDetail"
          >
              <nb-option
                class="trade-term-option"
                *ngFor="let tradeTerm of tradeTermDatas"
                [disabled]="tradeTerm.disabled"
                [value]="tradeTerm.value"
                >{{ getTradeTermLabel(tradeTerm.value) }}
              </nb-option>
          </nb-select>
        </div>
        <div class="trade-term-flex-container col-md-6">
          <label class="trade-term-input-label for-make-title">Apply to:</label>
          <div class="option__item" *ngFor="let applyTo of applyToDatas; let i = index">
            <input
              type="radio"
              [value]="applyTo.value"
              [disabled]="this.eventDetail || applyTo.disabled"
              [checked]="selectedApplyTo == applyTo.value"
              (change)="onChangeApplyTo($event.target.value)"
            />
            <label>{{ getApplyToLabel(applyTo.value) }}</label>
          </div>
        </div>
      </div>
      <div class="trade-term-flex-container col-md-4 mt-2">
        <div style="display: flex; flex-direction: column; gap: 5px;">
          <label class="trade-term-input-label group-title">Trade Term Group 1:</label>
          <nz-select
            [nzDropdownRender]="renderTemplate"
            nzPlaceHolder="Select Trade Term Group"
            [nzDisabled]="this.eventDetail"
            [ngModel]="selectedTradeTermGroup1"
            (ngModelChange)="onSelectTradeTermGroup(1, $event)"
            [nzOptionHeightPx]="36"
          >
            <nz-option
              *ngFor="let group of tradeTermGroups"
              nzCustomContent
              [nzLabel]="group"
              [nzValue]="group"
            >
              <div class="option-content">
                <span>{{ group }}</span>
                <button
                  nbButton
                  ghost
                  size="small"
                  shape="round"
                  [nbPopover]="eventVersionContextMenu"
                  nbPopoverPlacement="bottom"
                  (click)="handleButtonClick($event, group)"
                >
                  <nb-icon icon="more-vertical-outline"></nb-icon>
                </button>
                <ng-template #eventVersionContextMenu>
                  <nb-list>
                    <nb-list-item class="scenario-btn -list-item" nbButton ghost (click)="openRenameGroup(renameGroup)">
                      <nb-icon class="scenario-btn -edit" icon="edit" pack="simcel-event-icons"></nb-icon>
                      <label class="scenario-btn -edit -label">Rename</label>
                    </nb-list-item>
                    <nb-list-item class="scenario-btn -list-item" nbButton ghost (click)="openConfirmDelete(confirmDeleteGroup)">
                      <nb-icon class="scenario-btn -edit" icon="copy-outline"></nb-icon>
                      <label class="scenario-btn -edit -label">Delete</label>
                    </nb-list-item>
                  </nb-list>
                </ng-template>
              </div>
            </nz-option>
          </nz-select>
          <ng-template #renderTemplate>
            <nz-divider></nz-divider>
            <div class="container">
              <input type="text" nz-input #inputElement />
              <a class="add-item" (click)="addItem(inputElement)">
                <span nz-icon nzType="plus"></span>
                Add item
              </a>
            </div>
          </ng-template>
        </div>
        <div style="display: flex; flex-direction: column; gap: 5px;">
          <label class="trade-term-input-label group-title">Trade Term Group 2:</label>
          <nz-select
            [nzDropdownRender]="renderTemplate"
            nzPlaceHolder="Select Trade Term Group"
            [nzDisabled]="this.eventDetail"
            [ngModel]="selectedTradeTermGroup2"
            (ngModelChange)="onSelectTradeTermGroup(2, $event)"
            [nzOptionHeightPx]="36"
          >
            <nz-option
              *ngFor="let group of tradeTermGroups"
              nzCustomContent
              [nzLabel]="group"
              [nzValue]="group"
            >
              <div class="option-content">
                <span>{{ group }}</span>
                <button
                  nbButton
                  ghost
                  size="small"
                  shape="round"
                  [nbPopover]="eventVersionContextMenu"
                  nbPopoverPlacement="bottom"
                  (click)="handleButtonClick($event, group)"
                >
                  <nb-icon icon="more-vertical-outline"></nb-icon>
                </button>
                <ng-template #eventVersionContextMenu>
                  <nb-list>
                    <nb-list-item class="scenario-btn -list-item" nbButton ghost (click)="openRenameGroup(renameGroup)">
                      <nb-icon class="scenario-btn -edit" icon="edit" pack="simcel-event-icons"></nb-icon>
                      <label class="scenario-btn -edit -label">Rename</label>
                    </nb-list-item>
                    <nb-list-item class="scenario-btn -list-item" nbButton ghost (click)="openConfirmDelete(confirmDeleteGroup)">
                      <nb-icon class="scenario-btn -edit" icon="copy-outline"></nb-icon>
                      <label class="scenario-btn -edit -label">Delete</label>
                    </nb-list-item>
                  </nb-list>
                </ng-template>
              </div>
            </nz-option>
          </nz-select>
          <ng-template #renderTemplate>
            <nz-divider></nz-divider>
            <div class="container">
              <input type="text" nz-input #inputElement />
              <a class="add-item" (click)="addItem(inputElement)">
                <span nz-icon nzType="plus"></span>
                Add item
              </a>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="mb-4">
      <div class="row align-items-center segment-style">
        <div class="col-md-1 for-make-title">For:</div>
        <div class="col-md-5 for-make-title">
          <strong *ngIf="count && eventType && ![EventType.INVENTORY_PARAMETERS, EventType.COGS].includes(eventType)">
            {{ count.product }} Products & {{ count.customer }} Customers Selected
          </strong>
          <strong *ngIf="count && eventType && [EventType.INVENTORY_PARAMETERS, EventType.COGS].includes(eventType)">
            {{ count.product }} Products 
          </strong>
          &nbsp; &nbsp; &nbsp;
          <nb-select *ngIf="isSelectSegmentVisible()"
            class="segment-select"
            placeholder="Use existing segments"
            (selectedChange)="useSavedSegment($event)">
            <nb-option [value]="null">None</nb-option>
            <nb-option *ngFor="let segment of savedSegmentsObs | async" [value]="segment">
              {{ segment.name }}
            </nb-option>
          </nb-select>
        </div>
      </div>

      <div class="segment-criterion-container">
        <cel-segment-select-box
          [customerType]="getCustomerType()"
          [eventType]="eventType"
          [value]="impact?.segment"
          [adapter]="adapter"
          (valueChanged)="updateSegment($event)"
          [eventDetail]="this.eventDetail"
          [isTradeTerm]="true"
          [selectedTradeTerm]="selectedTradeTerm"
        ></cel-segment-select-box>
      </div>
    </div>
    <div class="mb-4">
      <div class="col container center-content">
        <cel-discount-term
          *ngIf="eventType && [EventType.TRADE_TERMS].includes(eventType) && !isDemandImpact()"
          name="Demand"
          [isTradeTerm]="true"
          [selectedTradeTerm]="selectedTradeTerm"
          [dateRange]="dateRange"
          (tradeTermChange)="updateTradeTerm($event)"
          [eventDetail]="this.eventDetail"
          [eventType]="eventType"
          (valueChanged)="updateImpact($event)"
          [value]="impactValue"
          [adapter]="adapter"
        ></cel-discount-term>
      </div>
      <div class="col container center-content" *ngFor="let adjustment of adjustments; let i = index; trackBy: trackByFn">
        <cel-segment-adjustment
          *ngIf="eventType && [EventType.TRADE_TERMS].includes(eventType) && isDemandImpact()"
          name="Demand"
          [dateRange]="dateRange"
          [adjustment]="adjustment.demand"
          (adjustmentChange)="updateAdjustment(adjustment, 'demand', $event)"
          [eventDetail]="this.eventDetail"
          [eventType]="eventType"
          [parentData]="sharedDataBtoC"
          (dataChanged)="handleDataChanged('C', $event)"
          [startDate]="$any(adjustment).startDate"
          [endDate]="$any(adjustment).endDate"
          (dateRangeChanged)="onDateRangeChanged(i, $event)"
          (deleteAdjustment)="deleteAdjustment(i)"
        ></cel-segment-adjustment>
      </div>

      <div class="add-another-adjustment-div" *ngIf="!this.eventDetail && (eventType && [EventType.TRADE_TERMS].includes(eventType) && isDemandImpact())">
        <nb-icon class="plus-icon" icon="plus-outline"></nb-icon>
        <button data-testid="e2e-btn-add-adjustment" class="add-another-adjustment-btn" type="button" (click)="addAdjustment()">
          Add Another Adjustment
        </button>
      </div>
    </div>
    <div class="row" *ngIf="!this.eventDetail" >
      <div class="col"></div>
      <nb-icon class="segment-impact-delete-icon" icon="trash-2"></nb-icon>
      <button class="segment-impact-delete-btn" type="button" (click)="delete.emit(true)" >
        Delete
      </button>
    </div>
  </nb-card-body>
</nb-card>

<ng-template #renameGroup let-group let-ref="dialogRef">
  <nb-card class="dialog-container">
    <nb-card-header class="dialog-header row">
      <label class="dialog-label">Rename Group</label>
      <button class="dialog-btn -close" (click)="ref.close()">
        <nb-icon class="dialog-btn -close-icon" icon="close-outline" pack="eva" fullWidth></nb-icon>
      </button>
    </nb-card-header>
    <nb-card-body class="dialog-body">
      <div class="dialog-text">Please add a new name for "{{ group }}"</div>
      <label class="dialog-input-label">New Group Name</label>
      <div>
        <input type="text" autofocus class="dialog-input" placeholder="Input Text" (change)="changeRenameGroup($event.target.value,group)" />
      </div>
    </nb-card-body>
    <nb-card-footer class="dialog-footer">
      <div class="row align-items-end dialog-btn -div">
        <button type="button" class="dialog-btn -cancel" (click)="ref.close()">Cancel</button>
        <button type="button" class="dialog-btn -save" (click)="ref.close(); editGroup()">
          Save
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #confirmDeleteGroup let-group let-ref="dialogRef">
  <nb-card>
    <nb-card-header>Confirm to delete group {{ group }}</nb-card-header>
    <nb-card-footer>
      <button nbButton status="primary" (click)="ref.close()">Cancel</button>
      <button nbButton class="float-right" status="danger" (click)="ref.close(); deleteGroup(group)">
        Delete
      </button>
    </nb-card-footer>
  </nb-card>
</ng-template>
