<div class="box-container">
  <input
    data-testid="e2e-inp-search"
    class="input"
    #input
    nbInput
    type="text"
    placeholder="type to search"
    [nbAutocomplete]="auto"
    (input)="term$.next($event.target.value)"
    [ngStyle]="{'height': boxHeight === '' ? '' : boxHeight + 'px'}"
  />
  <nb-autocomplete #auto (selectedChange)="onSelect($event); input.value = ''">
    <nb-option class="option" *ngFor="let opt of options$ | async" [value]="opt">
      <div class="value">
        {{ opt.value }}
        <div class="type">{{ displayNameService.getCustomisedMasterDataFieldNames(opt.type) }}</div>
      </div>
    </nb-option>
  </nb-autocomplete>
  <span class="add-btn" (click)="addNewSearch()">
    <nb-icon class="add-icon" icon="plus-outline"></nb-icon>
  </span>
<div>
