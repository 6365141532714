<div class="row align-items-center segment-adjust">
  <div class="col-2 segment-adjust-name"><strong>Adjustment</strong> on {{ name }}</div>
  <div class="col-2">
    <nb-select
      class="segment-adjust-box"
      fullWidth
      [selected]="signedAdjustment?.sign"
      (selectedChange)="updateSign($event)"
      [disabled]="disabled"
      *ngIf="!this.eventDetail"
    >
      <nb-option
        class="segment-adjust-box-option"
        [value]="AdjustmentSign.DECREASE"
        [disabled]="eventType === EventType.INVENTORY_PARAMETERS"
      >
        Decrease
      </nb-option>
      <nb-option
        class="segment-adjust-box-option"
        [value]="AdjustmentSign.INCREASE"
        [disabled]="eventType === EventType.INVENTORY_PARAMETERS"
      >
        Increase
      </nb-option>
    </nb-select>
    <input
      class="segment-adjust-value-box bgColorDetail"
      nbInput
      fullWidth
      [value]="signedAdjustment?.sign"
      [attr.disabled]="this.eventDetail"
      *ngIf="this.eventDetail"
    />
  </div>
  <div class="col-2 number-segment">
    <input
      data-testid="e2e-inp-adjustment"
      class="segment-adjust-value-box"
      [ngClass]="this.eventDetail && 'bgColorDetail'"
      nbInput
      fullWidth
      type="text"
      [value]="formatNumberInput(signedAdjustment?.value || 0)"
      (change)="updateValue($event.target.value)"
      (keyup)="onInputKeyUp($event)"
      [attr.disabled]="this.eventDetail"
      [disabled]="disabled"
      (wheel)="preventScroll($event)"
    />
  </div>
  <div class="col-1">
    <nb-select
      class="segment-adjust-box"
      fullWidth
      [selected]="signedAdjustment?.type"
      (selectedChange)="updateType($event)"
      *ngIf="!this.eventDetail"
      [disabled]="disabled"
    >
      <nb-option
        class="segment-adjust-box-option"
        [value]="AdjustmentType.PERCENTAGE"
        [disabled]="eventType === EventType.INVENTORY_PARAMETERS"
      >
        %
      </nb-option>
      <nb-option
        class="segment-adjust-box-option"
        [value]="AdjustmentType.ABSOLUTE"
        *ngIf="eventType !== EventType.INVENTORY_PARAMETERS"
      >
        Unit
      </nb-option>
      <!-- <nb-option
        class="segment-adjust-box-option"
        [value]="AdjustmentType.DAYS"
      >
        Days
      </nb-option> -->
    </nb-select>

    <input
      class="segment-adjust-box-option bgColorDetail adjustmentType "
      nbInput
      fullWidth
      [value]="signedAdjustment?.type == AdjustmentType.PERCENTAGE ? '%' : (signedAdjustment?.type == AdjustmentType.DAYS ? 'Days' : 'Abs')"
      [attr.disabled]="this.eventDetail"
      *ngIf="this.eventDetail"
    />
  </div>

  <div class="col-3" [ngClass]="{'date-range': !this.eventDetail}">
    <nz-range-picker
      [ngClass]="this.eventDetail && 'bgColorDetail'"
      class="form-control custom-nz-range-picker"
      [nzMode]="dateRangeSelectionText.toLowerCase()"
      [(ngModel)]="selectedDateRange"
      [nzAllowClear]="false"
      [nzDisabledDate]="selectableDateRangeFn | async | defaultTo: noDateAllowed"
      [nzRanges]="presettedDateRanges | async | defaultTo: {}"
      (ngModelChange)="onDateChange($event)"
      [nzDisabled]="this.eventDetail"
    ></nz-range-picker>
  </div>

  <div class="col-1" *ngIf="this.shouldDisplayPED()">
    <nb-checkbox
      class="segment-checkbox"
      [checked]="checked"
      (checkedChange)="checkedChange($event)"
      [disabled]="disabled"
    >
      Elasticity
    </nb-checkbox>
  </div>

  <div class="col-1" *ngIf="!this.eventDetail">
    <nb-icon class="delete-icon" icon="trash-2" (click)="deleteSegmentAdjustment()"></nb-icon>
  </div>
</div>
